import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { selectSMSDetails } from "../../state/contactLens";
import { useAppSelector } from "../../state/hooks";
import { SendSMSMutation, SendSMSMutationVariables } from "../../types";
import { useCallback } from "react";

export const SEND_SMS = gql`
  mutation SendSMSMutation($sendSmsSendSmsRequest: sendSMSDraft!) {
    sendSMS(sendSMSRequest: $sendSmsSendSmsRequest) {
      isSuccess
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  sendSMSMutation: (cartId: string) => Promise<FetchResult<SendSMSMutation>>;
}

export const useSendSMS = (): ExtendedMutation => {
  const { phoneNumber, name } = useAppSelector(selectSMSDetails);
  const [mutate, { data, error, loading, called }] = useMutation<SendSMSMutation, SendSMSMutationVariables>(SEND_SMS, {
    onError: () => void 0,
  });

  const sendSMSMutation = useCallback(
    (cartId: string) => {
      const sendSmsSendSmsRequest: SendSMSMutationVariables = {
        sendSmsSendSmsRequest: {
          phoneNumber,
          name,
          cartId,
        },
      };

      return mutate({
        variables: sendSmsSendSmsRequest,
      });
    },
    [mutate, name, phoneNumber],
  );

  return { sendSMSMutation, data, error, loading, called };
};

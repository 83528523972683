import React, { FC, useCallback, useRef } from "react";
import SentryContext from "./SentryContext";
import SentryTracking from "./SentryTracking";
import { Replay } from "@sentry/react";

export type SentryProviderProps = {
  children: React.ReactNode;
};

const SentryProvider: FC<SentryProviderProps> = ({ children }) => {
  const replay = useRef<Replay | null>(null);

  const startRecordReplay = useCallback(async () => {
    try {
      await replay.current?.flush();
    } catch {
      /* empty block */
    }
    replay.current?.start();
  }, []);

  const stopRecordReplay = useCallback(async () => {
    try {
      await replay.current?.stop();
    } catch {
      /* empty block */
    }

    try {
      await replay.current?.flush();
    } catch {
      /* empty block */
    }
  }, []);

  return (
    <SentryContext.Provider
      value={{
        startRecordReplay,
        stopRecordReplay,
      }}
    >
      <SentryTracking
        onInit={(r) => {
          replay.current = r;
        }}
      >
        {children}
      </SentryTracking>
    </SentryContext.Provider>
  );
};

export default SentryProvider;

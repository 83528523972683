import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { SetShippingMethod, SetShippingMethodVariables } from "../../types";
import { CART_FIELDS } from "../fragments";

export const SET_SHIPPING_METHOD = gql`
  ${CART_FIELDS}
  mutation SetShippingMethod($shippingMethodKey: String!) {
    setShippingMethod(shippingMethodKey: $shippingMethodKey) {
      ...CartFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setCartShippingMethod: (optometristRequest: string) => Promise<FetchResult<SetShippingMethod>>;
}

export const useSetShippingMethodMutation = (handleOnComplete?: (data: SetShippingMethod) => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SetShippingMethod, SetShippingMethodVariables>(SET_SHIPPING_METHOD, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const setCartShippingMethod = useCallback(
    (shippingMethodKey: string) => {
      const variables: SetShippingMethodVariables = {
        shippingMethodKey,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { setCartShippingMethod, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosDeleteAvailabilityMutation, PosDeleteAvailabilityMutationVariables } from "../../types";
import { useCallback } from "react";

export const DELETE_AVAILABILITY = gql`
  mutation PosDeleteAvailabilityMutation($ids: [ID]!) {
    posDeleteAvailability(ids: $ids) {
      availabilities {
        id
        distributionChannel {
          key
          name
        }
        startAt
        endAt
        optometrist {
          firstName
          lastName
          id
        }
      }
      errors
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  deleteAvailability: (ids: string[]) => Promise<FetchResult<PosDeleteAvailabilityMutation>>;
}

export const useDeleteAvailabilityMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosDeleteAvailabilityMutation, PosDeleteAvailabilityMutationVariables>(
    DELETE_AVAILABILITY,
    {
      onError: () => void 0,
    },
  );

  const deleteAvailability = useCallback(
    (ids: string[]) => {
      const variables: PosDeleteAvailabilityMutationVariables = {
        ids,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { deleteAvailability, data, error, loading, called };
};

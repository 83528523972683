import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { quoteSkippedSteps } from "../../constants";
import {
  AddOn,
  CartDemoLensPayload,
  ContactsPrescription,
  EYE_OPTION_TYPE,
  EyeLensPayload,
  EyeType,
  GlassesPrescription,
  LENS_BUILDER_STEP,
  LensBuilderStepType,
  LensEye,
  LensMeasurment,
  LensPayload,
  LineItem,
  OrderDemoLens_orderDemoLens,
  OverlappingPrescription,
  PaymentInput,
  RecursivePartial,
  SelectedLens,
  ServiceOption,
  SubmitPosOrder_submitPosOrder,
  Variant,
} from "../../types";
import { convertLineItemsToDispensingState, lensDataToAddItem, mapPrescriptionToCartItems } from "../../utils";

export interface LensBuilderState {
  addOns?: AddOn[];
  barcodeScannerOn: boolean;
  demoLensInCart: CartDemoLensPayload;
  drawOpen: boolean;
  editMode: boolean;
  fitToFrame: boolean;
  formPrescription?: RecursivePartial<OverlappingPrescription>;
  frameVariant?: Variant;
  lenses: SelectedLens;
  orderEyeConfig?: EYE_OPTION_TYPE;
  orderSuccess?: OrderDemoLens_orderDemoLens | SubmitPosOrder_submitPosOrder;
  paymentDetails: PaymentInput[];
  physicalTraceRequired: boolean;
  prescription?: ContactsPrescription | GlassesPrescription;
  prescriptionValuesAddedToCart: boolean;
  service?: ServiceOption;
  step: LENS_BUILDER_STEP;
  invoiceOnly: boolean;
}

const initialPrescriptionMeasurements = {
  height: "",
  pd: "",
  nearpd: "",
  sphere: "",
  add: "",
  axis: "",
  cyl: "",
  genericAdd: "",
};

export const initialLensBuilderState: LensBuilderState = {
  addOns: undefined,
  barcodeScannerOn: false,
  drawOpen: false,
  editMode: false,
  demoLensInCart: { demoLensInCart: false, cartHasItems: false },
  fitToFrame: false,
  formPrescription: undefined,
  frameVariant: undefined,
  lenses: {
    [EyeType.LEFT_OS]: {
      detail: {
        color: undefined,
        thickness: undefined,
        treatment: undefined,
        prescriptionLensType: undefined,
        prescriptionLensExtra: undefined,
        variantName: undefined,
        variantPrice: undefined,
      },
      itemToAdd: {
        eye: EyeType.LEFT_OS,
        power: "",
        quantity: 0,
        sku: "",
        distributionChannelKey: "",
        supplyChannelKey: "",
        subscription: false,
        ...initialPrescriptionMeasurements,
      },
    },
    [EyeType.RIGHT_OD]: {
      detail: {
        color: undefined,
        thickness: undefined,
        treatment: undefined,
        prescriptionLensType: undefined,
        prescriptionLensExtra: undefined,
        variantName: undefined,
        variantPrice: undefined,
      },
      itemToAdd: {
        eye: EyeType.RIGHT_OD,
        power: "",
        quantity: 0,
        sku: "",
        distributionChannelKey: "",
        supplyChannelKey: "",
        subscription: false,
        ...initialPrescriptionMeasurements,
      },
    },
  },
  orderEyeConfig: undefined,
  orderSuccess: undefined,
  paymentDetails: [],
  physicalTraceRequired: false,
  prescription: undefined,
  prescriptionValuesAddedToCart: false,
  service: undefined,
  step: LENS_BUILDER_STEP.SERVICE_TYPE,
  invoiceOnly: false,
};

export type EditModePayload = {
  mode: boolean;
  step: LENS_BUILDER_STEP;
};

export const lensBuilderSlice = createSlice({
  name: "lensBuilder",
  initialState: initialLensBuilderState,
  reducers: {
    resetLensBuilderState: (state) => {
      return {
        ...initialLensBuilderState,
        // this state is maintained by the cart icon component, we don't want to reset it
        demoLensInCart: state.demoLensInCart,
      };
    },
    resetLenses: (state) => {
      state.lenses = initialLensBuilderState.lenses;
    },
    resetLensEye: (state, action: PayloadAction<EyeType>) => {
      state.lenses[action.payload] = initialLensBuilderState.lenses[action.payload];
    },
    resetOnProductChange: (state) => ({
      ...initialLensBuilderState,
      ...state,
      prescriptionValuesAddedToCart: false,
      orderEyeConfig: undefined,
      lenses: initialLensBuilderState.lenses,
      addOns: undefined,
      prescription: undefined,
    }),
    setAddOns: (state, action: PayloadAction<AddOn[]>) => {
      state.addOns = action.payload;
    },
    setBarcodeScannerOn: (state, action: PayloadAction<boolean>) => {
      state.barcodeScannerOn = action.payload;
    },
    setDemoLensInCart: (state, action: PayloadAction<CartDemoLensPayload>) => {
      state.demoLensInCart = action.payload;
    },
    setCartItemsAsState: (state, action: PayloadAction<LineItem[]>) => {
      return convertLineItemsToDispensingState(action.payload);
    },
    setDrawOpen: (state, action: PayloadAction<boolean>) => {
      state.drawOpen = action.payload;
    },
    setEditMode: (state, action: PayloadAction<EditModePayload>) => {
      state.editMode = action.payload.mode;
      state.step = action.payload.step;
    },
    setFitToFrame: (state, action: PayloadAction<boolean>) => {
      state.fitToFrame = action.payload;
    },
    setFormPrescription: (state, action: PayloadAction<RecursivePartial<OverlappingPrescription> | undefined>) => {
      state.formPrescription = action.payload;
    },
    setFrameVariant: (state, action: PayloadAction<Variant>) => {
      state.frameVariant = action.payload;
    },
    setIsQuote: (state) => {
      state.prescriptionValuesAddedToCart = false;
      state.addOns = undefined;
      state.prescription = undefined;
      state.formPrescription = undefined;

      if (state.service) {
        state.service.quote = true;

        if (state.service?.displayName === "Frames (Customised)") {
          state.orderEyeConfig = EYE_OPTION_TYPE.BOTH;
          state.lenses[EyeType.LEFT_OS].itemToAdd.quantity = 1;
          state.lenses[EyeType.RIGHT_OD].itemToAdd.quantity = 1;
          state.service.steps = state.service.steps.filter((step) => !quoteSkippedSteps.framesCustomised.includes(step));
          state.step = LENS_BUILDER_STEP.LENS_TYPE;
        }
        if (state.service?.displayName === "Lens Only") {
          state.service.steps = state.service.steps.filter((step) => !quoteSkippedSteps.lensOnly.includes(step));
          state.step = LENS_BUILDER_STEP.SELECT_EYE_OPTION;
        }
      }
    },
    setLensEye: (state, action: PayloadAction<EyeLensPayload>) => {
      // new action for contact lenses as we don't need to handle symmetric lens orders
      const { eye, detail, itemToAdd } = action.payload;
      state.lenses[eye] = {
        detail: {
          ...state.lenses[eye]?.detail,
          ...(detail ?? {}),
        },
        itemToAdd: {
          ...state.lenses[eye]?.itemToAdd,
          ...(itemToAdd ?? {}),
        },
      } as LensEye;
    },
    setLenses: (state, action: PayloadAction<LensPayload>) => {
      const hasRightEye =
        state.orderEyeConfig === EYE_OPTION_TYPE.BOTH || state.orderEyeConfig === EYE_OPTION_TYPE.RIGHT_ONLY || !state.orderEyeConfig;
      const hasLeftEye = state.orderEyeConfig === EYE_OPTION_TYPE.BOTH || state.orderEyeConfig === EYE_OPTION_TYPE.LEFT_ONLY || !state.orderEyeConfig;

      if (hasRightEye) {
        state.lenses[EyeType.RIGHT_OD] = {
          detail: {
            ...state.lenses[EyeType.RIGHT_OD]?.detail,
            ...(action.payload?.detail ?? {}),
          },
          itemToAdd: {
            ...state.lenses[EyeType.RIGHT_OD]?.itemToAdd,
            ...(action.payload?.itemToAdd ?? {}),
          },
        } as LensEye;
      }

      if (hasLeftEye) {
        state.lenses[EyeType.LEFT_OS] = {
          detail: {
            ...state.lenses[EyeType.LEFT_OS]?.detail,
            ...(action.payload?.detail ?? {}),
          },
          itemToAdd: {
            ...state.lenses[EyeType.LEFT_OS]?.itemToAdd,
            ...(action.payload?.itemToAdd ?? {}),
          },
        } as LensEye;
      }
    },
    setMeasurements: (state, action: PayloadAction<Partial<LensMeasurment>>) => {
      const result = lensDataToAddItem(action.payload);
      const left = result[EyeType.LEFT_OS];
      const right = result[EyeType.RIGHT_OD];

      if (state.orderEyeConfig !== EYE_OPTION_TYPE.RIGHT_ONLY) {
        state.lenses[EyeType.LEFT_OS] = {
          ...state.lenses[EyeType.LEFT_OS],
          itemToAdd: {
            ...state.lenses[EyeType.LEFT_OS]?.itemToAdd,
            ...left,
          },
        } as LensEye;
      }

      if (state.orderEyeConfig !== EYE_OPTION_TYPE.LEFT_ONLY) {
        state.lenses[EyeType.RIGHT_OD] = {
          ...state.lenses[EyeType.RIGHT_OD],
          itemToAdd: {
            ...state.lenses[EyeType.RIGHT_OD]?.itemToAdd,
            ...right,
          },
        } as LensEye;
      }
    },
    setOrderEyeConfig: (state, action: PayloadAction<EYE_OPTION_TYPE>) => {
      const osQuantity = state.lenses[EyeType.LEFT_OS].itemToAdd.quantity;
      const odQuantity = state.lenses[EyeType.RIGHT_OD].itemToAdd.quantity;
      const prescriptionCartItems = state?.prescription && "id" in state.prescription ? mapPrescriptionToCartItems(state.prescription) : undefined;

      state.orderEyeConfig = action.payload;
      state.lenses[EyeType.LEFT_OS].itemToAdd.quantity = action.payload === EYE_OPTION_TYPE.RIGHT_ONLY ? 0 : osQuantity || 1;
      state.lenses[EyeType.RIGHT_OD].itemToAdd.quantity = action.payload === EYE_OPTION_TYPE.LEFT_ONLY ? 0 : odQuantity || 1;

      if (action.payload === EYE_OPTION_TYPE.RIGHT_ONLY && osQuantity) {
        state.lenses[EyeType.RIGHT_OD] = {
          ...state.lenses[EyeType.RIGHT_OD],
          itemToAdd: {
            ...state.lenses[EyeType.RIGHT_OD].itemToAdd,
            eye: EyeType.RIGHT_OD,
            // don't copy over measurements from the other eye
            height: undefined,
            pd: undefined,
            nearpd: undefined,
            bvd: undefined,
            pantoscopic_tilt: undefined,
            frame_wrap: undefined,
            ...prescriptionCartItems?.[EyeType.RIGHT_OD],
            quantity: odQuantity || 1,
          },
        };
      }

      if (action.payload === EYE_OPTION_TYPE.LEFT_ONLY && odQuantity) {
        state.lenses[EyeType.LEFT_OS] = {
          ...state.lenses[EyeType.LEFT_OS],
          itemToAdd: {
            ...state.lenses[EyeType.LEFT_OS].itemToAdd,
            eye: EyeType.LEFT_OS,
            height: undefined,
            pd: undefined,
            nearpd: undefined,
            bvd: undefined,
            pantoscopic_tilt: undefined,
            frame_wrap: undefined,
            ...prescriptionCartItems?.[EyeType.LEFT_OS],
            quantity: osQuantity || 1,
          },
        };
      }

      if (action.payload === EYE_OPTION_TYPE.BOTH) {
        if (osQuantity && !odQuantity) {
          state.lenses[EyeType.RIGHT_OD] = {
            ...state.lenses[EyeType.LEFT_OS],
            itemToAdd: {
              ...state.lenses[EyeType.LEFT_OS].itemToAdd,
              eye: EyeType.RIGHT_OD,
              height: undefined,
              pd: undefined,
              nearpd: undefined,
              bvd: undefined,
              pantoscopic_tilt: undefined,
              frame_wrap: undefined,
              ...prescriptionCartItems?.[EyeType.RIGHT_OD],
              quantity: odQuantity || 1,
            },
          };
        }
        if (odQuantity && !osQuantity) {
          state.lenses[EyeType.LEFT_OS] = {
            ...state.lenses[EyeType.RIGHT_OD],
            itemToAdd: {
              ...state.lenses[EyeType.RIGHT_OD].itemToAdd,
              eye: EyeType.LEFT_OS,
              height: undefined,
              pd: undefined,
              nearpd: undefined,
              bvd: undefined,
              pantoscopic_tilt: undefined,
              frame_wrap: undefined,
              ...prescriptionCartItems?.[EyeType.LEFT_OS],
              quantity: osQuantity || 1,
            },
          };
        }
      }
    },
    setOrderSuccess: (state, action: PayloadAction<OrderDemoLens_orderDemoLens | undefined>) => {
      state.orderSuccess = action.payload;
    },
    setPaymentDetails: (state, action: PayloadAction<PaymentInput[]>) => {
      state.paymentDetails = action.payload;
    },
    setPhysicalTraceRequired: (state, action: PayloadAction<boolean>) => {
      state.physicalTraceRequired = action.payload;
    },
    setPrescription: (state, action: PayloadAction<ContactsPrescription | GlassesPrescription | undefined>) => {
      state.prescription = action.payload;

      // set default quantity of lenses to 1 when selecting a prescription
      // user can manually reduce to 0 for contact lenses
      if (state.orderEyeConfig !== EYE_OPTION_TYPE.RIGHT_ONLY) {
        state.lenses[EyeType.LEFT_OS].itemToAdd.quantity = 1;
      }

      if (state.orderEyeConfig !== EYE_OPTION_TYPE.LEFT_ONLY) {
        state.lenses[EyeType.RIGHT_OD].itemToAdd.quantity = 1;
      }
    },
    setPrescriptionValuesAddedToCart: (state) => {
      state.prescriptionValuesAddedToCart = true;
    },
    setService: (state, action: PayloadAction<ServiceOption>) => {
      state.service = action.payload;
    },
    setServiceStep: (state, action: PayloadAction<LensBuilderStepType>) => {
      if (!state.service?.steps) {
        state.step = LENS_BUILDER_STEP.SERVICE_TYPE;
      } else {
        switch (action.payload) {
          case LensBuilderStepType.NEXT: {
            const currentStepIndex = state.service.steps.findIndex((s) => s === state.step);
            state.step =
              currentStepIndex !== state.service.steps.length - 1
                ? state.service?.steps[currentStepIndex + 1]
                : state.service.steps[state.service.steps.length - 1];
            break;
          }
          case LensBuilderStepType.BACK: {
            const currentStepIndex = state.service.steps.findIndex((s) => s === state.step);
            state.step = currentStepIndex ? state.service.steps[currentStepIndex - 1] : state.service.steps[0];
            break;
          }
        }
      }
    },
    setStep: (state, action: PayloadAction<LENS_BUILDER_STEP>) => {
      state.step = action.payload;
    },
    setInvoiceOnly: (state) => {
      state.invoiceOnly = !state.invoiceOnly;
    },
  },
});

export const {
  resetLensBuilderState,
  resetLensEye,
  resetLenses,
  resetOnProductChange,
  setAddOns,
  setBarcodeScannerOn,
  setCartItemsAsState,
  setDemoLensInCart,
  setDrawOpen,
  setEditMode,
  setFitToFrame,
  setFormPrescription,
  setFrameVariant,
  setIsQuote,
  setLensEye,
  setLenses,
  setMeasurements,
  setOrderEyeConfig,
  setOrderSuccess,
  setPaymentDetails,
  setPhysicalTraceRequired,
  setPrescription,
  setPrescriptionValuesAddedToCart,
  setService,
  setServiceStep,
  setStep,
  setInvoiceOnly,
} = lensBuilderSlice.actions;

export default lensBuilderSlice.reducer;

import isNil from "lodash/isNil";

import { PRESCRIPTION_TYPES, serviceOptions } from "../constants";
import { LENS_THICKNESS_OPTIONS } from "../constants/dispensing";
import { initialLensBuilderState, LensBuilderState } from "../state/lensBuilder";
import {
  Address,
  addressDraft,
  addressType,
  AddToCartDraftWithLineItemId,
  Cart_cart_lineItems_discounts,
  CATEGORY_SLUG,
  DistributionChannels_distributionChannels_address,
  EYE_OPTION_TYPE,
  EyeType,
  GroupedLineItems,
  LENS_BUILDER_STEP,
  LensType,
  LineItem,
  lineItemPrescriptionDraft,
  Patient,
  PrescriptionLensType,
  Product,
  PRODUCT_TYPE_KEY,
  ServiceOption,
  Stock,
  Variant,
} from "../types";
import { currencyFormat, getCountryCode } from "./helpers";

// list supplied by YS 21/02/2022
const CONTACT_LENS_SUPPLIERS = [
  "Alcon-au",
  "Alcon-nz",
  "BauschLomb-au",
  "CooperVision-au",
  "CooperVision-nz",
  "JohnsonJohnsonVisionCare-au",
  "JohnsonJohnsonVisionCare-nz",
  "Menicon",
  "RadiantHealth-nz",
  "Alcon-ca",
  "BauschLomb-ca",
  "JohnsonJohnsonVisionCare-ca",
  "CooperVision-ca",
  "PTSOptics-ca",
];

const MATERIAL_TYPE = ["BNLMATERIAL", "BNLMATERIALTAXED"];

export const shouldRemoveSunglassSKUs = (sku: string, remove?: boolean): boolean => !!remove && sku.toLowerCase().includes("sun");

export const matchingSunVariantStock = (optFrame: Variant, sunVariants: Variant[], storeKey: string): Stock | undefined => {
  const sunVariant = sunVariants.find((variant) => variant.sku.replace("SUN", "OPT") === optFrame.sku);
  return sunVariant?.stocks.find((stock) => stock.key === storeKey && (stock?.availableQuantity ?? 0) > 0);
};

export const filterAvailableProducts = (products?: Product[], dcKey?: string | null, service?: ServiceOption): Product[] => {
  const isCustomisedFrame = service?.displayName === serviceOptions.framesCustomised.displayName;
  const isLensOnly = service?.displayName === serviceOptions[CATEGORY_SLUG.LENSES].displayName;
  const isDemoLens = service?.displayName === serviceOptions.demoLens.displayName;
  const contactLens = service?.slug === CATEGORY_SLUG.CONTACT_LENSES;
  const isOffTheShelf = service?.displayName === serviceOptions?.framesOffTheShelf?.displayName;

  const customOrDemoLens = isCustomisedFrame || isDemoLens;

  const sunVariants = products?.flatMap((product) => product.variants.filter((variant) => variant.sku.slice(-3) === "SUN")) as Variant[];
  return (products ?? []).reduce((accum: Product[], product: Product) => {
    const productVariants = product.variants.reduce((accV: Variant[], variant: Variant) => {
      if (!variant || !variant.sku || !variant.name || !variant.stocks || shouldRemoveSunglassSKUs(variant.sku, customOrDemoLens)) {
        return accV;
      }

      const contrycode = getCountryCode().toLocaleLowerCase();

      // filter variant by stock availability for supply channels appropriate for order type
      const stocks = variant.stocks
        .filter((stock) => {
          const arrayDCKeys = [
            ...(dcKey && !customOrDemoLens && !contactLens ? [dcKey] : []), // show variants from store except for customised glasses & contact lenses
            ...(customOrDemoLens ? (dcKey ? ["EOLT", dcKey] : ["EOLT"]) : []), // show EOLT and local inventory variants for customised glasses
            ...(contactLens ? CONTACT_LENS_SUPPLIERS.filter((supplier) => supplier.includes(contrycode)) : []), // show variants from contact lens suppliers that are for current country
          ];

          return arrayDCKeys.includes(stock?.key ?? "") && (stock?.availableQuantity ?? 0) > 0;
        })
        .sort((stock) => (stock.key === "EOLT" ? -1 : 0));

      // apply variant filtering by available stock or
      // ignore stock filtering for lens only and demo lens or
      // include if there is matching sun variant with stock for opt frame
      if (stocks.length > 0 || isLensOnly || service?.quote) {
        accV.push({ ...variant, stocks });
      } else if (variant.sku.slice(-3) === "OPT" && dcKey && !isOffTheShelf) {
        const sunVariantStock = matchingSunVariantStock(variant, sunVariants, dcKey);
        if (sunVariantStock) {
          // sunOpt flag is added to the frame and is required when the item adding to cart
          accV.push({ ...variant, sunOpt: true, stocks: [sunVariantStock] });
        }
      }
      return accV;
    }, []);

    if (productVariants.length > 0) {
      accum.push({ ...product, variants: productVariants });
    }
    return accum;
  }, []);
};

type ProductTypeName = string;

type BundleGroup = Record<ProductTypeName, LineItem[]>;

type ProductGroup = Record<number, BundleGroup>;

/**
 * @param lineItems
 * @returns ProductGroup -> { [number]: { [ProductTypeName (eg frameSku/lensesSku)]: LineItem[] } }>
 * @description
 * Group line items by 1) bundle number, then 2) product type eg frame, lenses, addons etc
 */
export const groupLineItems = (lineItems: LineItem[]): ProductGroup =>
  lineItems.reduce((acc: ProductGroup, item: LineItem) => {
    const bundleNumber = item.customFields?.bundleNumber ?? -1;
    if (!acc[bundleNumber]) {
      acc[bundleNumber] = {};
    }
    const addOn = item.productSlug?.en === "addons";
    const productType = addOn ? "addons" : item.productType?.key;
    const variantSku = item.variant?.sku ?? "";

    // needed to add sku to display contact lenses with different skus in different groups
    const objProductKey = `${productType}${variantSku}`;
    if (productType && !acc[bundleNumber][objProductKey]) {
      acc[bundleNumber][objProductKey] = [];
    }

    if (acc?.[bundleNumber]?.[objProductKey]) {
      acc[bundleNumber][objProductKey] = [...acc[bundleNumber][objProductKey], item];
    }
    return acc;
  }, {});

export const cartLineItemsDisplaySubtotal = (lineItems: LineItem[]): string => {
  const productSubtotal = lineItems.reduce((acc, lineItem) => {
    acc += lineItem.totalPrice?.centAmount ?? 0;
    return acc;
  }, 0);
  return currencyFormat(productSubtotal / 100, lineItems?.[0]?.totalPrice?.currencyCode);
};

type ValidateAddressDraft = {
  address?: Address | DistributionChannels_distributionChannels_address | null;
  externalId?: string | null;
  patient?: Patient;
  methodName?: addressType;
};
/**
 * @param array of cart lineItems
 * @returns cart lineItems with summed total price and discount total discount
 * @description used for calculating total price when displaying same sku line
 * items in a single line similar to Optimate
 */
export const combinedPriceLineItem = (lineItems: LineItem[]): LineItem => {
  const priceValueCentAmount = lineItems.reduce((acc, li) => acc + li.price?.value?.centAmount ?? 0, 0);
  const totalPriceCentAmount = lineItems.reduce((acc, li) => acc + (li.totalPrice?.centAmount ?? 0), 0);
  const quantity = lineItems.reduce((acc, li) => acc + (li.quantity ?? 0), 0);

  const totalDiscountCentAmount = lineItems.reduce((acc, li) => acc + (li.discounts?.totalDiscount?.centAmount ?? 0), 0);
  return {
    ...lineItems[0],
    quantity,
    totalPrice: {
      currencyCode: lineItems[0].totalPrice?.currencyCode ?? "",
      centAmount: totalPriceCentAmount,
    },
    price: {
      ...lineItems[0].price,
      value: {
        ...lineItems[0].price?.value,
        centAmount: priceValueCentAmount,
      },
    },
    discounts: {
      ...lineItems[0].discounts,
      totalDiscount: {
        ...lineItems[0].discounts?.totalDiscount,
        currencyCode: lineItems[0].discounts?.totalDiscount?.currencyCode ?? "",
        centAmount: totalDiscountCentAmount,
      },
    } as Cart_cart_lineItems_discounts,
  };
};

export const validateAddressDraft = ({ address, patient, methodName, externalId }: ValidateAddressDraft): addressDraft | null => {
  if (!address || !patient || !methodName) {
    return null;
  }

  const { state, region, country, city, postalCode, streetName, streetNumber } = address;
  const company = (address as unknown as Address)?.company || "";

  if (postalCode && city && country && (state || region) && patient.firstName && patient.lastName && patient.email) {
    return {
      company,
      city,
      postalCode,
      streetName,
      streetNumber,
      region: (region || state) as string,
      ...(state ? { state } : {}),
      country,
      email: patient.email,
      phone: patient.mobilePhone,
      mobile: patient.mobilePhone,
      firstName: patient.firstName,
      lastName: patient.lastName,
      additionalAddressInfo: methodName,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      additionalStreetInfo: address?.additionalStreetInfo || "",
      ...(methodName === addressType.clickAndCollectAddress && !!externalId ? { externalId } : {}),
    };
  }

  return null;
};

export const getHealthFundCodes = (currentItem: LineItem, bundleItems: LineItem[], customerGroupKey?: string): string[] => {
  //customerGroupHealthFundCode should only on frame and base on customer group. While it exist, all other codes should be null
  const productType = currentItem.productType?.key ?? "";
  const sku = currentItem?.variant?.sku;

  if (customerGroupKey && customerGroupKey.length > 0 && sku) {
    const codes = getHealthFundCodeByHFProvider(currentItem);
    if (codes.length > 0) {
      return codes;
    }
  }

  if (currentItem.variant?.customerGroupHealthFundCode) {
    return [currentItem.variant?.customerGroupHealthFundCode];
  }

  const lensesCount = bundleItems.filter(
    (item) =>
      item?.productType?.key === "lenses" &&
      (item?.variant?.singleHealthFundCode || item?.variant?.multiHealthFundCode) &&
      item?.customFields?.bundleNumber === currentItem?.customFields?.bundleNumber,
  ).length;
  switch (productType) {
    case "frame":
      return [...(currentItem.variant?.singleHealthFundCode ? [currentItem.variant?.singleHealthFundCode] : [])];
    case "lenses": {
      if (lensesCount === 1) {
        const lensCode = currentItem.variant?.singleHealthFundCode;
        const lensAddonCodes = (currentItem?.variant?.singleHealthFundCodeAddOns ?? []).filter((code) => !isNil(code)) as string[];
        return [...(lensCode ? [lensCode] : []), ...lensAddonCodes];
      } else {
        const lensCode = currentItem.variant?.multiHealthFundCode;
        const lensAddonCodes = (currentItem?.variant?.multiHealthFundCodeAddOns ?? []).filter((code) => !isNil(code)) as string[];
        return [...(lensCode ? [lensCode] : []), ...lensAddonCodes];
      }
    }
    case "contactLenses": {
      if (bundleItems.length === 1) {
        const lensCode = currentItem.variant?.singleHealthFundCode;
        return [...(lensCode ? [lensCode] : [])];
      } else {
        const lensCode = currentItem.variant?.multiHealthFundCode;
        return [...(lensCode ? [lensCode] : [])];
      }
    }
    default:
      return [];
  }
};

/**
 * the NIB deal we would use the code 225 which covers the frames and lenses. 
 * If they buy two pairs of single vision lenses then we the code is 228 instead of 225 for frames in ranges 1 and 2. 
 * For frames in range 3 if we're running a hot offer then it's also 228 otherwise range 3 uses codes 110 and 212 like normal transactions.

 * @param currentItem : the current item thay look for claim code
 * @param bundleItems : every line item in the cart/order
 * @returns claim code
 */
export const getHealthFundCodeByHFProvider = (currentItem: LineItem): string[] => {
  const healthFundDiscountValue = getHealthFundDiscountValue(currentItem);
  return healthFundDiscountValue;
};

const getHealthFundDiscountValue = (lineItem: LineItem): string[] => {
  const regex = new RegExp("^ClaimCode", "gi");
  const includedPhiDiscount =
    lineItem?.discounts?.includedDiscounts &&
    (lineItem?.discounts?.includedDiscounts?.length > 0
      ? lineItem?.discounts?.includedDiscounts?.find((item) => (item?.name ? regex.test(item?.name) : false))
      : "");
  const includedDiscounts = includedPhiDiscount ? includedPhiDiscount?.name : "";
  const healthFundDiscountValue = includedDiscounts ? includedDiscounts?.split("-")[1] : "";
  return healthFundDiscountValue ? [healthFundDiscountValue] : [];
};

enum LAB_KEYS {
  EOLT = "EOLT",
}

export const isStore = (supplyChannelKey?: string): boolean => !Object.values(LAB_KEYS).includes(supplyChannelKey as LAB_KEYS);

const productNameMap: Record<string, string> = {
  frame: "Frame",
  lenses: "Lenses",
  addons: "Add ons",
  contactLenses: "Contact lenses",
};

export const mapProductToDisplayName = (productType: string): string => {
  const [_key, displayName] = Object.entries(productNameMap).find(([key]) => !!productType.match(key)) ?? [];
  return displayName ?? "Other";
};

export const showCompleteQuote = (bundleLineItems: LineItem[]): boolean => bundleLineItems.some((li) => li?.customFields?.isQuote);

export const getBundleService = (lineItems: LineItem[]): ServiceOption | undefined => {
  const includesLens = lineItems.some((li: LineItem) => li.productType?.key === "lenses");
  const bundleFrame = lineItems.find((li: LineItem) => li.productType?.key === "frame");

  if (includesLens && !!bundleFrame) {
    return serviceOptions.framesCustomised;
  }

  if (includesLens) {
    return serviceOptions.lenses;
  }
};

export const hasEyeLens = (lineItems: LineItem[], eyeType: EyeType): boolean => {
  return lineItems.some((li) => li.productType?.key === "lenses" && li.customFields?.eye === eyeType);
};

export const convertLineItemsToDispensingState = (lineItems: LineItem[]): LensBuilderState => {
  const bundleServiceType = getBundleService(lineItems);

  const hasLeftLenses = hasEyeLens(lineItems, EyeType.LEFT_OS);
  const hasRightLenses = hasEyeLens(lineItems, EyeType.RIGHT_OD);
  const hasBothLenses = hasLeftLenses && hasRightLenses;

  const dispensingState = lineItems.reduce(
    (acc: LensBuilderState, li: LineItem) => {
      if (bundleServiceType === serviceOptions.framesCustomised || bundleServiceType === serviceOptions.lenses) {
        const orderEyeConfig = hasBothLenses
          ? EYE_OPTION_TYPE.BOTH
          : lineItems.find((li: LineItem) => li.productType?.key === "lenses")?.customFields?.eye;

        if (orderEyeConfig) {
          acc.orderEyeConfig = orderEyeConfig as EYE_OPTION_TYPE;
        }
        const { productType, variant } = li;

        if (productType?.key === "frame" && variant) {
          acc.frameVariant = {
            ...(variant as unknown as Variant),
            // the only time a state line item will have an id is when it is a quote (populated by cart response)
            // so we will use ids to determine if the current lensbuilding state is a quote
            lineItemId: li.id,
            currentPrice: {
              discounted: {
                value: {
                  centAmount: li.totalPrice?.centAmount ?? 0,
                  currencyCode: li.totalPrice?.currencyCode ?? "",
                },
              },
              cashPrice: {
                amount: li.price.value.centAmount,
                currencyCode: li.price.value.currencyCode,
              },
            },
          };
        }
        if (productType?.key === "lenses") {
          const { customFields } = li;
          if (customFields) {
            const lensType = customFields?.lensType as LensType;
            const lenseEye = customFields.eye === EyeType.LEFT_OS ? EyeType.LEFT_OS : EyeType.RIGHT_OD;

            if (variant?.sku?.includes("BNLBASE")) {
              let prescriptionLensType: PrescriptionLensType | null = null;
              let prescriptionLensExtra = "";
              const prescriptionTypes = PRESCRIPTION_TYPES.filter((p) => p.lensType === lensType);
              if (prescriptionTypes.length > 0) {
                if (prescriptionTypes.length === 1) {
                  prescriptionLensType = prescriptionTypes[0]?.type || null;
                  prescriptionLensExtra = prescriptionTypes[0]?.extra || "";
                } else {
                  const foundPrescription = prescriptionTypes.filter((p) => li.name?.en?.endsWith(p.type));
                  if (foundPrescription.length) {
                    prescriptionLensType = foundPrescription[0]?.type || null;
                    prescriptionLensExtra = foundPrescription[0]?.extra || "";
                  }
                }
              }

              const lenses = {
                ...acc.lenses,
                [lenseEye]: {
                  ...acc.lenses[lenseEye],
                  detail: {
                    ...acc.lenses[lenseEye].detail,
                    variantName: variant?.name,
                    variantPrice: (li?.totalPrice?.centAmount ?? 0) / 100,
                    prescriptionLensType: prescriptionLensType,
                    prescriptionLensExtra: prescriptionLensExtra, // used to display lens description in review step
                  },
                  itemToAdd: {
                    ...acc.lenses[lenseEye].itemToAdd,
                    ...customFields,
                    lineItemId: li.id,
                    sku: variant?.sku,
                    quantity: li.quantity,
                    supplyChannelKey: li.supplyChannel?.key,
                    distributionChannelKey: li.distributionChannel?.name?.en?.split(" ")[0] ?? "",
                  },
                },
              };
              acc.lenses = lenses;
            } else if (variant?.name?.toLowerCase()?.startsWith("lens treatment")) {
              const lenses = {
                ...acc.lenses,
                [lenseEye]: {
                  ...acc.lenses[lenseEye],
                  detail: {
                    ...acc.lenses[lenseEye].detail,
                    treatment: {
                      name: variant?.name?.replace("Lens Treatment - ", ""),
                    },
                  },
                },
              };
              acc.lenses = lenses;
            } else if (variant?.name?.toLowerCase()?.startsWith("lens index")) {
              const index = parseFloat(variant?.name?.replace("Lens Index ", ""));
              const foundThickness = LENS_THICKNESS_OPTIONS.find((t) => t.lensIndex === index);
              const lenses = {
                ...acc.lenses,
                [lenseEye]: {
                  ...acc.lenses[lenseEye],
                  detail: {
                    ...acc.lenses[lenseEye].detail,
                    thickness: {
                      name: foundThickness?.name || variant?.name,
                    },
                  },
                },
              };
              acc.lenses = lenses;
            } else if (variant?.name?.toLowerCase()?.startsWith("lens colour")) {
              const lenses = {
                ...acc.lenses,
                [lenseEye]: {
                  ...acc.lenses[lenseEye],
                  detail: {
                    ...acc.lenses[lenseEye].detail,
                    color: variant?.name?.replace("Lens Colour - ", ""),
                  },
                },
              };
              acc.lenses = lenses;
            }
          }
        }

        if (productType?.key === "addons") {
          acc.addOns = [
            ...(acc?.addOns ?? []),
            {
              lineItemId: li.id,
              name: li?.variant?.name ?? "",
              sku: li?.variant?.sku ?? "",
              supplyChannelKey: li?.supplyChannel?.key ?? "",
              price: { amount: (li?.totalPrice?.centAmount ?? 0) / 100, currencyCode: li?.totalPrice?.currencyCode ?? "" },
            },
          ];
        }

        // no requirement to handle contact lenses yet

        acc.service = {
          ...bundleServiceType,
          steps: [LENS_BUILDER_STEP.ENTER_PRESCRIPTION, LENS_BUILDER_STEP.MEASUREMENT, LENS_BUILDER_STEP.REVIEW],
        };
        acc.step = LENS_BUILDER_STEP.ENTER_PRESCRIPTION;
      }

      return acc;
    },
    { ...initialLensBuilderState },
  );

  return dispensingState;
};

export const convertAddToCartDraftToLineItemPrescription = (itemsToAdd: AddToCartDraftWithLineItemId): lineItemPrescriptionDraft => ({
  lineItemId: itemsToAdd.lineItemId as string,
  eye: itemsToAdd.eye,
  sphere: itemsToAdd.sphere,
  cyl: itemsToAdd.cyl,
  axis: itemsToAdd.axis,
  add: itemsToAdd.add,
  power: itemsToAdd.power,
  nearpd: itemsToAdd.nearpd,
  height: itemsToAdd.height,
  pd: itemsToAdd.pd,
  bvd: itemsToAdd.bvd,
  intAdd: itemsToAdd.intAdd,
  hPrism: itemsToAdd.hPrism,
  vPrism: itemsToAdd.vPrism,
  hPrismType: itemsToAdd.hPrismType,
  vPrismType: itemsToAdd.vPrismType,
  lensType: itemsToAdd.lensType,
  genericAdd: itemsToAdd.genericAdd,
});

const findBaseMaterial = (items: LineItem[], bundleNumber: number | null | undefined): LineItem | undefined => {
  return items.find((item) => MATERIAL_TYPE.includes(item?.variant?.sku as string) && bundleNumber == item?.customFields?.bundleNumber);
};

export const mergeBaseMaterialToBaseLenses = (items: LineItem[]): LineItem[] => {
  const baseMaterialItem = items.find((item) => MATERIAL_TYPE.includes(item?.variant?.sku as string));

  const newPricing = baseMaterialItem
    ? items
        .filter((item) => !MATERIAL_TYPE.includes(item?.variant?.sku as string))
        .map((item) => {
          const baseMaterial = findBaseMaterial(items, item?.customFields?.bundleNumber);
          if (item?.variant?.sku?.includes("BNLBASE")) {
            return {
              ...item,
              price: {
                value: {
                  centAmount: (baseMaterial?.price?.value?.centAmount ?? 0) + (item?.price?.value?.centAmount ?? 0),
                  currencyCode: item?.price?.value?.currencyCode ?? "",
                },
                discounted: null,
              },
              discounts: {
                totalDiscount: {
                  currencyCode: item.discounts?.totalDiscount?.currencyCode ?? "",
                  centAmount: (item.discounts?.totalDiscount?.centAmount ?? 0) + (baseMaterial?.discounts?.totalDiscount?.centAmount ?? 0),
                },
                includedDiscounts: item?.discounts?.includedDiscounts ? item?.discounts?.includedDiscounts : [],
              },
              totalPrice: {
                currencyCode: item?.totalPrice?.currencyCode ?? "",
                centAmount: (item?.totalPrice?.centAmount ?? 0) + (baseMaterial?.totalPrice?.centAmount ?? 0),
              },
            };
          }
          return item;
        })
    : items;

  return newPricing;
};

export const groupItemByClaimCode = (lineItems: LineItem[], allLineItems: LineItem[], customerGroupKey?: string): GroupedLineItems[] => {
  const healthFundCodes = lineItems.flatMap((item) => {
    return getHealthFundCodes(item, allLineItems, customerGroupKey);
  });

  const uniq = Array.from(new Set(healthFundCodes));

  uniq.push("none");

  return uniq.map((code) => {
    if (code === "none") {
      const itemsWithoutClaimCode = lineItems.filter((item) => {
        const codes = getHealthFundCodes(item, allLineItems, customerGroupKey);
        return codes.length === 0;
      });
      return {
        healthfundCode: code,
        items: itemsWithoutClaimCode,
        hfCodes: code,
      };
    }
    const itemsWithTheSameCode = lineItems.filter((item) => {
      const codes = getHealthFundCodes(item, allLineItems, customerGroupKey);
      return codes.includes(code);
    });

    if (itemsWithTheSameCode.length > 1) {
      return {
        healthfundCode: code,
        items: itemsWithTheSameCode.map((item, index) => {
          return { ...item, sharedClaimCode: index !== 0, hfCodes: code };
        }),
      };
    }

    return {
      healthfundCode: code,
      items: itemsWithTheSameCode.map((item) => ({ ...item, sharedClaimCode: false, hfCodes: code })),
    };
  });
};

export const isRxBundleItem = (bundleNumber: number, allLineItems: LineItem[]): boolean => {
  const foundFrameOrLenses = allLineItems?.find(
    (item) => item.customFields?.bundleNumber === bundleNumber && [CATEGORY_SLUG.LENSES].includes(item.productType?.key as CATEGORY_SLUG),
  );

  return !!foundFrameOrLenses;
};

export const isContactLensesBundleItem = (bundleNumber: number, allLineItems: LineItem[]): boolean => {
  const foundLine = allLineItems?.find(
    (item) =>
      item.customFields?.bundleNumber === bundleNumber &&
      ["contactLenses"].includes(item.productType?.key as string) &&
      item.variant?.sku?.startsWith("CL"),
  );

  return !!foundLine;
};

export const isOffTheShelfBundleItem = (bundleNumber: number, allLineItems: LineItem[]): boolean => {
  const foundLine = allLineItems?.filter((item) => item.customFields?.bundleNumber === bundleNumber);

  return foundLine?.length === 1 && foundLine?.[0]?.productType?.key === CATEGORY_SLUG.FRAME;
};

export const isAccessoriesBundleItem = (bundleNumber: number, allLineItems: LineItem[]): boolean => {
  const foundLine = allLineItems?.filter((item) => item.customFields?.bundleNumber === bundleNumber);

  return (
    foundLine?.length === 1 &&
    [PRODUCT_TYPE_KEY.ACCESSORIES, PRODUCT_TYPE_KEY.CONTACT_LENSES, PRODUCT_TYPE_KEY.GIFTS, PRODUCT_TYPE_KEY.ADD_ON].includes(
      foundLine?.[0]?.productType?.key as PRODUCT_TYPE_KEY,
    ) &&
    !!foundLine?.[0]?.variant?.sku &&
    !foundLine?.[0]?.variant?.sku.startsWith("CL")
  );
};

export const isServiceBundleItem = (bundleNumber: number, allLineItems: LineItem[]): boolean => {
  const foundLine = allLineItems?.filter((item) => item.customFields?.bundleNumber === bundleNumber);

  return foundLine?.length === 1 && foundLine?.[0]?.productType?.key === CATEGORY_SLUG.SERVICES;
};

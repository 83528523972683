import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PaymentInput, SubmitPosOrder, SubmitPosOrderVariables } from "../../types";
import { useCallback } from "react";

export const SUBMIT_ORDER = gql`
  mutation SubmitPosOrder($paymentDetails: [PaymentInput]!) {
    submitPosOrder(paymentDetails: $paymentDetails) {
      orderNumber
      shippingAddress {
        firstName
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  submitOrder: (paymentDetails: PaymentInput[]) => Promise<FetchResult<SubmitPosOrder>>;
}

export const useSubmitOrderMutation = (handleOnComplete?: (data: SubmitPosOrder) => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SubmitPosOrder, SubmitPosOrderVariables>(SUBMIT_ORDER, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const submitOrder = useCallback(
    (paymentDetails: PaymentInput[]) => {
      const variables: SubmitPosOrderVariables = {
        paymentDetails,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { submitOrder, data, error, loading, called };
};

import { Bundle, LineItem, OrderField, RefundedItem } from "../../types";
import { RootState } from "../store";

export const selectActiveOrderNumber = (state: RootState): string => state.orders.activeOrderNumber;
export const selectActiveOrder = (state: RootState): OrderField | null => state.orders.activeOrder;
export const selectRefundItem = (state: RootState): RefundedItem[] => state.orders.refundItems;
export const selectReturnAllItems = (state: RootState): RefundedItem[] => state.orders.returnAllItems;
export const selectRefundReasons = (state: RootState): string[] => state.orders.refundReasons;
export const selectReturnReasons = (state: RootState): string[] => state.orders.returnReasons;
export const selectOrdeDetails = (state: RootState): Bundle[] => state.orders.orderDetails;
export const selectAllOrderItems = (state: RootState): LineItem[] => state.orders.allOrderItems;

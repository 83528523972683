import { createContext } from "react";

import { StaffToken } from "./useStaffToken";

export type StaffContextType = {
  staffToken: StaffToken | null;
  logout: () => void;
};

const StaffContext = createContext<StaffContextType>({
  staffToken: null,
  logout: () => {
    return;
  },
});

export default StaffContext;

import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { Cart, CartMini, CartMiniVariables, CartVariables } from "../../types";
import { CART_FIELDS } from "../fragments";

export const CART = gql`
  ${CART_FIELDS}
  query Cart($distributionChannelKey: String) {
    cart(distributionChannelKey: $distributionChannelKey) {
      ...CartFields
    }
  }
`;

export const CART_MINI = gql`
  query CartMini($distributionChannelKey: String) {
    cart(distributionChannelKey: $distributionChannelKey) {
      createdAt
      customFields {
        specialInstructions
      }
      lineItems {
        id
        variant {
          sku
        }
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<Cart>, "data" | "loading" | "error" | "refetch" | "called"> {
  getCart: LazyQueryExecFunction<Cart, CartVariables>;
}

interface ExtendedQueryResultMini extends Pick<QueryResult<CartMini>, "data" | "loading" | "error" | "refetch"> {
  getCart: LazyQueryExecFunction<CartMini, CartMiniVariables>;
  called: boolean;
}

export const useCartQuery = (handleOnComplete?: (data: Cart) => void, networkChange?: boolean): ExtendedQueryResult => {
  const [getCart, { data, error, loading, called, refetch }] = useLazyQuery<Cart, CartVariables>(CART, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    notifyOnNetworkStatusChange: networkChange ?? true,
    fetchPolicy: "network-only",
  });

  return {
    getCart,
    data,
    error,
    loading,
    called,
    refetch,
  };
};

export const useCartMiniQuery = (handleOnComplete?: (data: CartMini) => void, networkChange?: boolean): ExtendedQueryResultMini => {
  const [getCart, { data, error, loading, called, refetch }] = useLazyQuery<CartMini, CartMiniVariables>(CART_MINI, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    notifyOnNetworkStatusChange: networkChange ?? true,
    fetchPolicy: "network-only",
  });

  return {
    getCart,
    data,
    error,
    loading,
    called,
    refetch,
  };
};

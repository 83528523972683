import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { IncrementInventory, IncrementInventoryDraft, IncrementInventoryVariables } from "../../types";
import { useCallback } from "react";

export const INCREMENT_INVENTORY = gql`
  mutation IncrementInventory($incrementInventoryRequest: IncrementInventoryDraft!) {
    incrementInventory(incrementInventoryRequest: $incrementInventoryRequest) {
      id
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  incrementInventory: (incrementInventoryRequest: IncrementInventoryDraft) => Promise<FetchResult<IncrementInventory>>;
}

export const useIncrementInventory = (handleOnComplete?: (data: IncrementInventory) => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<IncrementInventory, IncrementInventoryVariables>(INCREMENT_INVENTORY, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    notifyOnNetworkStatusChange: true,
  });

  const incrementInventory = useCallback(
    (incrementInventoryRequest: IncrementInventoryDraft) => {
      const variables = {
        incrementInventoryRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { incrementInventory, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { HFRefundRequest, SubmitHealthFundRefund, SubmitHealthFundRefundVariables } from "../../types";
import { useCallback } from "react";

export const ADD_HF_REFUND = gql`
  mutation SubmitHealthFundRefund($hfRefundRequest: HFRefundRequest!) {
    submitHealthFundRefund(hfRefundRequest: $hfRefundRequest) {
      id
    }
  }
`;
interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  submitHealthFundRefund: (hfRefundRequest: HFRefundRequest) => Promise<FetchResult<SubmitHealthFundRefund>>;
}

export const useSubmitHealthFundRefundMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SubmitHealthFundRefund, SubmitHealthFundRefundVariables>(ADD_HF_REFUND, {
    onError: () => void 0,
  });

  const submitHealthFundRefund = useCallback(
    (hfRefundRequest: HFRefundRequest) => {
      const variables: SubmitHealthFundRefundVariables = {
        hfRefundRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { submitHealthFundRefund, data, error, loading, called };
};

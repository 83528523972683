import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { UpdateBundleToReceiveAtStore } from "../../types";
import { useCallback } from "react";

export const UPDATE_BUNDLE_STATUS_TO_RECEIEVE_AT_STORE = gql`
  mutation UpdateBundleToReceiveAtStore($orderId: String!, $bundleNumber: Int!) {
    updateBundleToReceiveAtStore(orderId: $orderId, bundleNumber: $bundleNumber) {
      id
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  bundleReceivedAtStoreUpdate: (orderId: string, bundleNumber: number) => Promise<FetchResult<UpdateBundleToReceiveAtStore>>;
}

export const useUpdateBundleToReceiveAtStore = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation(UPDATE_BUNDLE_STATUS_TO_RECEIEVE_AT_STORE, {
    onError: () => void 0,
  });

  const bundleReceivedAtStoreUpdate = useCallback(
    (orderId: string, bundleNumber: number) => {
      const variables = {
        orderId,
        bundleNumber,
      };

      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { bundleReceivedAtStoreUpdate, data, error, loading, called };
};

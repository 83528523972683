import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosDeleteAppointment, PosDeleteAppointmentVariables } from "../../types";
import { useCallback } from "react";

export const DELETE_APPOINTMENT = gql`
  mutation PosDeleteAppointment($posDeleteAppointmentId: ID!) {
    posDeleteAppointment(id: $posDeleteAppointmentId) {
      isSuccess
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  deleteAppointment: (posDeleteAppointmentId: string) => Promise<FetchResult<PosDeleteAppointment>>;
}

export const useDeleteAppointmentMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosDeleteAppointment, PosDeleteAppointmentVariables>(DELETE_APPOINTMENT, {
    onError: () => void 0,
  });

  const deleteAppointment = useCallback(
    (posDeleteAppointmentId: string) => {
      const variables: PosDeleteAppointmentVariables = {
        posDeleteAppointmentId,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { deleteAppointment, data, error, loading, called };
};

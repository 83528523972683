import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosLoadBulkReplenishments, PosLoadBulkReplenishmentsVariables } from "../../types";
import { useCallback } from "react";

export const LOAD_BULK_REPLENISHMENTS = gql`
  mutation PosLoadBulkReplenishments($batchNumber: String!, $distributionChannelKey: String!) {
    posLoadBulkReplenishments(batchNumber: $batchNumber, distributionChannelKey: $distributionChannelKey) {
      essilorStoreCode
      batchNumber
      date
    }
  }
`;
interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  loadBulkReplenishments: (batchNumber: string, distributionChannelKey: string) => Promise<FetchResult<PosLoadBulkReplenishments>>;
}

export const useLoadBulkReplenishmentsMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosLoadBulkReplenishments, PosLoadBulkReplenishmentsVariables>(
    LOAD_BULK_REPLENISHMENTS,
    {
      onError: () => void 0,
    },
  );

  const loadBulkReplenishments = useCallback(
    (batchNumber: string, distributionChannelKey: string) => {
      const variables: PosLoadBulkReplenishmentsVariables = {
        batchNumber,
        distributionChannelKey,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { loadBulkReplenishments, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { SendInventory, SendInventoryDraft, SendInventoryVariables } from "../../types";
import { useCallback } from "react";

export const SEND_INVENTORY = gql`
  mutation SendInventory($sendInventoryRequest: SendInventoryDraft!) {
    sendInventory(sendInventoryRequest: $sendInventoryRequest) {
      sku
      supplyChannel {
        id
        key
        name {
          en
        }
      }
      batchNumber
      quantityOnStock
      availableQuantity
      restockableInDays
      expectedDelivery
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  sendInventory: (sendInventoryRequest: SendInventoryDraft) => Promise<FetchResult<SendInventory>>;
}

export const useSendInventory = (handleOnComplete?: (data: SendInventory) => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SendInventory, SendInventoryVariables>(SEND_INVENTORY, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: true,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const sendInventory = useCallback(
    (sendInventoryRequest: SendInventoryDraft) => {
      const variables = {
        sendInventoryRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { sendInventory, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { RemoveItems, RemoveItemsVariables } from "../../types";

export const DELETE_LINE_ITEMS = gql`
  mutation RemoveItems($lineItemIds: [String]!) {
    removeItems(lineItemIds: $lineItemIds) {
      id
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  deleteLineItems: (lineItemIds: string[]) => Promise<FetchResult<RemoveItems>>;
}

export const useDeleteMultipleLineItems = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<RemoveItems, RemoveItemsVariables>(DELETE_LINE_ITEMS, {
    onError: () => void 0,
  });

  const deleteLineItems = useCallback(
    (lineItemIds: string[]) => {
      const variables: RemoveItemsVariables = {
        lineItemIds,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { deleteLineItems, data, error, loading, called };
};

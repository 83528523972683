import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { ListContactsPrescriptions, ListContactsPrescriptionsVariables } from "../../types";
import { CONTACTS_PRESCRIPTION_FIELDS } from "../fragments";

export const LIST_CONTACTS_PRESCRIPTIONS = gql`
  ${CONTACTS_PRESCRIPTION_FIELDS}
  query ListContactsPrescriptions($patientId: ID!) {
    listContactsPrescriptions(patientId: $patientId) {
      ...ContactsPrescriptionFields
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<ListContactsPrescriptions>, "data" | "loading" | "error" | "called"> {
  listContactsPrescriptions: LazyQueryExecFunction<ListContactsPrescriptions, ListContactsPrescriptionsVariables>;
}

export const useContactsPrescriptionsQuery = (): ExtendedQueryResult => {
  const [listContactsPrescriptions, { data, error, loading, called }] = useLazyQuery<ListContactsPrescriptions, ListContactsPrescriptionsVariables>(
    LIST_CONTACTS_PRESCRIPTIONS,
    {
      onError: () => void 0,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  return {
    listContactsPrescriptions,
    data,
    error,
    loading,
    called,
  };
};

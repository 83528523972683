import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";
import { useCallback, useRef } from "react";

import { SearchPatientsElastic, SearchPatientsElasticVariables, SearchPatientsQuickElastic, SearchPatientsQuickElasticVariables } from "../../types";

export const SEARCH_PATIENTS = gql`
  query SearchPatientsElastic($query: String!, $countryCode: String!) {
    searchPatientsElastic(query: $query, countryCode: $countryCode) {
      count
      patientSearchElasticResponse {
        id
        firstName
        externalId
        preferredName
        healthfundName
        gender
        dateOfBirth
        age
        email
        mobilePhone
        landline
        lastName
        medicare
        isMedicareValidated
        medicareReference
        medicareExpiry
        notes
        privateHealthFundCode
        privateHealthFundMemberNumber
        privateHealthFundMemberReference
      }
    }
  }
`;

export const SEARCH_PATIENTS_QUICK = gql`
  query SearchPatientsQuickElastic($query: String!, $countryCode: String!) {
    searchPatientsElastic(query: $query, countryCode: $countryCode) {
      count
      patientSearchElasticResponse {
        id
        firstName
        externalId
        preferredName
        mobilePhone
        lastName
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<SearchPatientsElastic>, "data" | "loading" | "error" | "called"> {
  searchPatients: LazyQueryExecFunction<SearchPatientsElastic, SearchPatientsElasticVariables>;
}

interface ExtendedQuickQueryResult extends Pick<QueryResult<SearchPatientsQuickElastic>, "data" | "loading" | "error"> {
  searchPatients: ({
    variables,
  }: {
    variables: SearchPatientsQuickElasticVariables;
  }) => Promise<QueryResult<SearchPatientsQuickElastic, SearchPatientsQuickElasticVariables>>;
  called: boolean;
  abort: () => void;
}

export const useSearchPatientsQuery = (handleOnComplete?: (data: SearchPatientsElastic) => void): ExtendedQueryResult => {
  const [searchPatients, { data, error, loading, called }] = useLazyQuery<SearchPatientsElastic, SearchPatientsElasticVariables>(SEARCH_PATIENTS, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  return {
    searchPatients,
    data,
    error,
    loading,
    called,
  };
};

export const useSearchPatientsQuickQuery = (handleOnComplete?: (data: SearchPatientsQuickElastic) => void): ExtendedQuickQueryResult => {
  const abortController = useRef(new AbortController());
  const [searchPatients, { data, error, loading, called }] = useLazyQuery<SearchPatientsQuickElastic, SearchPatientsQuickElasticVariables>(
    SEARCH_PATIENTS_QUICK,
    {
      onError: () => void 0,
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
      context: {
        fetchOptions: {
          signal: abortController.current.signal,
        },
      },
    },
  );

  const abort = useCallback(() => {
    abortController.current.abort();
    abortController.current = new AbortController();
  }, []);

  return {
    searchPatients,
    data,
    error,
    loading,
    called,
    abort,
  };
};

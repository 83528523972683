import React, { FC } from "react";

import GoogleAuth from "./GoogleAuth";

type Props = {
  children?: React.ReactNode;
};

const Authentication: FC<Props> = ({ children }) => <GoogleAuth>{children}</GoogleAuth>;

export default Authentication;

import { gql, QueryResult, useLazyQuery } from "@apollo/client";
import { useCallback, useRef } from "react";

import { StoreOrderByNumber, StoreOrderByNumberSearch, StoreOrderByNumberSearchVariables, StoreOrderByNumberVariables } from "../../types";
import { ORDER_FIELD } from "../fragments";

const storeCode = process.env.REACT_APP_STORE_COUNTRY_CODE;

export const ORDER_BY_ID = gql`
  ${ORDER_FIELD}
  query StoreOrderByNumber($orderNumber: String!) {
    storeOrderByNumber(orderNumber: $orderNumber) {
      ...OrderField
    }
  }
`;

export const ORDER_BY_ID_SEARCH = gql`
  query StoreOrderByNumberSearch($orderNumber: String!) {
    storeOrderByNumber(orderNumber: $orderNumber) {
      id
      orderNumber
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<StoreOrderByNumber>, "data" | "loading" | "error" | "refetch" | "called"> {
  getOrder: (orderNumber: string) => Promise<QueryResult<StoreOrderByNumber, StoreOrderByNumberVariables>>;
}

interface ExtendedQueryResultSearch extends Pick<QueryResult<StoreOrderByNumberSearch>, "data" | "loading" | "error" | "refetch" | "called"> {
  getOrder: (orderNumber: string) => Promise<QueryResult<StoreOrderByNumberSearch, StoreOrderByNumberSearchVariables>>;
  abort: () => void;
}

export const useOrderByOrderNumberLazyQuery = (handleOnComplete?: (data: StoreOrderByNumber) => void): ExtendedQueryResult => {
  const [fetch, { data, error, loading, called, refetch }] = useLazyQuery<StoreOrderByNumber, StoreOrderByNumberVariables>(ORDER_BY_ID, {
    onError: () => void 0,
    fetchPolicy: "network-only",
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    context: {
      headers: {
        store: storeCode || "",
      },
    },
  });

  const getOrder = useCallback(
    (orderNumber: string): Promise<QueryResult<StoreOrderByNumber, StoreOrderByNumberVariables>> => {
      return fetch({ variables: { orderNumber } });
    },
    [fetch],
  );

  return {
    getOrder,
    data,
    error,
    loading,
    called,
    refetch,
  };
};

export const useOrderByOrderNumberSearchLazyQuery = (handleOnComplete?: (data: StoreOrderByNumberSearch) => void): ExtendedQueryResultSearch => {
  const abortController = useRef(new AbortController());
  const [fetch, { data, error, loading, called, refetch }] = useLazyQuery<StoreOrderByNumberSearch, StoreOrderByNumberSearchVariables>(
    ORDER_BY_ID_SEARCH,
    {
      onError: () => void 0,
      fetchPolicy: "network-only",
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
      context: {
        headers: {
          store: storeCode || "",
        },
        fetchOptions: {
          signal: abortController.current.signal,
        },
      },
    },
  );

  const getOrder = useCallback(
    (orderNumber: string): Promise<QueryResult<StoreOrderByNumberSearch, StoreOrderByNumberSearchVariables>> => {
      return fetch({ variables: { orderNumber } });
    },
    [fetch],
  );

  const abort = useCallback(() => {
    abortController.current.abort();
    abortController.current = new AbortController();
  }, []);

  return {
    getOrder,
    data,
    error,
    loading,
    called,
    refetch,
    abort,
  };
};

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React, { FC, useEffect } from "react";

const ENVIRONMENT = process.env.NODE_ENV;

type Props = {
  children?: React.ReactNode;
  enableDevMode?: boolean;
  onInit: (replay: Sentry.Replay) => void;
};
const COUNTRY = process.env.REACT_APP_STORE_COUNTRY_CODE;
const APP_ENV = process.env.REACT_APP_ENV;

const SentryTracking: FC<Props> = ({ children, enableDevMode, onInit }) => {
  useEffect(() => {
    if (ENVIRONMENT === "production" || enableDevMode) {
      const replay = new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      });
      const browser = new Integrations.BrowserTracing();
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DNS,
        environment: APP_ENV,
        normalizeDepth: 10,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        integrations: [],
        tracesSampleRate: 0.2,
      });
      Sentry.addIntegration(browser);
      Sentry.addIntegration(replay);
      Sentry.configureScope((scope) => {
        scope.setExtra("country", COUNTRY);
      });

      onInit(replay);
    }
  }, [enableDevMode, onInit]);

  return <Sentry.ErrorBoundary showDialog={false}>{children}</Sentry.ErrorBoundary>;
};

export default SentryTracking;

import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { InventoriesSent, InventoriesSentVariables } from "../../types";

export const INVENTORIES_SENT = gql`
  query InventoriesSent($inventoryMovementsRequest: InventoryMovementsRequest!) {
    inventoriesSent(inventoryMovementsRequest: $inventoryMovementsRequest) {
      id
      createdAt
      sourceChannel
      destinationChannel
      transferDate
      transferStatus
      inventoryEntries {
        sku
        description
        quantity
        transferType
        comment
      }
      batchNumber
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<InventoriesSent>, "data" | "loading" | "error" | "variables" | "called"> {
  getInventoriesSent: LazyQueryExecFunction<InventoriesSent, InventoriesSentVariables>;
}

export const useInventoriesSentQuery = (handleOnComplete?: (data: InventoriesSent) => void): ExtendedQueryResult => {
  const [getInventoriesSent, { data, error, loading, called, variables }] = useLazyQuery<InventoriesSent, InventoriesSentVariables>(
    INVENTORIES_SENT,
    {
      onError: () => void 0,
      fetchPolicy: "network-only",
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    },
  );

  return {
    getInventoriesSent,
    data,
    error,
    loading,
    called,
    variables,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosRefundPayment, PosRefundPaymentVariables, RefundPaymentInput } from "../../types";
import { ORDER_FIELD } from "../fragments";
import { useCallback } from "react";

//only refund the payment
export const REFUND_PAYMENT = gql`
  ${ORDER_FIELD}
  mutation PosRefundPayment($refundPaymentRequest: RefundPaymentInput!) {
    refundPayment(refundPaymentRequest: $refundPaymentRequest) {
      ...OrderField
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  refundPayment: (refundPaymentRequest: RefundPaymentInput) => Promise<FetchResult<PosRefundPayment>>;
}

export const useRefundPaymentMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosRefundPayment, PosRefundPaymentVariables>(REFUND_PAYMENT, {
    onError: () => void 0,
  });

  const refundPayment = useCallback(
    (refundPaymentRequest: RefundPaymentInput) => {
      const variables: PosRefundPaymentVariables = {
        refundPaymentRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { refundPayment, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { OrderToCart, OrderToCartVariables } from "../../types";
import { CART_FIELDS } from "../fragments";

export const ORDER_TO_CART = gql`
  ${CART_FIELDS}
  mutation OrderToCart($orderId: String!) {
    orderToCart(orderId: $orderId) {
      ...CartFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  orderToCart: (orderId: string) => Promise<FetchResult<OrderToCart>>;
}

export const useOrderToCart = (): ExtendedMutation => {
  const [mutate, { loading, error, called, data }] = useMutation<OrderToCart, OrderToCartVariables>(ORDER_TO_CART, {
    onError: void 0,
  });

  const orderToCart = useCallback(
    (orderId: string) => {
      return mutate({
        variables: {
          orderId,
        },
      });
    },
    [mutate],
  );

  return {
    orderToCart: orderToCart,
    loading,
    error,
    called,
    data,
  };
};

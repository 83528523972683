import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { UpsertConfig, UpsertConfigVariables } from "../../types";

export const UPSERT_CONFIG = gql`
  mutation UpsertConfig($type: ConfigType!, $data: JSON) {
    upsertConfig(type: $type, data: $data)
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  upsertConfig: (variables: UpsertConfigVariables) => Promise<FetchResult<UpsertConfig>>;
}

export const useUpsertConfig = (): ExtendedMutation => {
  const [mutateUpsertConfig, { loading, error, data, called }] = useMutation<UpsertConfig, UpsertConfigVariables>(UPSERT_CONFIG, {
    onError: void 0,
  });

  const upsertConfig = useCallback(
    (variables: UpsertConfigVariables) => {
      return mutateUpsertConfig({
        variables,
      });
    },
    [mutateUpsertConfig],
  );

  return {
    upsertConfig,
    loading,
    error,
    data,
    called,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { AddHealthFundRefundComment, AddHealthFundRefundCommentVariables, HealthFundRefundComment } from "../../types";
import { useCallback } from "react";

//the api also update the exist comment
export const UPDATE_HEALTH_FUND_COMMENT = gql`
  mutation AddHealthFundRefundComment($hfRefundCommentRequest: HealthFundRefundComment!) {
    addHealthFundRefundComment(hfRefundCommentRequest: $hfRefundCommentRequest) {
      id
      comment
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updateHFRefundComment: (hfRefundCommentUpdateRequest: HealthFundRefundComment) => Promise<FetchResult<AddHealthFundRefundComment>>;
}

export const useUpdateHealthFundRefundCommentMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<AddHealthFundRefundComment, AddHealthFundRefundCommentVariables>(
    UPDATE_HEALTH_FUND_COMMENT,
    {
      onError: () => void 0,
    },
  );

  const updateHFRefundComment = useCallback(
    (hfRefundCommentUpdateRequest: HealthFundRefundComment) => {
      const variables: AddHealthFundRefundCommentVariables = {
        hfRefundCommentRequest: hfRefundCommentUpdateRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateHFRefundComment, data, error, loading, called };
};

import { createContext } from "react";

export type ISentryContext = {
  startRecordReplay: () => void;
  stopRecordReplay: () => void;
};

const SentryContext = createContext<ISentryContext>({
  startRecordReplay: () => {},
  stopRecordReplay: () => {},
});

export default SentryContext;

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { addressDraft, CustomerAddress, SetShippingAddress } from "../../types";
import { CART_FIELDS } from "../fragments";

export const SET_SHIPPING_ADDRESS = gql`
  ${CART_FIELDS}
  mutation SetShippingAddress($addressRequest: addressDraft!) {
    setShippingAddress(addressRequest: $addressRequest) {
      ...CartFields
    }
  }
`;

interface Variables {
  addressRequest: addressDraft | CustomerAddress;
}

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setShippingAddress: (addressRequest: addressDraft | CustomerAddress) => Promise<FetchResult<SetShippingAddress>>;
}

export const useSetShippingAddressMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SetShippingAddress, Variables>(SET_SHIPPING_ADDRESS, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  const setShippingAddress = useCallback(
    (addressRequest: addressDraft | CustomerAddress) => {
      const variables: Variables = {
        addressRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { setShippingAddress, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../state/hooks";
import { selectUpdateLineItemPrescriptionRequest } from "../../state/lensBuilder";
import { setUnquotingOrderItems } from "../../state/orders";
import { UpdateLineItemPrescription, UpdateLineItemPrescriptionVariables } from "../../types";
import { CART_FIELDS } from "../fragments";

export const UPDATE_LINE_ITEM_PRESCRIPTION = gql`
  ${CART_FIELDS}
  mutation UpdateLineItemPrescription($updateLineItemPrescriptionRequest: updateLineItemPrescriptionDraft) {
    updateLineItemPrescription(updateLineItemPrescriptionRequest: $updateLineItemPrescriptionRequest) {
      ...CartFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updateQuoteLineItems: () => Promise<FetchResult<UpdateLineItemPrescription>>;
}

export const useUpdateLineItemPrescription = (handleOnComplete?: (data: UpdateLineItemPrescription) => void): ExtendedMutation => {
  const updateLineItemPrescriptionRequest = useAppSelector(selectUpdateLineItemPrescriptionRequest);
  const dispatch = useDispatch();

  const [mutate, { data, error, loading, called }] = useMutation<UpdateLineItemPrescription, UpdateLineItemPrescriptionVariables>(
    UPDATE_LINE_ITEM_PRESCRIPTION,
    {
      onError: () => void 0,
      onCompleted: (data) => {
        dispatch(setUnquotingOrderItems([]));
        if (handleOnComplete) {
          handleOnComplete(data);
        }
      },
    },
  );

  const updateQuoteLineItems = useCallback(() => {
    const variables: UpdateLineItemPrescriptionVariables = {
      updateLineItemPrescriptionRequest,
    };
    return mutate({
      variables,
    });
  }, [mutate, updateLineItemPrescriptionRequest]);

  return { updateQuoteLineItems, data, error, loading, called };
};

interface ExtendedUpdateRxMutation extends Omit<MutationResult, "client" | "reset"> {
  updateRx: (variables: UpdateLineItemPrescriptionVariables) => Promise<FetchResult<UpdateLineItemPrescription>>;
}

export const useUpdateLineItemRx = (): ExtendedUpdateRxMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<UpdateLineItemPrescription, UpdateLineItemPrescriptionVariables>(
    UPDATE_LINE_ITEM_PRESCRIPTION,
    {
      onError: () => void 0,
    },
  );

  const updateRx = useCallback(
    async (variables: UpdateLineItemPrescriptionVariables) => {
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateRx, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { StaffToken, StaffTokenVariables } from "./../../types/generated";

export const STAFF_TOKEN = gql`
  mutation StaffToken($employeeNumber: String!) {
    staffToken(employeeNumber: $employeeNumber) {
      access_token
      expiresAt
      firstName
      lastName
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  getStaffToken: (employeeNumber: string) => Promise<FetchResult<StaffToken>>;
}

export const useGetStaffToken = (): ExtendedMutation => {
  const [mutate, { loading, error, called, data }] = useMutation<StaffToken, StaffTokenVariables>(STAFF_TOKEN, {
    onError: void 0,
  });

  const getStaffToken = useCallback(
    (employeeNumber: string) => {
      return mutate({
        variables: {
          employeeNumber,
        },
      });
    },
    [mutate],
  );

  return {
    getStaffToken,
    loading,
    error,
    called,
    data,
  };
};

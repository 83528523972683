import { FetchResult, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { ADD_BUNDLE_TO_CART } from ".";
import { AddBundleToCartMutation, AddBundleToCartMutationVariables } from "../../types";

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  addBundleToCart: (variables: AddBundleToCartMutationVariables) => Promise<FetchResult<AddBundleToCartMutation>>;
}

export const useAddBundle = (): ExtendedMutation => {
  const [mutateAddBundleToCart, { loading, error, data, called }] = useMutation<AddBundleToCartMutation, AddBundleToCartMutationVariables>(
    ADD_BUNDLE_TO_CART,
    {
      onError: void 0,
    },
  );

  const addBundleToCart = useCallback(
    (variables: AddBundleToCartMutationVariables) => {
      return mutateAddBundleToCart({
        variables,
      });
    },
    [mutateAddBundleToCart],
  );

  return {
    addBundleToCart,
    loading,
    error,
    data,
    called,
  };
};

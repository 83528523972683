import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { ReceiveInventory, ReceiveInventoryVariables } from "../../types";
import { useCallback } from "react";

export const RECEIVE_INVENTORY = gql`
  mutation ReceiveInventory($batchNumber: ID!) {
    receiveInventory(batchNumber: $batchNumber) {
      sku
      id
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  receiveInventory: (batchNumber: string) => Promise<FetchResult<ReceiveInventory>>;
}

export const useReceiveInventory = (handleOnComplete?: (data: ReceiveInventory) => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<ReceiveInventory, ReceiveInventoryVariables>(RECEIVE_INVENTORY, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: true,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const receiveInventory = useCallback(
    (batchNumber: string) => {
      const variables = {
        batchNumber,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { receiveInventory, data, error, loading, called };
};

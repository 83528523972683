import { gql, LazyQueryExecFunction, OperationVariables, QueryResult, useLazyQuery } from "@apollo/client";

import { Orders, OrdersSummaryTable } from "../../types";
import { LINE_ITEM_FIELDS } from "../fragments";

export const PATIENT_ORDERS = gql`
  ${LINE_ITEM_FIELDS}
  query Orders {
    orders {
      lineItems {
        ...LineItemFields
      }
      id
      status
      orderNumber
      createdAt
      customFields {
        specialInstructions
      }
    }
  }
`;

export const PATIENT_ORDERS_SUMMARY_TABLE = gql`
  query OrdersSummaryTable {
    orders {
      id
      status
      orderNumber
      createdAt
      customFields {
        specialInstructions
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<Orders>, "data" | "loading" | "error" | "variables" | "refetch" | "called"> {
  getOrders: LazyQueryExecFunction<Orders, OperationVariables>;
}

interface TableExtendedQueryResult extends Pick<QueryResult<OrdersSummaryTable>, "data" | "loading" | "error" | "variables" | "refetch" | "called"> {
  getOrders: LazyQueryExecFunction<OrdersSummaryTable, OperationVariables>;
}

export const usePatientOrdersQuery = (): ExtendedQueryResult => {
  const [getOrders, { data, error, loading, called, variables, refetch }] = useLazyQuery<Orders>(PATIENT_ORDERS, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
  });

  return { getOrders, data, error, loading, called, variables, refetch };
};

export const usePatientOrdersTableQuery = (): TableExtendedQueryResult => {
  const [getOrders, { data, error, loading, called, variables, refetch }] = useLazyQuery<OrdersSummaryTable>(PATIENT_ORDERS_SUMMARY_TABLE, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
  });

  return { getOrders, data, error, loading, called, variables, refetch };
};

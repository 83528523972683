import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { CreateGlassesPrescription, CreateGlassesPrescriptionVariables, GlassesPrescriptionRequest } from "../../types";
import { GLASSES_PRESCRIPTION_FIELDS } from "../fragments";
import { useCallback } from "react";

export const CREATE_GLASSES_PRESCRIPTION = gql`
  ${GLASSES_PRESCRIPTION_FIELDS}
  mutation CreateGlassesPrescription($glassesPrescriptionRequest: GlassesPrescriptionRequest!) {
    createGlassesPrescription(glassesPrescriptionRequest: $glassesPrescriptionRequest) {
      ...GlassesPrescriptionFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult<CreateGlassesPrescription>, "client" | "reset"> {
  createGlassesPrescription: (glassesPrescriptionRequest: GlassesPrescriptionRequest) => Promise<FetchResult<CreateGlassesPrescription>>;
}

export const useCreateGlassesPrescriptionMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<CreateGlassesPrescription, CreateGlassesPrescriptionVariables>(
    CREATE_GLASSES_PRESCRIPTION,
    {
      onError: () => void 0,
      notifyOnNetworkStatusChange: true,
    },
  );

  const createGlassesPrescription = useCallback(
    (glassesPrescriptionRequest: GlassesPrescriptionRequest) => {
      const variables = {
        glassesPrescriptionRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { createGlassesPrescription, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { CART_FIELDS } from "../fragments";
import { SetNoAction, SetNoActionVariables } from "./../../types/generated";

export const SET_NO_ACTION = gql`
  ${CART_FIELDS}
  mutation SetNoAction($bundleNumbers: [Int]!, $action: Boolean) {
    setNoAction(bundleNumbers: $bundleNumbers, action: $action) {
      ...CartFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setNoAction: (bundleNumbers: number[], action?: boolean) => Promise<FetchResult<SetNoAction>>;
}

export const useSetNoAction = (): ExtendedMutation => {
  const [mutate, { loading, error, called, data }] = useMutation<SetNoAction, SetNoActionVariables>(SET_NO_ACTION, {
    onError: void 0,
  });

  const setNoAction = useCallback(
    (bundleNumbers: number[], action?: boolean) => {
      return mutate({
        variables: {
          bundleNumbers: bundleNumbers,
          action: action,
        },
      });
    },
    [mutate],
  );

  return {
    setNoAction: setNoAction,
    loading,
    error,
    called,
    data,
  };
};

import { gql, QueryResult, useLazyQuery } from "@apollo/client";

import { OrderContactMethod, OrderContactMethodVariables } from "../../types";

const storeCode = process.env.REACT_APP_STORE_COUNTRY_CODE;

export const ORDER_CONTACT_METHOD = gql`
  query OrderContactMethod($orderNumber: String!) {
    storeOrderByNumber(orderNumber: $orderNumber) {
      customFields {
        contactMethod
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<OrderContactMethod>, "data" | "loading" | "error"> {
  getOrderContactMethod: ({ variables }: { variables: OrderContactMethodVariables }) => void;
}

export const useOrderContactMethodQuery = (): ExtendedQueryResult => {
  const [getOrderContactMethod, { data, loading }] = useLazyQuery<OrderContactMethod, OrderContactMethodVariables>(ORDER_CONTACT_METHOD, {
    onError: () => void 0,
    fetchPolicy: "network-only",
    context: {
      headers: {
        store: storeCode || "",
      },
    },
  });

  return {
    getOrderContactMethod,
    data,
    loading,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { CART_FIELDS } from "../fragments";
import { replaceLineItemDraft, ReplaceLineItemMutation, ReplaceLineItemMutationVariables } from "./../../types/generated";

export const REPLACE_LINE_ITEM = gql`
  ${CART_FIELDS}
  mutation ReplaceLineItemMutation($replaceLineItemsRequest: [replaceLineItemDraft]!) {
    replaceLineItem(replaceLineItemsRequest: $replaceLineItemsRequest) {
      ...CartFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  replaceLineItem: (item: replaceLineItemDraft[]) => Promise<FetchResult<ReplaceLineItemMutation>>;
}

export const useReplaceLineItemMutation = (): ExtendedMutation => {
  const [mutate, { loading, error, called, data }] = useMutation<ReplaceLineItemMutation, ReplaceLineItemMutationVariables>(REPLACE_LINE_ITEM, {
    onError: void 0,
  });

  const replaceLineItem = useCallback(
    (items: replaceLineItemDraft[]) => {
      return mutate({
        variables: {
          replaceLineItemsRequest: items,
        },
      });
    },
    [mutate],
  );

  return {
    replaceLineItem,
    loading,
    error,
    called,
    data,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosUpdateCalendarNoteMutation, PosUpdateCalendarNoteMutationVariables } from "../../types";
import { useCallback } from "react";

export const UPDATE_CALENDAR_NOTE = gql`
  mutation PosUpdateCalendarNoteMutation($id: ID!, $note: String!) {
    updateCalendarNote(id: $id, note: $note) {
      id
      date
      note
      updatedAt
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updateCalendarNote: (id: string, note: string) => Promise<FetchResult<PosUpdateCalendarNoteMutation>>;
}

export const useUpdateCalendarNoteMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosUpdateCalendarNoteMutation, PosUpdateCalendarNoteMutationVariables>(
    UPDATE_CALENDAR_NOTE,
    {
      onError: () => void 0,
    },
  );

  const updateCalendarNote = useCallback(
    (id: string, note: string) => {
      const variables: PosUpdateCalendarNoteMutationVariables = {
        id,
        note,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateCalendarNote, data, error, loading, called };
};

import { CountryCode, isValidPhoneNumber } from "libphonenumber-js";

import {
  addToCartDraft,
  CartPrice,
  ContactLensSelectedProduct,
  ContactLensSelectedProducts,
  DataProduct,
  EyeType,
  SALES_TYPE,
  SPHERE_TYPE,
  StateSmsDetails,
  Variant,
} from "../../types";
import { getCountryCode, getProductDiscountedPrice } from "../../utils";
import { RootState } from "../store";

export const SUBSCRIPTION_DISCOUNT = 0.1;

export const selectSalesType = (state: RootState): SALES_TYPE | string => state.contactLens.salesType;
export const selectSearchProducts = (state: RootState): DataProduct[] => state.contactLens.productSearch;
export const selectOrderItems = (state: RootState): addToCartDraft[] => [
  ...(state.contactLens.selected[EyeType.RIGHT_OD].productFamily ? [state.contactLens.selected[EyeType.RIGHT_OD].itemToAdd] : []),
  ...(state.contactLens.selected[EyeType.LEFT_OS].productFamily ? [state.contactLens.selected[EyeType.LEFT_OS].itemToAdd] : []),
];

export const selectSMSDetails = (state: RootState): StateSmsDetails => state.contactLens.smsDetails;

type FieldValidationValue = string | number | null | boolean | undefined;

const countryCode = getCountryCode().toUpperCase() as CountryCode;

export const selectOrderIsValid = (state: RootState): Record<number, boolean> => {
  const leftSelected = state.contactLens.selected[EyeType.LEFT_OS];
  const rightSelected = state.contactLens.selected[EyeType.RIGHT_OD];

  const stepZeroRequired: FieldValidationValue[] = [
    state.contactLens.salesType,
    state.contactLens.smsDetails.name,
    state.contactLens.smsDetails.phoneNumber,
    isValidPhoneNumber(state.contactLens.smsDetails.phoneNumber, countryCode),
  ];

  const stepOneRequired: FieldValidationValue[] = [
    ...(leftSelected.productFamily
      ? [leftSelected.productName, ...(state.contactLens.salesType === SALES_TYPE.SUBSCRIPTION ? [leftSelected.itemToAdd.frequency] : [])]
      : []),
    ...(rightSelected.productFamily
      ? [rightSelected.productName, ...(state.contactLens.salesType === SALES_TYPE.SUBSCRIPTION ? [rightSelected.itemToAdd.frequency] : [])]
      : []),
  ];

  const sphereProps: FieldValidationValue[] = [
    ...(leftSelected.itemToAdd.sphere === SPHERE_TYPE.SPHERIC ? [leftSelected.itemToAdd.power] : []),
    ...(rightSelected.itemToAdd.sphere === SPHERE_TYPE.SPHERIC ? [rightSelected.itemToAdd.power] : []),
  ];

  const multifocalProps: FieldValidationValue[] = [
    ...(leftSelected.itemToAdd.sphere === SPHERE_TYPE.MULTI_FOCAL ? [leftSelected.itemToAdd.power, leftSelected.itemToAdd.add] : []),
    ...(rightSelected.itemToAdd.sphere === SPHERE_TYPE.MULTI_FOCAL ? [rightSelected.itemToAdd.power, rightSelected.itemToAdd.add] : []),
  ];

  const toricProps: FieldValidationValue[] = [
    ...(leftSelected.itemToAdd.sphere === SPHERE_TYPE.TORIC
      ? [leftSelected.itemToAdd.power, leftSelected.itemToAdd.cyl, leftSelected.itemToAdd.axis]
      : []),
    ...(rightSelected.itemToAdd.sphere === SPHERE_TYPE.TORIC
      ? [rightSelected.itemToAdd.power, rightSelected.itemToAdd.cyl, rightSelected.itemToAdd.axis]
      : []),
  ];

  const multifocalToricProps: FieldValidationValue[] = [
    ...(leftSelected.itemToAdd.sphere === SPHERE_TYPE.MULTI_FOCAL_TORIC
      ? [leftSelected.itemToAdd.power, leftSelected.itemToAdd.cyl, leftSelected.itemToAdd.add, leftSelected.itemToAdd.axis]
      : []),
    ...(rightSelected.itemToAdd.sphere === SPHERE_TYPE.MULTI_FOCAL_TORIC
      ? [rightSelected.itemToAdd.power, rightSelected.itemToAdd.cyl, rightSelected.itemToAdd.add, rightSelected.itemToAdd.axis]
      : []),
  ];

  const stepTwoRequired: FieldValidationValue[] = [
    ...multifocalProps,
    ...toricProps,
    ...sphereProps,
    ...multifocalToricProps,
    leftSelected.productFamily ? leftSelected.itemToAdd.quantity > 0 : true,
    rightSelected.productFamily ? rightSelected.itemToAdd.quantity > 0 : true,
  ];
  return {
    0: [...stepZeroRequired].every(Boolean),
    1: [...stepZeroRequired, ...stepOneRequired].every(Boolean),
    2: [...stepZeroRequired, ...stepOneRequired, ...stepTwoRequired].every(Boolean),
    3: [...stepZeroRequired, ...stepOneRequired, ...stepTwoRequired].every(Boolean),
  };
};

export const selectSymmetricProduct = (state: RootState): boolean => state.contactLens.symmetricProduct;

export const selectProductSelections = (state: RootState): ContactLensSelectedProducts => state.contactLens.selected;

export const selectRightEyeVariant = (state: RootState): Variant | undefined => state.contactLens.selected[EyeType.RIGHT_OD].variant;

export const selectLeftEyeVariant = (state: RootState): Variant | undefined => state.contactLens.selected[EyeType.LEFT_OS].variant;

export const selectRightEyeItemToAdd = (state: RootState): addToCartDraft => state.contactLens.selected[EyeType.RIGHT_OD].itemToAdd;

export const selectLeftEyeItemToAdd = (state: RootState): addToCartDraft => state.contactLens.selected[EyeType.LEFT_OS].itemToAdd;

export const selectNoLeftEyeSubscription = (state: RootState): boolean => !state.contactLens.selected[EyeType.LEFT_OS].productFamily?.length;

export const selectNoRightEyeSubscription = (state: RootState): boolean => !state.contactLens.selected[EyeType.RIGHT_OD].productFamily?.length;

type PriceTotals = Pick<CartPrice, "subtotal" | "discountedTotal">;

const calcPricesForEye = (selected: ContactLensSelectedProduct, salesType: SALES_TYPE | string): PriceTotals => {
  const exists = !!selected.productFamily?.length;
  if (!exists) {
    return {
      subtotal: 0,
      discountedTotal: 0,
    };
  }

  const variant = selected.variant as Variant;

  const unitPrice = getProductDiscountedPrice(variant.currentPrice) ?? 0;

  const {
    itemToAdd: { quantity },
  } = selected;

  const subtotal = quantity * unitPrice;
  const discountedTotal = salesType === SALES_TYPE.SUBSCRIPTION ? subtotal * (1 - SUBSCRIPTION_DISCOUNT) : subtotal;

  return {
    subtotal,
    discountedTotal,
  };
};

export const selectPriceDetails = (state: RootState): CartPrice => {
  const salesType = state.contactLens.salesType;
  const { subtotal, discountedTotal } = [state.contactLens.selected[EyeType.LEFT_OS], state.contactLens.selected[EyeType.RIGHT_OD]].reduce(
    (accum: PriceTotals, selected: ContactLensSelectedProduct) => {
      const { subtotal, discountedTotal } = calcPricesForEye(selected, salesType);
      accum.subtotal += subtotal;
      accum.discountedTotal += discountedTotal;

      return accum;
    },
    {
      subtotal: 0,
      discountedTotal: 0,
    },
  );

  const discountPercent = ((subtotal - discountedTotal) / subtotal) * 100;
  const discountAmount = subtotal - discountedTotal;

  return {
    subtotal,
    discountPercent: isNaN(discountPercent) ? 0 : discountPercent,
    discountedTotal,
    discountAmount,
  };
};

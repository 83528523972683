import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { SendPrescription, SendPrescriptionRequest } from "../../types";
import { useCallback } from "react";

const MUTATION_SEND_PRESCRIPTION = gql`
  mutation SendPrescription($sendPrescriptionRequest: SendPrescriptionRequest!) {
    sendPrescription(request: $sendPrescriptionRequest)
  }
`;

export interface IUseSendPrescriptionMutation extends Omit<MutationResult, "client" | "reset"> {
  sendPrescription: (request: SendPrescriptionRequest) => Promise<FetchResult<SendPrescription>>;
}

export const useSendPrescriptionMutation = (): IUseSendPrescriptionMutation => {
  const [mutate, { loading, error, called }] = useMutation(MUTATION_SEND_PRESCRIPTION);

  const sendPrescription = useCallback(
    async (request: SendPrescriptionRequest) => {
      return mutate({ variables: { sendPrescriptionRequest: request } });
    },
    [mutate],
  );

  return {
    sendPrescription,
    loading,
    error,
    called,
  };
};

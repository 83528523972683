import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { selectActiveDistributionChannelKey } from "../../state/authentication";
import { useAppSelector } from "../../state/hooks";
import { Appointment, PosCreateAppointmentMutation, PosCreateAppointmentMutationVariables } from "../../types";
import { toAppointmentCreateOperationVariables } from "../../utils";
import { APPOINTMENT_TYPE_FIELDS, CORE_OPTOMETRIST_FIELDS, PATIENT_FIELDS } from "../fragments";
import { useCallback } from "react";

export const CREATE_APPOINTMENT = gql`
  ${APPOINTMENT_TYPE_FIELDS}
  ${CORE_OPTOMETRIST_FIELDS}
  ${PATIENT_FIELDS}
  mutation PosCreateAppointmentMutation($posCreateAppointmentAppointmentRequest: AppointmentRequest) {
    posCreateAppointment(appointmentRequest: $posCreateAppointmentAppointmentRequest) {
      id
      countryCode
      startAt
      endAt
      source
      notes
      status
      distributionChannel {
        key
      }
      patient {
        ...PatientFields
      }
      optometrist {
        ...CoreOptometristFields
        appointmentTypes {
          ...AppointmentTypeFields
        }
      }
      appointmentType {
        ...AppointmentTypeFields
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  createAppointment: (appointment: Appointment) => Promise<FetchResult<PosCreateAppointmentMutation>> | void;
}

export const useCreateAppointmentMutation = (): ExtendedMutation => {
  const distributionChannelKey = useAppSelector(selectActiveDistributionChannelKey);
  const [mutate, { data, error, loading, called }] = useMutation<PosCreateAppointmentMutation, PosCreateAppointmentMutationVariables>(
    CREATE_APPOINTMENT,
    {
      onError: () => void 0,
    },
  );

  const createAppointment = useCallback(
    (appointment: Appointment) => {
      if (appointment && distributionChannelKey) {
        const variables: PosCreateAppointmentMutationVariables = toAppointmentCreateOperationVariables(appointment, distributionChannelKey);
        return mutate({
          variables,
        });
      }
    },
    [distributionChannelKey, mutate],
  );

  return { createAppointment, data, error, loading, called };
};

import styled from "@emotion/styled";
import { Grow, MenuItem, MenuList, Paper, Popper, Stack } from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useReadConfig } from "../data/queries";
import { ConfigReportItem, ConfigType } from "../types";

const StyledPopper = styled(Popper)`
  position: fixed !important;
  padding: 0.5rem 1rem;
`;

type Props = {
  children?: React.ReactNode;
};

const SidebarReportMenu: FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { readConfig, data } = useReadConfig();

  useEffect(() => {
    readConfig({
      variables: {
        type: ConfigType.ReportMenu,
      },
    });
  }, [readConfig]);

  const reportItems = useMemo(() => {
    return (data?.readConfig?.value as ConfigReportItem[]) || [];
  }, [data?.readConfig?.value]);

  const navigate = useNavigate();

  const handleShowMenu = useCallback((show: boolean): void => {
    setOpen(show);
  }, []);

  const handleSelect = useCallback(
    (reportId: string): void => {
      navigate(`/report?id=${reportId}`);
    },
    [navigate],
  );

  const handleListKeyDown = useCallback((event: React.KeyboardEvent): void => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }, []);

  return (
    <Stack
      ref={anchorRef}
      onMouseEnter={() => {
        handleShowMenu(true);
      }}
      onMouseLeave={() => {
        handleShowMenu(false);
      }}
    >
      <StyledPopper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                {reportItems.map((item) => (
                  <MenuItem key={item.id} onClick={() => handleSelect(item.id as string)}>
                    {item.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </StyledPopper>
      {children}
    </Stack>
  );
};

export default SidebarReportMenu;

export const TEST_DATA_IDS = {
  ADD_APPOINTMENT_HEADER_CLOSE_ICON: "appointments-calendar-add-appointment-close-icon",
  ADD_AVAILABILITY_ICON: "add-availability-icon",
  APPOINTMENTS_PATIENT_SEARCH_INPUT: "appointments-patient-search-input",
  AVAILABILITY_CALENDAR: "optom-header-container",
  AVAILABILITY_CALENDAR_INPUT: "availability-calendar-input",
  AVAILABILITY_PEAK_TIME_PICKER_END: "availability-peak-time-picker-end",
  AVAILABILITY_PEAK_TIME_PICKER_START: "availability-peak-time-picker-start",
  AVAILABILITY_SELECT_STORE: "availability-select-store",
  AVAILABILITY_TIME_PICKER_END: "availability-time-picker-end",
  AVAILABILITY_TIME_PICKER_START: "availability-time-picker-start",
  AVAILABILITY_TOGGLE: "availability-toggle",
  CALENDAR_APPOINTMENT_STATUS_UPDATE_SELECT_CONTAINER: "appointments-patientcard-appointment-status-update-container",
  ALERT_SNACKBAR: "alert-snackbar",
  CALENDAR_AVAILABLE_TIMESLOT: "appointments-calendar-available-timeslot",
  CALENDAR_CUSTOM_EVENT_ONLINE_STATUS_ICON: "appointments-calendar-custom-event-online-status-icon",
  CALENDAR_CUSTOM_EVENT_WHATSAPP: "appointments-calendar-custom-event-whatsapp",
  CALENDAR_DATEPICKER: "appointments-toolbar-datepicker",
  CALENDAR_DATEPICKER_INPUT: "appointments-toolbar-datepicker-input",
  CALENDAR_DELETE_APPOINTMENT_CONFIRMATION: "appointments-delete-confirmation-dialog",
  CALENDAR_EVENT: "appointments-calendar-event",
  CALENDAR_GUTTER_TIMESLOT: "appointments-calendar-gutter-timeslot",
  CALENDAR_MOBILE_DATEPICKER_CLICKOUT: "appointments-toolbar-datepicker-mobile-clickout",
  CALENDAR_NOTE_CLOSE_ICON: "calendar-note-close-icon",
  CALENDAR_NOTE_DELETE_DALOG: "calendar-note-delete-dialog",
  CALENDAR_NOTE_DELETE_ICON: "calendar-note-delete-icon",
  CALENDAR_NOTE_EDIT_ICON: "calendar-note-edit-icon",
  CALENDAR_NOTE_NOTIFICATION_OFF: "calendar-note-notification-off",
  CALENDAR_NOTE_NOTIFICATION_ON: "calendar-note-notification-on",
  CALENDAR_SHOW_CANCELLED_ICON: "appointments-calendar-show-cancelled-icon",
  CALENDAR_SINGLE_DAY_VIEW_ICON: "appointments-calendar-single-day-view-icon",
  CALENDAR_THREE_DAY_VIEW_ICON: "appointments-calendar-three-day-view-icon",
  CALENDAR_TOOLBAR_NAV_NEXT: "appointments-toolbar-datepicker-next-date-nav-icon",
  CALENDAR_TOOLBAR_NAV_PREV: "appointments-toolbar-datepicker-previous-date-nav-icon",
  CALENDAR_TWENTY_MINUTE_VIEW_ICON: "appointments-calendar-twenty_minute-view-icon",
  CALENDAR_UNAVAILABLE_TIMESLOT: "appointments-calendar-unavailable-timeslot",
  CIRCULAR_LOADER: "mui-circular-loader-ui,",
  CT_ORDER_ADD_TO_CART_LOADING_INDICATOR: "ct-order-add-to-cart-loading-indicator",
  CT_ORDER_STEP_ORDER_SUMMARY: "ct-order-step-order-summary",
  CT_ORDER_STEP_PATIENT_DETAILS: "ct-order-step-patient-details",
  CT_ORDER_STEP_PRODUCT_DETAILS: "ct-order-step-product-details",
  CT_ORDER_STEP_SELECT_PRODUCT: "ct-order-step-select-product",
  CT_SEARCH_PRODUCTS_LOADING_INDICATOR: "ct-search-products-query-loading-indicator",
  CT_SELECT_ON_DEMAND_SALES_TYPE_OPTION: "ct-select-on-demand-sales-type-option",
  CT_SELECT_SUBSCRIPTION_SALES_TYPE_OPTION: "ct-select-subscription-sales-type-option",
  DISPENSING_CART_ICON: "dispensing-cart-icon",
  DISPENSING_ENTER_PRESCRIPTION: "dispensing-enter-prescription",
  DISPENSING_FLOATING_BUTTON: "dispensing-floating-button",
  DISPENSING_ORDER_SUCCESS_MESSAGE: "dispensing-order-success-message",
  DISPENSING_PRODUCT_SEARCH_INPUT: "dispensing-product-search-input",
  DISPENSING_DELETE_BUNDLE_ICON: "dispensing-delete-bundle-icon",
  DISPENSING_SUNOPT_CONFIRM_DIALOG: "dispensing-sunopt-confirm-dialog",
  EXAM_PATIENT_DETAIL_ARROW: "exam-paitent-detail-arrow",
  EXAM_SHOW_PATIENT_ARROW: "show-patient-arrow",
  FILE_ICON: "file-icon",
  GLOBAL_SEARCH_INPUT: "global-search-input",
  INVENTORY_WRITEOFF_REASON_SELECT: "inventory-writeoff-reason-select",
  INVENTORY_TRANSFER_TYPE_SELECT: "inventory-transfer-type-select",
  LOADING_SKELETON_ITEM: "loading-skeleton-item",
  PATIENT_CARD_HEADER_CLOSE_ICON: "appointments-calendar-patient-card-close-icon",
  PATIENT_CARD_HEADER_DELETE_ICON: "appointments-calendar-patient-card-delete-icon",
  PATIENT_CARD_HEADER_EDIT_ICON: "appointments-calendar-patient-card-edit-icon",
  PATIENT_CONTACT: "patient-contact",
  PATIENT_EMAIL: "patient-email",
  PATIENT_FIRST_NAME: "patient-first-name",
  PATIENT_LAST_NAME: "patient-last-name",
  PATIENT_MEDICARE: "patient-medicare",
  PATIENT_PROFILE_EDIT_ICON: "patient-profile-edit-icon",
  PATIENT_SEARCH_INPUT: "patient-search-input",
  PAYMENT_METHOD_ADD_ICON: "payment-method-add-icon",
  PAYMENT_METHOD_DELETE_ICON: "payment-method-delete-icon",
  PRIVATE_HEALTH_FUND_INPUT: "private-health-fund-input",
  PHYSICAL_TRACE_ICON: "physical-trace-icon",
  LENS_ONLY_WARN_ICON: "lens-only-warn-icon",
  NORMAL_TRACE_ICON: "normal-trace-icon",
  PRIVACY_MODE_ICON: "appointments-toolbar-views-privacy-mode-icon",
  REMOVE_AVAILABILITY_ICON: "remove-availability-icon",
  REFUND_SELECT_REASON: "refund-select-reason",
  REFUND_INPUT_REASON: "refund-input-reason",
  REFUND_STATUS_DIALOG: "refund-status-dialog",
  REFUND_COMMENT_DIALOG: "refund-comment-dialog",
  REFUND_FAULTY_COMMENT_INPUT: "refund-faulty-comment-input",
  REFUND_FAULTY_SELECT_REASON: "refund-faulty_select-reason",
  RETURN_ALL_DIALOG: "return-all-dialog",
  SELECT_OPTOM: "select-optom",
  SHOW_CALENDAR_DATEPICKER_ICON: "appointments-toolbar-show-datepicker-icon",
  SIDEBAR_BRAND_LOGO: "sidebar-brand-logo",
  SIDEBAR_CONTACT_LENS: "sidebar-contact-lens",
  SIDEBAR_NAV_ITEM: "sidebar-nav-item",
  STORE_SEARCH_INPUT: "store-search-input",
  SUPPLY_CHANNEL_INPUT: "supply-channel-input",
  SUN_OPT_ICON: "sun-opt-icon",
  EXTEND_MORE_ICON: "extend-more-icon",
  EXTEND_LESS_ICON: "extend-less-icon",
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PROFILE_TABS } from "../../components/PatientProfileNew";
import {
  ContactsPrescription,
  Customer_customer,
  Customer_customer_addresses,
  CustomerGroups_posRetrieveCustomerGroups,
  GlassesPrescription,
  OverlappingPrescription,
  Patient,
  RecursivePartial,
} from "../../types";
import { lensBuilderSlice } from "../lensBuilder/slice";

export interface PatientState {
  activePatient?: Patient;
  hiddenActivePatient?: Patient;
  activeCustomer?: Customer_customer;
  customerGroups: CustomerGroups_posRetrieveCustomerGroups[];
  contactsPrescriptions: ContactsPrescription[];
  formPrescription?: RecursivePartial<OverlappingPrescription>;
  glassesPrescriptions: GlassesPrescription[];
  showDetail: boolean;
  profileTab: PROFILE_TABS;
  addresses: Customer_customer_addresses[];
}

export const initialPatientState: PatientState = {
  activePatient: undefined,
  hiddenActivePatient: undefined,
  activeCustomer: undefined,
  customerGroups: [],
  contactsPrescriptions: [],
  formPrescription: undefined,
  glassesPrescriptions: [],
  showDetail: false,
  profileTab: 0 as PROFILE_TABS,
  addresses: [],
};

interface PatientResponse extends Patient {
  __typename?: string;
}

export const patientSlice = createSlice({
  name: "patient",
  initialState: initialPatientState,
  reducers: {
    setActivePatient: (state, action: PayloadAction<PatientResponse | undefined>) => {
      if (action?.payload) {
        // we do an object value equality check when editing patient data, ensure patient never includes __typename from graphql response
        const { __typename, ...rest } = action.payload;
        state.activePatient = rest;
      } else {
        state.activePatient = undefined;
      }
    },
    setHiddenActivePatient: (state) => {
      state.hiddenActivePatient = state.activePatient;
      state.activePatient = undefined;
    },
    resetHiddenActivePatient: (state) => {
      state.activePatient = state.hiddenActivePatient;
      state.hiddenActivePatient = undefined;
    },
    setActiveCustomer: (state, action: PayloadAction<Customer_customer | undefined>) => {
      if (action?.payload) {
        state.activeCustomer = action.payload;
      } else {
        state.activePatient = undefined;
      }
    },
    setCustomerGroups: (state, action: PayloadAction<CustomerGroups_posRetrieveCustomerGroups[]>) => {
      state.customerGroups = action.payload;
    },
    setFormPrescription: (state, action: PayloadAction<RecursivePartial<OverlappingPrescription> | undefined>) => {
      state.formPrescription = action.payload;
    },
    setShowDetail: (state, action: PayloadAction<boolean>) => {
      state.showDetail = action.payload;
    },
    setProfileTab: (state, action: PayloadAction<PROFILE_TABS>) => {
      state.profileTab = action.payload;
    },
    setAddresses: (state, action: PayloadAction<Customer_customer_addresses[]>) => {
      state.addresses = action.payload;
    },
    setActivePatientExternalId: (state, action: PayloadAction<string>) => {
      state.activePatient = { ...state.activePatient, externalId: action.payload } as Patient;
    },
  },
  extraReducers: (builder) => {
    // patient formPrescription is displayed in the lensbuilder flow, we want to clear that out when resetting
    builder.addCase(lensBuilderSlice.actions.resetOnProductChange, (state) => {
      state.formPrescription = undefined;
    });
  },
});

export const {
  setActivePatient,
  setHiddenActivePatient,
  resetHiddenActivePatient,
  setActiveCustomer,
  setCustomerGroups,
  setFormPrescription,
  setShowDetail,
  setProfileTab,
  setAddresses,
  setActivePatientExternalId,
} = patientSlice.actions;

export default patientSlice.reducer;

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { UpdatePaymentStateAsPaid, UpdatePaymentStateAsPaidVariables } from "../../types";
import { ORDER_FIELD } from "../fragments";

export const UPDATE_PAYMENT_STATE_AS_PAID = gql`
  ${ORDER_FIELD}
  mutation UpdatePaymentStateAsPaid($orderId: ID!, $paymentId: ID!, $transactionType: String) {
    updatePaymentStateAsPaid(orderId: $orderId, paymentId: $paymentId, transactionType: $transactionType) {
      ...OrderField
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult<UpdatePaymentStateAsPaid>, "client" | "reset"> {
  updatePaymentStateAsPaid: (variables: UpdatePaymentStateAsPaidVariables) => Promise<FetchResult<UpdatePaymentStateAsPaid>>;
}

export const useUpdatePaymentStateAsPaid = (): ExtendedMutation => {
  const [mutate, { loading, error, data, called }] = useMutation<UpdatePaymentStateAsPaid, UpdatePaymentStateAsPaidVariables>(
    UPDATE_PAYMENT_STATE_AS_PAID,
    {
      onError: void 0,
    },
  );

  const updatePaymentStateAsPaid = useCallback(
    (variables: UpdatePaymentStateAsPaidVariables) => {
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return {
    updatePaymentStateAsPaid,
    loading,
    error,
    data,
    called,
  };
};

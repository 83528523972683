import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { ProfileNote, ResourceAction, UpdateCustomerProfileNote, UpdateCustomerProfileNoteVariables } from "../../types";
import { PROFILE_NOTES } from "../fragments";
import { useCallback } from "react";

export const MUTATE_UPDATE_CUSTOMER_PROFILE_NOTE = gql`
  ${PROFILE_NOTES}
  mutation UpdateCustomerProfileNote($input: CustomerProfileNoteInput!) {
    updateCustomerProfileNote(customerProfileNoteInput: $input) {
      ...ProfileNotes
    }
  }
`;

export interface IUseUpdateCustomerProfileNotes extends Omit<MutationResult, "client" | "reset"> {
  createNote: (note: Partial<ProfileNote>) => Promise<FetchResult<UpdateCustomerProfileNote>>;
  updateNote: (note: Partial<ProfileNote>) => Promise<FetchResult<UpdateCustomerProfileNote>>;
  deleteNote: (id: string) => Promise<FetchResult<UpdateCustomerProfileNote>>;
}

export const useUpdateCustomerProfileNotes = (): IUseUpdateCustomerProfileNotes => {
  const [mutate, { loading, error, data, called }] = useMutation<UpdateCustomerProfileNote, UpdateCustomerProfileNoteVariables>(
    MUTATE_UPDATE_CUSTOMER_PROFILE_NOTE,
  );

  const createNote = useCallback(
    (note: Partial<ProfileNote>) => {
      return mutate({
        variables: {
          input: {
            ...note,
            action: ResourceAction.ADD,
          },
        },
      });
    },
    [mutate],
  );

  const updateNote = useCallback(
    (note: Partial<ProfileNote>) => {
      return mutate({
        variables: {
          input: {
            ...note,
            action: ResourceAction.UPDATE,
          },
        },
      });
    },
    [mutate],
  );
  const deleteNote = useCallback(
    (id: string) => {
      return mutate({
        variables: {
          input: {
            id,
            action: ResourceAction.DELETE,
          },
        },
      });
    },
    [mutate],
  );

  return {
    createNote,
    updateNote,
    deleteNote,
    loading,
    error,
    data,
    called,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { UpdateGlassesPrescription, UpdateGlassesPrescriptionRequest, UpdateGlassesPrescriptionVariables } from "../../types";
import { GLASSES_PRESCRIPTION_FIELDS } from "../fragments";
import { useCallback } from "react";

export const UPDATE_GLASSES_PRESCRIPTION = gql`
  ${GLASSES_PRESCRIPTION_FIELDS}
  mutation UpdateGlassesPrescription($glassesPrescriptionRequest: UpdateGlassesPrescriptionRequest!) {
    updateGlassesPrescription(updateGlassesPrescriptionRequest: $glassesPrescriptionRequest) {
      ...GlassesPrescriptionFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult<UpdateGlassesPrescription>, "client" | "reset"> {
  updateGlassesPrescription: (glassesPrescriptionRequest: UpdateGlassesPrescriptionRequest) => Promise<FetchResult<UpdateGlassesPrescription>>;
}

export const useUpdateGlassesPrescriptionMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<UpdateGlassesPrescription, UpdateGlassesPrescriptionVariables>(
    UPDATE_GLASSES_PRESCRIPTION,
    {
      onError: () => void 0,
      notifyOnNetworkStatusChange: true,
    },
  );

  const updateGlassesPrescription = useCallback(
    (glassesPrescriptionRequest: UpdateGlassesPrescriptionRequest) => {
      const variables = {
        glassesPrescriptionRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateGlassesPrescription, data, error, loading, called };
};

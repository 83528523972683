import { gql, LazyQueryExecFunction, OperationVariables, QueryResult, useLazyQuery } from "@apollo/client";

import { CustomerGroups } from "../../types";

export const CUSTOMER_GROUPS = gql`
  query CustomerGroups {
    posRetrieveCustomerGroups {
      id
      key
      name
      version
      benefit
      region
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<CustomerGroups>, "data" | "loading" | "error" | "called"> {
  getCustomerGroups: LazyQueryExecFunction<CustomerGroups, OperationVariables>;
}

export const useCustomerGroupQuery = (): ExtendedQueryResult => {
  const [getCustomerGroups, { data, error, loading, called }] = useLazyQuery<CustomerGroups>(CUSTOMER_GROUPS, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return {
    getCustomerGroups,
    data,
    error,
    loading,
    called,
  };
};

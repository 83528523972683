import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { ApplyPromotion, ApplyPromotionVariables } from "../../types";
import { useCallback } from "react";

export const APPLY_DISCOUNT_CODE = gql`
  mutation ApplyPromotion($promoCode: String!) {
    applyPromotion(promoCode: $promoCode) {
      id
      version
      totalPrice {
        centAmount
      }
      discountCodes {
        discountCode {
          code
          id
        }
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  applyDiscountCode: (promoCode: string) => Promise<FetchResult<ApplyPromotion>>;
}

export const useApplyDiscountCodeMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<ApplyPromotion, ApplyPromotionVariables>(APPLY_DISCOUNT_CODE, {
    onError: () => void 0,
  });

  const applyDiscountCode = useCallback(
    (promoCode: string) => {
      const variables: ApplyPromotionVariables = {
        promoCode,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { applyDiscountCode, data, error, loading, called };
};

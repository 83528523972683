import styled from "@emotion/styled";
import { Autocomplete, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { DISABLE_AUTO_FILL, PATHS, TEST_DATA_IDS } from "../constants";
import { useOrderByOrderNumberSearchLazyQuery, useSearchPatientsQuickQuery } from "../data/queries";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { setActiveOrderNumber } from "../state/orders";
import { selectActivePatient, setActivePatient } from "../state/patient";
import {
  Patient,
  SearchPatientsQuickElastic_searchPatientsElastic_patientSearchElasticResponse,
  StoreOrderByNumberSearch_storeOrderByNumber,
} from "../types";
import { getCountryCode } from "../utils";
import { formatPhoneNumber } from "../utils/phoneTest";
import { SentryTag } from "./Sentry/SentryConsts";

const SelectContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  position: relative;
`;

const SEARCH_THROTTLE_MS = 600;

type SearchOption = SearchPatientsQuickElastic_searchPatientsElastic_patientSearchElasticResponse | StoreOrderByNumberSearch_storeOrderByNumber;

const GlobalSearch: FC = () => {
  const [searchOptions, setSearchOptions] = useState<SearchOption[]>([]);
  const [value, setValue] = useState<SearchOption | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activePatient = useAppSelector(selectActivePatient);

  const { getOrder, abort: abortSearchOrder, ...orderResp } = useOrderByOrderNumberSearchLazyQuery();

  const { searchPatients, abort: abortSearchPatients, ...patientResp } = useSearchPatientsQuickQuery();

  const countryCode = getCountryCode().toUpperCase();
  const isCa = countryCode === "CA";

  useEffect(() => {
    if (orderResp.error) {
      setSearchOptions([]);
    }
  }, [orderResp.error]);

  useEffect(() => {
    if (location.pathname.includes(PATHS.PATIENT) || location.pathname.includes(PATHS.DISPENSING)) {
      setValue((prev) => {
        if (prev && activePatient?.id && "externalId" in prev && prev.externalId !== activePatient.externalId) {
          return null;
        } else if (!prev && activePatient?.id) {
          return {
            id: activePatient.id,
            firstName: activePatient.firstName,
            lastName: activePatient.lastName,
            externalId: activePatient.externalId,
            mobilePhone: activePatient.mobilePhone,
            preferredName: activePatient.preferredName,
          } as unknown as SearchOption;
        }
        return prev;
      });
    } else {
      if (!activePatient?.id) {
        setValue(null);
      }
    }
  }, [
    activePatient?.externalId,
    activePatient?.firstName,
    activePatient?.id,
    activePatient?.lastName,
    activePatient?.mobilePhone,
    activePatient?.preferredName,
    location.pathname,
  ]);

  const handleSelectOption = useCallback(
    (e: React.SyntheticEvent, selectedOption: SearchOption | null): void => {
      if (selectedOption !== null) {
        if ("firstName" in selectedOption) {
          // elastic response patient is not the complete patient object
          dispatch(setActivePatient(selectedOption as Patient));
          Sentry.setTag(SentryTag.Customer, `${selectedOption.firstName} ${selectedOption.lastName} ${selectedOption.externalId}`);
          navigate(PATHS.PATIENT);
          setValue(selectedOption);
        } else if ("orderNumber" in selectedOption && selectedOption.orderNumber) {
          dispatch(setActiveOrderNumber(selectedOption.orderNumber));
          navigate(PATHS.PATIENT_ORDER);
          setValue(selectedOption);
        }
      }
    },
    [dispatch, navigate],
  );

  // prevents default filtering of options that exlude inexact matches
  const filterOptions = useCallback((options: SearchOption[]): SearchOption[] => options, []);

  const debouncedSetKeyWord = useMemo((): ((query: string) => void) => {
    return debounce(async (query: string) => {
      if (query) {
        // we expect order numbers to have a BN prefix followed by min 6 numbers
        const orderNumberInKeyword = query.match(/BN[0-9]{6,}$/i)?.[0];
        if (query.slice(0, 2).toLowerCase() !== "bn" && !orderNumberInKeyword) {
          abortSearchPatients();
          const result = await searchPatients({ variables: { query, countryCode } });
          const patientSearchData = (result?.data?.searchPatientsElastic?.patientSearchElasticResponse ??
            []) as unknown as SearchPatientsQuickElastic_searchPatientsElastic_patientSearchElasticResponse[];
          setSearchOptions(patientSearchData);
        } else if (orderNumberInKeyword) {
          // order search is case sensitive and order numbers are upper case
          abortSearchOrder();
          const result = await getOrder(orderNumberInKeyword?.toUpperCase());
          const order = result.data?.storeOrderByNumber;
          if (order) {
            setSearchOptions([order]);
          } else {
            setSearchOptions([]);
          }
        }
      }
    }, SEARCH_THROTTLE_MS);
  }, [countryCode, searchPatients, getOrder, abortSearchPatients, abortSearchOrder]);

  const handleTextFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      debouncedSetKeyWord(event.target.value);
    },
    [debouncedSetKeyWord],
  );

  const formatLabel = useCallback(
    (option: SearchOption): string => {
      if ("firstName" in option) {
        return `${option.preferredName || option.firstName || ""} ${option.lastName ?? ""}\u00A0\u00A0\u00A0\u00A0\u00A0${
          formatPhoneNumber(option.mobilePhone) ?? ""
        }\u00A0\u00A0\u00A0\u00A0\u00A0${isCa ? "Z" : ""}${option?.externalId ?? ""}`;
      } else if ("orderNumber" in option) {
        return `Order number: ${option.orderNumber}`;
      }
      return "";
    },
    [isCa],
  );

  const loading = useMemo(() => orderResp.loading || patientResp.loading, [orderResp.loading, patientResp.loading]);

  return (
    <SelectContainer data-testid={TEST_DATA_IDS.GLOBAL_SEARCH_INPUT}>
      <Autocomplete
        size="small"
        autoHighlight
        clearOnEscape
        disablePortal
        getOptionLabel={formatLabel}
        loading={loading}
        loadingText="Searching..."
        filterOptions={filterOptions}
        value={value}
        onChange={handleSelectOption}
        options={searchOptions}
        noOptionsText="No results"
        sx={{ width: "100%", backgroundColor: "white" }}
        renderOption={(props, options) => (
          <li {...props} key={options.id}>
            {formatLabel(options)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, autoComplete: DISABLE_AUTO_FILL }}
            type="text"
            autoComplete={DISABLE_AUTO_FILL}
            label="Enter your search term"
            variant="outlined"
            name="global-search"
            onChange={handleTextFieldChange}
          />
        )}
      />
    </SelectContainer>
  );
};

export default GlobalSearch;

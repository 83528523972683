import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { SendQuote, SendQuoteVariables } from "../../types";
import { useCallback } from "react";

export const SEND_QUOTE = gql`
  mutation SendQuote($cartId: String!, $email: String!) {
    sendQuote(cartId: $cartId, email: $email) {
      isSuccess
      body
      type
      source
      cartId
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  sendQuote: (cartId: string, email: string) => Promise<FetchResult<SendQuote>>;
}

export const useSendQuote = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SendQuote, SendQuoteVariables>(SEND_QUOTE, {
    onError: () => void 0,
  });

  const sendQuote = useCallback(
    (cartId: string, email: string) => {
      const variables: SendQuoteVariables = {
        cartId,
        email,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { sendQuote, data, error, loading, called };
};

import isNil from "lodash/isNil";

import { SENDGRID_EMAIL_STATUS_CODE, TWILIO_SMS_STATUS_CODE } from "../constants/orderMessagesTracking";
import {
  Bundle,
  LensType,
  OrderField,
  OrderField_fullPaymentInfo,
  OrderState,
  PaymentMethod,
  RefundedItem,
  StoreOrderByNumber_storeOrderByNumber,
  StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo,
  StoreOrdersTable_storeOrders,
  TransactionType,
} from "../types";
import { currencyFormat } from "./helpers";
import { isHF } from "./refund";
import { DEMO_LENS_SKU } from "../constants";

const MATERIAL_TYPE = ["BNLMATERIAL", "BNLMATERIALTAXED"];

//re-arrange line item, make sure frame always first and lenses second in a bundle
export const sortLineItemByType = (lineItems: OrderState[]): OrderState[] => {
  return lineItems.sort((item) => {
    if (item.productType === "frame") {
      return -1;
    } else if (item.productType === "lenses") {
      return 0;
    }
    return 1;
  });
};

export const shouldMergeItems = (item: OrderState, newLineItem: OrderState): boolean =>
  item.productType === "lenses" &&
  item.quantity === newLineItem.quantity &&
  item.sku === newLineItem.sku &&
  item.originalStatusKey === newLineItem.originalStatusKey;

export const sumPrice = (item: OrderState, nextItem: OrderState): string => {
  const itemCurrency = isNaN(Number(item.displayPrice.charAt(0))) ? item.displayPrice.charAt(0) : "";
  const nextItemCurrency = isNaN(Number(nextItem.displayPrice.charAt(0))) ? nextItem.displayPrice.charAt(0) : "";
  const priceIndex = itemCurrency === "" ? 0 : 1;
  const nextPriceIndex = nextItemCurrency === "" ? 0 : 1;
  const prevPrice = parseFloat(item.displayPrice.slice(priceIndex, item.displayPrice.length));
  const nextPrice = parseFloat(nextItem.displayPrice.slice(nextPriceIndex, nextItem.displayPrice.length));
  return `${itemCurrency || nextItemCurrency}${(prevPrice + nextPrice).toFixed(2)}`;
};

export const mergeLens = (currentlineItems: OrderState[], newLineItem: OrderState): OrderState[] => {
  let merged = false;
  if (currentlineItems.length === 0) {
    return [newLineItem];
  } else {
    const newLineItems: OrderState[] = currentlineItems.map((item) => {
      if (shouldMergeItems(item, newLineItem)) {
        const newQuantity = newLineItem.quantity * 2;

        const prescriptions = [...(item.prescription ?? []), ...(newLineItem.prescription ?? [])];
        const ids = [...item.id, ...newLineItem.id.filter(Boolean)];
        merged = true;
        return {
          ...item,
          id: ids,
          prescription: prescriptions,
          quantity: newQuantity,
          displayPrice: sumPrice(item, newLineItem),
          states: [...(item.states ?? []), ...(newLineItem.states ?? [])],
        };
      }
      return item;
    });
    if (merged) {
      return newLineItems;
    } else {
      return [...newLineItems, newLineItem];
    }
  }
};

export const lineItemToBundle = (data: OrderField | undefined): Bundle[] => {
  const bundles: Bundle[] = []; // group line items into bundles
  let currentBaseMaterialIndex = -1;
  if (data?.lineItems) {
    const baseMaterials = data.lineItems.filter((item) => MATERIAL_TYPE.includes(item?.variant?.sku as string));
    return (
      data.lineItems
        .filter((item) => !MATERIAL_TYPE.includes(item?.variant?.sku as string))
        .map((lineItem) => {
          const originalStatus = lineItem?.state?.[0]?.state?.name?.en ?? "";
          const originalStatusKey = lineItem?.state?.[0]?.state?.key ?? "";
          const bundleNumber = lineItem.customFields?.bundleNumber ?? -1;
          const refundReason = lineItem?.returnInfos?.[0]?.comment ?? "";
          const requirePhysicalTrace = lineItem.customFields?.physicalTraceRequired ?? false;
          const customerGroupHealthFundCode = lineItem.variant?.customerGroupHealthFundCode;
          const healthFundCodes = [];

          if (lineItem.productType?.key === "lenses") {
            const lenses = data?.lineItems?.filter((item) => {
              //get all lenses in the bundle
              return item.customFields?.bundleNumber === bundleNumber && lineItem.variant?.sku === item.variant?.sku;
            });
            if (lenses.length > 1) {
              lineItem.variant?.multiHealthFundCode && healthFundCodes.push(lineItem.variant?.multiHealthFundCode);
              lineItem.variant?.multiHealthFundCodeAddOns && healthFundCodes.push(...lineItem.variant.multiHealthFundCodeAddOns);
            } else {
              lineItem.variant?.singleHealthFundCode && healthFundCodes.push(lineItem.variant?.singleHealthFundCode);
              lineItem.variant?.singleHealthFundCodeAddOns && healthFundCodes.push(...lineItem.variant.singleHealthFundCodeAddOns);
            }
          } else {
            if (lineItem.quantity > 1) {
              lineItem.variant?.multiHealthFundCode && healthFundCodes.push(lineItem.variant?.multiHealthFundCode);
              lineItem.variant?.multiHealthFundCodeAddOns && healthFundCodes.push(...lineItem.variant.multiHealthFundCodeAddOns);
            } else {
              lineItem.variant?.singleHealthFundCode && healthFundCodes.push(lineItem.variant?.singleHealthFundCode);
              lineItem.variant?.singleHealthFundCodeAddOns && healthFundCodes.push(...lineItem.variant.singleHealthFundCodeAddOns);
            }
          }

          let currentPrice = lineItem.totalPrice?.centAmount ?? 0;
          const isBase = baseMaterials && lineItem.variant?.sku?.includes("BNLBASE");
          if (isBase) {
            currentPrice +=
              baseMaterials.find((baseMaterial) => baseMaterial?.customFields?.eye === lineItem?.customFields?.eye)?.totalPrice?.centAmount ?? 0;
            currentBaseMaterialIndex++;
          }
          const item: OrderState = {
            currentStatus: lineItem.state?.[0]?.state?.name?.en as string,
            currentStatusKey: lineItem.state?.[0]?.state?.key as string,
            displayPrice: currencyFormat(currentPrice / 100, lineItem.totalPrice?.currencyCode) as string,
            id:
              isBase && baseMaterials[currentBaseMaterialIndex]?.id
                ? [lineItem.id as string, baseMaterials[currentBaseMaterialIndex].id]
                : [lineItem.id as string],
            orderId: data?.id ?? "",
            sku: lineItem.variant?.sku ?? "",
            supplyChannelKey: lineItem.supplyChannel?.key,
            originalStatus,
            originalStatusKey,
            quantity: lineItem.quantity,
            statusId: lineItem.state?.[0]?.state?.id as string,
            variantName: lineItem.variant?.name ?? "UNKNOWN VARIANT",
            prescription: [lineItem?.customFields],
            productType: lineItem.productType?.key ?? "",
            states: lineItem.state ?? [],
            refundReason,
            physicalTraceRequired: requirePhysicalTrace,
            healthFundCodes: customerGroupHealthFundCode
              ? [customerGroupHealthFundCode]
              : (healthFundCodes.filter((code) => !isNil(code)) as string[]),
            lensType: getLensTypeString(lineItem.customFields?.lensType),
            isSunOpt: lineItem?.customFields?.sunOpt ?? false,
            frameSku: lineItem?.customFields?.frameSku,
            collectedDate: lineItem?.customFields?.collectedDate,
            isTrace: lineItem?.customFields?.isTrace ?? false,
          };

          return { bundleNumber, lineItems: [item] };
        })
        ?.reduce((prev, next) => {
          if (prev.length === 0) {
            return [next];
          } else {
            const bundleNumber = next.bundleNumber;
            const sameBundleIndex = prev.findIndex((bundle) => bundle.bundleNumber === bundleNumber);
            if (sameBundleIndex !== -1) {
              if (next.lineItems.length > 0) {
                const merged = mergeLens(prev[sameBundleIndex].lineItems, next.lineItems[0]);
                prev[sameBundleIndex] = { bundleNumber, lineItems: merged };
              }
              return prev;
            } else {
              return prev.concat(next);
            }
          }
        }, bundles)
        .map((item) => {
          return { ...item, lineItems: sortLineItemByType(item.lineItems) };
        }) ?? []
    );
  }

  return bundles;
};

export const getAvailableRefundAmount = (paymentInfo: OrderField_fullPaymentInfo | null): number => {
  return (
    paymentInfo?.transactions?.reduce((prev, next) => {
      if (next?.type === TransactionType.Charge) {
        return prev + (next?.amount.centAmount ?? 0);
      }
      return prev - (next?.amount?.centAmount ?? 0);
    }, 0) ?? 0
  );
};

export const paymentMethodToString = (paymentMethod: string | null | undefined): string => {
  if (paymentMethod)
    switch (paymentMethod) {
      case PaymentMethod.Cash:
      case PaymentMethod.Afterpay:
      case PaymentMethod.EFTPOS:
      case PaymentMethod.Laybuy:
      case PaymentMethod.Mastercard:
      case PaymentMethod.RoyalPay:
      case PaymentMethod.ZipPay:
        return paymentMethod;
      case PaymentMethod.Visa:
        return "Visa";
      case PaymentMethod.Amex:
        return "American express";
      case PaymentMethod.HealthFund:
        return "Health Fund";
      case PaymentMethod.BNGiftVoucher:
        return "Bailey Nelson gift voucher";
      case PaymentMethod.ACTConcessionScheme:
        return "ACT Concession Scheme";
      case PaymentMethod.DefenceHealth:
        return "Defence Health";
      case PaymentMethod.NTConcessionScheme:
        return "NT Concession Scheme";
      case "Adyen-Credit card": //online only, but we need to handle it in POS
        return "Adyen credit card";
      case PaymentMethod.ProvincialBilling:
        return "Provincial Billing";
      default:
        return paymentMethod;
    }

  return "";
};

//CT doesn't update the order status immediately, however line item state does.
export const isOrderHasCorrectStatus = (status: string, order: OrderField | null | undefined): boolean => {
  if (order) {
    return order?.lineItems?.every((item) => {
      if (item?.state) {
        const lastIndex = item.state.length - 1;
        return item.state[lastIndex]?.state?.name?.en === status;
      }
      return false;
    });
  }
  return false;
};

export const orderIncludesCorrectStatus = (status: string, order: OrderField | null | undefined): boolean => {
  if (order) {
    return order?.lineItems?.some((item) => {
      if (item?.state) {
        const lastIndex = item.state.length - 1;
        return item.state[lastIndex]?.state?.name?.en === status;
      }
      return false;
    });
  }
  return false;
};

export const includeHealthFund = (paymentInfo: (StoreOrderByNumber_storeOrderByNumber_fullPaymentInfo | null)[]): boolean => {
  return paymentInfo?.filter((info) => isHF(info?.paymentMethod)).length > 0;
};

export const validateRefundInput = (amountString: string): boolean => {
  if (amountString.length > 0) {
    const amount = Number(amountString);
    if (!Number.isNaN(amount) && amount > 0) {
      return true;
    }
  }
  return false;
};

export const includeHealthFundCode = (refundItems: RefundedItem[]): boolean => {
  return refundItems?.some((item) => {
    return item.hasHealthFundCode;
  });
};

//convert LensType to string
export const getLensTypeString = (lensType: LensType | null | undefined): string => {
  switch (lensType) {
    case LensType.B:
      return "Bifocal";
    case LensType.D:
      return "Single Vision Distance";
    case LensType.I:
      return "Single Vision Intermediate";
    case LensType.N:
      return "Single Vision Near";
    case LensType.M:
      return "Multifocal";
    case LensType.P:
      return "Progressive Near";
    default:
      return "";
  }
};

export const getBundleJobType = (lineItems: OrderState[]): string => {
  const init = "Job Type:";

  //remove duplicates
  const uniqueJobType = new Set(lineItems.map((item) => item?.lensType));

  const result = Array.from(uniqueJobType.values()).reduce((prev, next) => {
    if (next) {
      return `${prev} ${next}`;
    }
    return prev;
  }, init);

  if (result && result !== init) {
    return result;
  }
  return ""; //if no lens type, return empty string,
};

export const getTotalPrice = (prices: number[]): number => {
  let total = 0;
  prices.forEach((price) => {
    total += price;
  });

  return total;
};

export const getTotalCost = (prices: number[]): string => {
  return currencyFormat(getTotalPrice(prices) / 100, null);
};

export const isOrderReceivedOrCompleted = (order: StoreOrderByNumber_storeOrderByNumber | StoreOrdersTable_storeOrders): boolean => {
  const bundles = lineItemToBundle(order as OrderField);
  const bundleStatus = bundles.map((bundle) => {
    //assume every items in bundle will have the same status
    return bundle.lineItems.length > 0 ? bundle.lineItems[0].states[0]?.state?.name?.en ?? "" : "";
  });
  const completedStatus = ["returned", "refunded", "collected"];
  const receivedAtStoreCount = bundleStatus.filter((status) => status.toLowerCase()?.trim() === "received at store").length;
  const completedCount = bundleStatus.filter((status) => completedStatus.includes(status.toLowerCase().trim())).length;

  return (
    order.status?.toLowerCase()?.trim() === "received at store" ||
    (receivedAtStoreCount > 0 && completedCount === bundleStatus.length - receivedAtStoreCount) //at least one bundle is receieved at store, and other bundle has status of returned, refunded or collected
  );
};

export const isOrderNotifiedButFailed = (order: StoreOrderByNumber_storeOrderByNumber | StoreOrdersTable_storeOrders): boolean => {
  let notifiedButFailed = false;
  if (order.customFields?.customerNotified) {
    if (
      order.customFields?.contactMethod?.toLowerCase() === "sms" &&
      [TWILIO_SMS_STATUS_CODE.undelivered, TWILIO_SMS_STATUS_CODE.failed, TWILIO_SMS_STATUS_CODE.canceled].includes(
        order.customFields?.smsStatus as string,
      )
    ) {
      notifiedButFailed = true;
    } else if (
      order.customFields?.contactMethod?.toLowerCase() === "email" &&
      [SENDGRID_EMAIL_STATUS_CODE.dropped, SENDGRID_EMAIL_STATUS_CODE.bounce].includes(order.customFields?.emailStatus as string)
    ) {
      notifiedButFailed = true;
    } else {
      notifiedButFailed = false;
    }
  }
  return notifiedButFailed;
};

export const isOrderNotifying = (order: StoreOrderByNumber_storeOrderByNumber | StoreOrdersTable_storeOrders): boolean => {
  let isNotifiedAndPending = false;
  if (order.customFields?.customerNotified) {
    if (
      order.customFields?.contactMethod?.toLowerCase() === "sms" &&
      [
        TWILIO_SMS_STATUS_CODE.accepted,
        TWILIO_SMS_STATUS_CODE.scheduled,
        TWILIO_SMS_STATUS_CODE.queued,
        TWILIO_SMS_STATUS_CODE.sending,
        TWILIO_SMS_STATUS_CODE.sent,
        TWILIO_SMS_STATUS_CODE.receiving,
        TWILIO_SMS_STATUS_CODE.received,
        "",
      ].includes(order.customFields?.smsStatus || "")
    ) {
      isNotifiedAndPending = true;
    } else if (
      order.customFields?.contactMethod?.toLowerCase() === "email" &&
      [SENDGRID_EMAIL_STATUS_CODE.processed, SENDGRID_EMAIL_STATUS_CODE.deferred, ""].includes(order.customFields?.emailStatus || "")
    ) {
      isNotifiedAndPending = true;
    } else {
      isNotifiedAndPending = false;
    }
  }
  return isNotifiedAndPending;
};

export const enableSendNotification = (order: StoreOrderByNumber_storeOrderByNumber | StoreOrdersTable_storeOrders): boolean => {
  if (order.customFields?.customerNotified) {
    const failedNotification = isOrderNotifiedButFailed(order);
    if (!failedNotification) {
      return false;
    }
  }

  return isOrderReceivedOrCompleted(order);
};

export const isLensOnlyBundle = (bundle: Bundle): boolean => {
  const foundFrame = bundle.lineItems?.find((item) => item.productType?.toLocaleLowerCase() === "frame");
  const foundLenses = bundle.lineItems?.find((item) => item.productType?.toLocaleLowerCase() === "lenses");
  return !foundFrame && !!foundLenses;
};

//temporary solution, will add new field in CT to identify the order type
export const isDemoLensBundle = (bundle: Bundle): boolean => {
  return bundle.lineItems.length > 0 ? bundle.lineItems.every((item) => item.sku === DEMO_LENS_SKU.AU) : false;
};

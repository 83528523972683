import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosCreateCalendarNoteMutation, PosCreateCalendarNoteMutationVariables } from "../../types";
import { useCallback } from "react";

export const CREATE_CALENDAR_NOTE = gql`
  mutation PosCreateCalendarNoteMutation($distributionChannelKey: String!, $date: String!, $note: String!) {
    createCalendarNote(distributionChannelKey: $distributionChannelKey, date: $date, note: $note) {
      id
      date
      note
      updatedAt
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  createCalendarNote: (distributionChannelKey: string, date: string, note: string) => Promise<FetchResult<PosCreateCalendarNoteMutation>>;
}

export const useCreateCalendarNoteMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosCreateCalendarNoteMutation, PosCreateCalendarNoteMutationVariables>(
    CREATE_CALENDAR_NOTE,
    {
      onError: () => void 0,
    },
  );

  const createCalendarNote = useCallback(
    (distributionChannelKey: string, date: string, note: string) => {
      const variables: PosCreateCalendarNoteMutationVariables = {
        distributionChannelKey,
        date,
        note,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { createCalendarNote, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { AppointmentTypeUpdateRequest, PosUpdateAppointmentTypeMutation, PosUpdateAppointmentTypeMutationVariables } from "../../types";
import { useCallback } from "react";

export const UPDATE_APPOINTMENT_TYPE = gql`
  mutation PosUpdateAppointmentTypeMutation($posUpdateAppointmentTypeRquest: AppointmentTypeUpdateRequest) {
    posUpdateAppointmentType(appointmentTypeUpdateRequest: $posUpdateAppointmentTypeRquest) {
      id
      color
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updatePosAppointmentType: (posUpdateAppointmentTypeRquest: AppointmentTypeUpdateRequest) => Promise<FetchResult<PosUpdateAppointmentTypeMutation>>;
}

export const useUpdateAppointmentTypeMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosUpdateAppointmentTypeMutation, PosUpdateAppointmentTypeMutationVariables>(
    UPDATE_APPOINTMENT_TYPE,
    {
      onError: () => void 0,
    },
  );

  const updatePosAppointmentType = useCallback(
    (appointmentTypeRequest: AppointmentTypeUpdateRequest) => {
      const variables: PosUpdateAppointmentTypeMutationVariables = {
        posUpdateAppointmentTypeRquest: appointmentTypeRequest,
      };

      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updatePosAppointmentType, data, error, loading, called };
};

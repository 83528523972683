// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Sentry from "@sentry/react";
import { CountryCode, parsePhoneNumber } from "libphonenumber-js";
import isNil from "lodash/isNil";

import { CurrentPrice } from "../types";

/**
 * @description
 * Replaces null and undefined values with empty strings.
 * Required for certain middleware functions.
 */
export const nullishStringify = <t>(item: t): t => {
  return Object.entries(item).reduce((acc: t, [k, v]) => {
    acc[k as keyof t] = v ?? "";
    return acc;
  }, {} as t);
};

/**
 * @description
 * Replaces object properties with null and undefined values.
 */
export const removeNullishValues = <t>(item: t): t =>
  Object.entries(item).reduce((acc: t, [k, v]) => {
    if (!isNil(v)) {
      acc[k as keyof t] = v;
    }
    return acc;
  }, {} as t);

/**
 * @description
 * Replaces object properties with null, undefined and empty values.
 */
export const removeEmptyValues = <t>(item: t): t =>
  Object.entries(item).reduce((acc: t, [k, v]) => {
    if (!isNil(v) && v !== "") {
      acc[k as keyof t] = v;
    }
    return acc;
  }, {} as t);
export const toE164 = (number: string | null | undefined, countryCode: string): string => {
  let validPhoneNumber = "";
  if (number) {
    //only parse to E164 if mobile number isn't empty
    try {
      validPhoneNumber = parsePhoneNumber(number, countryCode as CountryCode).format(`E.164`);
    } catch (e) {
      //do nothing
    }
  }
  return validPhoneNumber;
};

// returns iso 3166 alpha 2 format eg `CA
// https://en.wikipedia.org/wiki/ISO_3166-2
export const getCountryCode = (): string => {
  const rawCountryCode = process.env.REACT_APP_STORE_COUNTRY_CODE ?? "";
  const hyphenIndex = rawCountryCode.indexOf("-");

  if (rawCountryCode.length < 1 || hyphenIndex === -1) {
    Sentry.captureMessage("getCountryCode util: Invalid REACT_APP_STORE_COUNTRY_CODE env var");
    return "";
  }
  return rawCountryCode.slice(hyphenIndex + 1, rawCountryCode.length);
};

const currencyCodeList = {
  "en-ca": "CAD",
  "en-au": "AUD",
  "en-gb": "GBP",
  "en-nz": "NZD",
};

/**
 * @description
 * Returns the currency code for the current locale.
 * @example
 * currencyFormat(100, "AUD");
 * // returns "$100.00"
 *  currencyFormat(100, "GBP");
 * // returns "£100.00"
 */
export const currencyFormat = (amount: string | number | null | undefined, currencyCode: string | null | undefined): string => {
  if (isNil(amount)) {
    return "";
  }
  const locale = process.env.REACT_APP_STORE_LOCALE || "en-AU";
  if (!process.env.REACT_APP_STORE_LOCALE) {
    Sentry.captureMessage("REACT_APP_STORE_LOCALE environment variable not set, defaulting to en-AU"); // indicate locale is not set
  }

  const code = currencyCode ? currencyCode : currencyCodeList[locale.toLowerCase() as keyof typeof currencyCodeList]; //use default currency code if not provided
  return new Intl.NumberFormat(locale, { style: "currency", currency: code }).format(Number.parseFloat(`${amount}`)); //format to 2 decimal places with correct dollar sign
};

export const validateEmailAddress = (email: string | undefined): boolean => {
  if (email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  return false;
};

export const getProductDiscountedPrice = (currentPrice?: CurrentPrice): number | null => {
  if (currentPrice?.discounted?.value?.centAmount) {
    return currentPrice.discounted.value.centAmount / 100;
  }
  return currentPrice?.cashPrice?.amount ?? null;
};

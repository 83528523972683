import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { MarketingCompOpt, MarketingCompOptVariables } from "../../types";

export const MARKETING_COM_OPT = gql`
  query MarketingCompOpt($posSelectPatientId: String!, $store: String!, $countryCode: String!) {
    posSelectPatient(id: $posSelectPatientId, store: $store, countryCode: $countryCode) {
      marketingComOpt {
        email_optin
        sms_optin
        whatsapp_optin
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<MarketingCompOpt>, "data" | "loading" | "error" | "called"> {
  queryMarketingComOpt: LazyQueryExecFunction<MarketingCompOpt, MarketingCompOptVariables>;
}

export const useMarketingComOptLazyQuery = (): ExtendedQueryResult => {
  const [queryMarketingComOpt, { data, error, loading, called }] = useLazyQuery<MarketingCompOpt, MarketingCompOptVariables>(MARKETING_COM_OPT, {
    // these are important, do not remove
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
  });

  return {
    queryMarketingComOpt,
    data,
    error,
    loading,
    called,
  };
};

import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { PosRetrieveReceivedBulkReplenishments, PosRetrieveReceivedBulkReplenishmentsVariables } from "../../types";

export const RECEIVED_BULK_REPLENISHMENTS = gql`
  query PosRetrieveReceivedBulkReplenishments($endDate: String!, $startDate: String!, $distributionChannelKey: String) {
    posRetrieveReceivedBulkReplenishments(endDate: $endDate, startDate: $startDate, distributionChannelKey: $distributionChannelKey) {
      date
      batchNumber
      essilorStoreCode
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<PosRetrieveReceivedBulkReplenishments>, "data" | "loading" | "error" | "called"> {
  getBulkReplenishments: LazyQueryExecFunction<PosRetrieveReceivedBulkReplenishments, PosRetrieveReceivedBulkReplenishmentsVariables>;
}

export const useReceivedBulkReplenishmentsQuery = (handleOnComplete?: (data: PosRetrieveReceivedBulkReplenishments) => void): ExtendedQueryResult => {
  const [getBulkReplenishments, { data, error, loading, called }] = useLazyQuery<
    PosRetrieveReceivedBulkReplenishments,
    PosRetrieveReceivedBulkReplenishmentsVariables
  >(RECEIVED_BULK_REPLENISHMENTS, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  return {
    getBulkReplenishments,
    data,
    error,
    loading,
    called,
  };
};

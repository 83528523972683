import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { ContactMethodType, SetContactMethod, SetContactMethodVariables } from "../../types";
import { useCallback } from "react";

export const SET_CART_CONTACT_METHOD = gql`
  mutation SetContactMethod($contactMethod: ContactMethodType!) {
    setContactMethod(contactMethod: $contactMethod) {
      customFields {
        contactMethod
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setContactMethod: (contactMethod: ContactMethodType) => Promise<FetchResult<SetContactMethod>>;
}

export const useSetCartContactMethodMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SetContactMethod, SetContactMethodVariables>(SET_CART_CONTACT_METHOD, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  const setContactMethod = useCallback(
    (contactMethod: ContactMethodType) => {
      const variables: SetContactMethodVariables = {
        contactMethod,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { setContactMethod, data, error, loading, called };
};

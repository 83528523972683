import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { ResendInvoiceMutation, ResendInvoiceMutationVariables, ResendInvoiceRequest } from "../../types";
import { useCallback } from "react";

export const RESEND_INVOICE = gql`
  mutation ResendInvoiceMutation($resendInvoiceRequest: ResendInvoiceRequest!) {
    resendInvoice(resendInvoiceRequest: $resendInvoiceRequest) {
      isSuccess
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  resendInvoiceMutation: (resendInvoiceRequest: ResendInvoiceRequest) => Promise<FetchResult<ResendInvoiceMutation>>;
}

export const useResendInvoice = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<ResendInvoiceMutation, ResendInvoiceMutationVariables>(RESEND_INVOICE, {
    onError: () => void 0,
  });

  const resendInvoiceMutation = useCallback(
    (resendInvoiceRequest: ResendInvoiceRequest) => {
      return mutate({
        variables: { resendInvoiceRequest },
      });
    },
    [mutate],
  );

  return { resendInvoiceMutation, data, error, loading, called };
};

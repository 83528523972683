import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { SetInvoiceOnlyOrder, SetInvoiceOnlyOrderVariables } from "../../types";
import { CART_FIELDS } from "../fragments";

export const SET_INVOICE_ONLY_ORDER = gql`
  ${CART_FIELDS}
  mutation SetInvoiceOnlyOrder($invoiceOnlyOrder: Boolean!) {
    setinvoiceOnlyOrder(invoiceOnlyOrder: $invoiceOnlyOrder) {
      ...CartFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setInvoiceOnlyOrder: (isInvoiceOnly: boolean) => Promise<FetchResult<SetInvoiceOnlyOrder>>;
}

export const useSetInvoiceOnlyOrder = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SetInvoiceOnlyOrder, SetInvoiceOnlyOrderVariables>(SET_INVOICE_ONLY_ORDER, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  const setInvoiceOnlyOrder = useCallback(
    (isInvoiceOnly: boolean) => {
      const variables: SetInvoiceOnlyOrderVariables = {
        invoiceOnlyOrder: isInvoiceOnly,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { setInvoiceOnlyOrder, data, error, loading, called };
};

import { FetchResult, MutationResult, gql, useMutation } from "@apollo/client";
import { ATTACHMENT_FIELDS } from "../fragments";
import { UpdateAttachment, UpdateAttachmentInput, UpdateAttachmentVariables } from "../../types";
import { useCallback } from "react";

export const ATTACHMENT_UPDATE = gql`
  ${ATTACHMENT_FIELDS}
  mutation UpdateAttachment($input: UpdateAttachmentInput!) {
    updateAttachment(input: $input) {
      ...AttachmentFields
    }
  }
`;

type ExtendedMutation = Omit<MutationResult, "client" | "reset"> & {
  updateAttachment: (input: UpdateAttachmentInput) => Promise<FetchResult<UpdateAttachment>>;
};

export const useAttachmentUpdate = (): ExtendedMutation => {
  const [mutate, { data, loading, error, called }] = useMutation<UpdateAttachment, UpdateAttachmentVariables>(ATTACHMENT_UPDATE);

  const updateAttachment = useCallback(
    (input: UpdateAttachmentInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate],
  );

  return {
    updateAttachment,
    data,
    loading,
    error,
    called,
  };
};

import * as Sentry from "@sentry/react";
import jwt_decode from "jwt-decode";
import React, { FC, useCallback, useEffect } from "react";

import { selectStoreAuthenticated, setCognitoAuthEmail, setCognitoAuthName, storeLogin, storeLogout } from "../../state/authentication";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { AUTH_KEYS, StoreTokenInfo } from "../../types";
import { SentryTag } from "../Sentry/SentryConsts";
import { useNavigate } from "react-router-dom";

type Props = {
  children?: React.ReactNode;
};

const GoogleAuth: FC<Props> = ({ children }) => {
  // allows dispatched actions to trigger useEffect cognito login
  const stateStoreAuthenticated = useAppSelector(selectStoreAuthenticated);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { REACT_APP_STORE_DOMAIN, REACT_APP_STORE_CLIENT_ID } = process.env;
  const cognitoAuthUrl = `https://${REACT_APP_STORE_DOMAIN}/login?client_id=${REACT_APP_STORE_CLIENT_ID}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${window.location.protocol}//${window.location.host}/login`;

  const handleLogin = useCallback((): void => {
    if (!stateStoreAuthenticated) {
      dispatch(storeLogin());
    }
  }, [dispatch, stateStoreAuthenticated]);

  const handlestoreLogout = useCallback((): void => {
    if (stateStoreAuthenticated) {
      dispatch(storeLogout());
    }
  }, [dispatch, stateStoreAuthenticated]);

  useEffect(() => {
    const storeAuthToken = sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_TOKEN);
    const storeAuthExpires = Number.parseInt(sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_EXPIRES) ?? "0");

    const authenticatedFromStorage = storeAuthToken !== null && storeAuthExpires > Date.now();

    // Is there a non-expired token saved in session storage, render children
    if (authenticatedFromStorage) {
      try {
        const decoded: StoreTokenInfo = jwt_decode(storeAuthToken);
        Sentry.setUser({ email: decoded.email ?? "no email" });
        dispatch(setCognitoAuthEmail(decoded.email));
        Sentry.setTag(SentryTag.User, decoded.email);
      } catch {
        Sentry.captureMessage("Error decoding store token from browser storage");
      }
      handleLogin();
    } else {
      // If there is a # in the URL, indicating a possible cognito response url, then test url params for a valid token
      const url = new URL(window.location.toString());
      const searchParams = new URLSearchParams(url.hash.substr(1));

      const idToken = searchParams.get("id_token");
      const expiresIn = Number.parseInt(searchParams.get("expires_in") ?? "0") * 1000;

      if (idToken !== null && expiresIn > 0) {
        try {
          const decoded: StoreTokenInfo = jwt_decode(idToken);
          Sentry.setUser({ email: decoded.email ?? "no email" });
          Sentry.setTag(SentryTag.User, decoded.email);
          dispatch(setCognitoAuthEmail(decoded.email));
          dispatch(setCognitoAuthName(decoded.name));
        } catch {
          Sentry.captureMessage("Error decoding cognito auth id token from redirect");
        }

        sessionStorage.setItem(AUTH_KEYS.STORE_AUTH_TOKEN, idToken);
        sessionStorage.setItem(AUTH_KEYS.STORE_AUTH_EXPIRES, `${Date.now() + expiresIn}`);
        handleLogin();
        // clear up the url
        // window.location.assign(`${window.location.protocol}//${window.location.host}/`);
        // location.assign or reload can cause err:NETWORK_ABORT on pending requests, and those aborted requests will be recorded by sentry, so we use navigate to avoid this issue
        navigate("/");
      } else {
        // no valid token in storage or url search params, redirect to cognito
        handlestoreLogout();
        window.location.assign(cognitoAuthUrl);
      }
    }
  }, [cognitoAuthUrl, dispatch, handleLogin, handlestoreLogout, stateStoreAuthenticated, navigate]);

  // If the user is authenticated then display the child compnent we wrap
  if (stateStoreAuthenticated) {
    return <>{children}</>;
  }

  return null;
};

export default GoogleAuth;

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PhysicalTraceComplete, PhysicalTraceCompleteVariables } from "../../types";
import { useCallback } from "react";

export const PHYSICAL_TRACE_COMPLETE = gql`
  mutation PhysicalTraceComplete($orderNumber: String!) {
    physicalTraceCompleted(orderNumber: $orderNumber) {
      customerId
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setPhysicalTraceComplete: (orderNumber: string) => Promise<FetchResult<PhysicalTraceComplete>>;
}

export const usePhysicalTraceCompleteMutation = (handleOnComplete?: () => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PhysicalTraceComplete, PhysicalTraceCompleteVariables>(PHYSICAL_TRACE_COMPLETE, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const setPhysicalTraceComplete = useCallback(
    (orderNumber: string) => {
      const variables: PhysicalTraceCompleteVariables = {
        orderNumber,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { setPhysicalTraceComplete, data, error, loading, called };
};

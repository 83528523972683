import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { MedicareValidation, MedicareValidationVariables } from "../../types";

export const MEDICARE_VALIDATION = gql`
  query MedicareValidation($medicareValidationInput: MedicareValidationInput!) {
    validateMedicare(medicareValidationInput: $medicareValidationInput) {
      patientVerified
      isUpdateRequired
      concessionVerified
      patient {
        code
        message
      }
      concession {
        code
        message
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<MedicareValidation>, "data" | "loading" | "error" | "variables" | "called"> {
  validateMedicare: LazyQueryExecFunction<MedicareValidation, MedicareValidationVariables>;
}

export const useMedicareValidationQuery = (): ExtendedQueryResult => {
  const [validateMedicare, { data, error, loading, variables, called }] = useLazyQuery<MedicareValidation, MedicareValidationVariables>(
    MEDICARE_VALIDATION,
    {
      onError: () => void 0,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    validateMedicare,
    data,
    error,
    loading,
    variables,
    called,
  };
};

export const PATHS = {
  HOME: "/",
  APPOINTMENTS: "/appointments",
  AVAILABILITY: "/availability",
  CART: "/cart",
  CONTACT_LENS: "/contact-lens",
  CONTACT_LENS_ORDER: "/contact-lens/order",
  CONTACT_LENS_ORDER_COMPLETE: "/contact-lens/order/complete",
  DASHBOARD: "/dashboard",
  DISPENSING: "/create-an-order",
  DISPENSING_SHOPPING_BAG: "/create-an-order/shopping-bag",
  DISPENSING_PAYMENT: "/create-an-order/payment",
  DISPENSING_CHECKOUT: "/create-an-order/check-out",
  DISPENSING_ORDER_SUCCESS: "/create-an-order/success",
  EXAM: "/exam",
  PATIENT: "/patient",
  PATIENT_LEGACY: "/patient-legacy",
  PATIENT_NEW: "/patient-new",
  NEW_PATIENT: "/create-patient",
  ORDERS: "/orders",
  PENDING_CART: "/pending",
  REFUND: "/refund",
  PATIENT_ORDER: "/order",
  INVENTORY: "/inventory",
  REPORT: "/report",
  CL_SIGNUP_REPORT: "/report/contact-lens-sign-up",
  EOD_REPORT: "/report/end-of-day",
  SALES_REPORT: "/report/daily-weekly-sales",
  TODAY_ORDERS_DETAILS_REPORT: "/report/today-orders-details",
  OPTOM_SETTING: "/setting/optom",
  APPOINTMENT_TYPE_SETTING: "/setting/appointment-type",
  REPLENISHMENT_BATCH_RECEIPT_JOURNAL: "/setting/replenishment-batch-receipt-journal",
  CUSTOMER_ORDER_BATCH_RECEIPT_JOURNAL: "/setting/customer-order-batch-receipt-journal",
  SETTING: "/setting",
  HF_REFUND_SETTING: "/setting/hf-refunds",
  COMPLETE_HF_REFUND_SETTING: "/setting/hf-refunds-completed",
  SETTING_REPORT: "/setting/reporting",
  CONFIGURATION: "/setting/configuration",
  ACCOUNTS_RECEIVABLE: "/accountsreceivable",
};

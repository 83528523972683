import { FetchResult, MutationResult, gql, useMutation } from "@apollo/client";
import { DeleteAttachment, DeleteAttachmentInput, DeleteAttachmentVariables } from "../../types";
import { useCallback } from "react";

export const ATTACHMENT_DELETE = gql`
  mutation DeleteAttachment($input: DeleteAttachmentInput!) {
    deleteAttachment(input: $input)
  }
`;

type ExtendedMutation = Omit<MutationResult, "client" | "reset"> & {
  deleteAttachment: (input: DeleteAttachmentInput) => Promise<FetchResult<DeleteAttachment>>;
};

export const useAttachmentDelete = (): ExtendedMutation => {
  const [mutate, { data, loading, error, called }] = useMutation<DeleteAttachment, DeleteAttachmentVariables>(ATTACHMENT_DELETE);

  const deleteAttachment = useCallback(
    (input: DeleteAttachmentInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate],
  );

  return {
    deleteAttachment,
    data,
    loading,
    error,
    called,
  };
};

import { gql, LazyQueryExecFunction, OperationVariables, QueryResult, useLazyQuery } from "@apollo/client";

import { CartDiscountCodes } from "../../types";

export const CART_DISCOUNT_CODES = gql`
  query CartDiscountCodes {
    cart {
      discountCodes {
        discountCode {
          code
          id
        }
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<CartDiscountCodes>, "data" | "loading" | "error" | "refetch" | "called"> {
  getAppliedDiscountCodes: LazyQueryExecFunction<CartDiscountCodes, OperationVariables>;
}

export const useCartDiscountCodesQuery = (): ExtendedQueryResult => {
  const [getAppliedDiscountCodes, { data, error, loading, called, refetch }] = useLazyQuery<CartDiscountCodes>(CART_DISCOUNT_CODES, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return {
    getAppliedDiscountCodes,
    data,
    error,
    loading,
    called,
    refetch,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { HealthFundRefundUpdate, SubmitHealthFundRefundUpdate, SubmitHealthFundRefundUpdateVariables } from "../../types";
import { useCallback } from "react";

export const UPDATE_HEALTH_FUND_REFUND = gql`
  mutation SubmitHealthFundRefundUpdate($hfRefundUpdateRequest: HealthFundRefundUpdate!) {
    submitHealthFundRefundUpdate(hfRefundUpdateRequest: $hfRefundUpdateRequest) {
      id
      staffName
      refundType
      customerName
      healthFund
      dateOfService
      healthFundNumber
      healthFundReference
      distributionChannelKey
      providersName
      providerNumber
      totalPrice
      healthFundPayment
      reason
      status
      createdAt
      orderNumber
      hfRefundAmount
      comment
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updateHFRefund: (hfRefundUpdateRequest: HealthFundRefundUpdate) => Promise<FetchResult<SubmitHealthFundRefundUpdate>>;
}

export const useUpdateHealthFundRefundMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SubmitHealthFundRefundUpdate, SubmitHealthFundRefundUpdateVariables>(
    UPDATE_HEALTH_FUND_REFUND,
    {
      onError: () => void 0,
    },
  );

  const updateHFRefund = useCallback(
    (hfRefundUpdateRequest: HealthFundRefundUpdate) => {
      const variables: SubmitHealthFundRefundUpdateVariables = {
        hfRefundUpdateRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateHFRefund, data, error, loading, called };
};

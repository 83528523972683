import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosDeleteCalendarNoteMutation, PosDeleteCalendarNoteMutationVariables } from "../../types";
import { useCallback } from "react";

export const DELETE_CALENDAR_NOTE = gql`
  mutation PosDeleteCalendarNoteMutation($id: ID!) {
    deleteCalendarNote(id: $id) {
      id
      date
      note
      updatedAt
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  deleteCalendarNote: (id: string) => Promise<FetchResult<PosDeleteCalendarNoteMutation>>;
}

export const useDeleteCalendarNoteMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosDeleteCalendarNoteMutation, PosDeleteCalendarNoteMutationVariables>(
    DELETE_CALENDAR_NOTE,
    {
      onError: () => void 0,
    },
  );

  const deleteCalendarNote = useCallback(
    (id: string) => {
      const variables: PosDeleteCalendarNoteMutationVariables = {
        id,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { deleteCalendarNote, data, error, loading, called };
};

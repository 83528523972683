import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { RetrieveCalendarNote, RetrieveCalendarNoteVariables } from "../../types";

export const CALENDAR_NOTES = gql`
  query RetrieveCalendarNote($date: String!, $distributionChannelKey: String!) {
    retrieveCalendarNote(date: $date, distributionChannelKey: $distributionChannelKey) {
      id
      date
      note
      updatedAt
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<RetrieveCalendarNote>, "data" | "loading" | "error" | "variables" | "called"> {
  getCalendarNotes: LazyQueryExecFunction<RetrieveCalendarNote, RetrieveCalendarNoteVariables>;
}

export const useRetrieveCalendarNoteLazyQuery = (distributionChannelKey: string, date: string): ExtendedQueryResult => {
  const [getCalendarNotes, { data, error, loading, variables, called }] = useLazyQuery<RetrieveCalendarNote, RetrieveCalendarNoteVariables>(
    CALENDAR_NOTES,
    {
      variables: { date, distributionChannelKey },
      onError: () => void 0,
      fetchPolicy: "network-only",
    },
  );

  return {
    getCalendarNotes,
    data,
    error,
    loading,
    variables,
    called,
  };
};

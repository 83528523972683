import { useLocalStorage } from "@uidotdev/usehooks";
import { useCallback, useEffect, useState } from "react";

import { isAfter } from "../../utils";

export type StaffToken = {
  accessToken: string;
  expiresAt: string;
  firstName: string;
  lastName: string;
};

export type UseStaffTokenHook = {
  staffToken: StaffToken | null;
  setToken: (token: StaffToken | null) => void;
};

const useStaffToken = (): UseStaffTokenHook => {
  const [accessToken, setAccessToken] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [storageToken, setStorageToken] = useLocalStorage<StaffToken | null>("staff-token", null);

  useEffect(() => {
    const token = storageToken || null;
    const expiresAt = token?.expiresAt;

    if (expiresAt) {
      const dateExpire = new Date(expiresAt);
      if (isAfter(new Date(), dateExpire)) {
        // expired token
        setStorageToken(null);
        setAccessToken("");
        setExpiresAt("");
        setFirstName("");
        setLastName("");
      } else {
        setAccessToken(token?.accessToken || "");
        setExpiresAt(token?.expiresAt || "");
        setFirstName(token?.firstName || "");
        setLastName(token?.lastName || "");
      }
    }
  }, [storageToken, setStorageToken]);

  const setToken = useCallback(
    (token: StaffToken | null) => {
      if (token) {
        setStorageToken(token);
      } else {
        setStorageToken(null);
      }
      setAccessToken(token?.accessToken || "");
      setExpiresAt(token?.expiresAt || "");
      setFirstName(token?.firstName || "");
      setLastName(token?.lastName || "");
    },
    [setStorageToken],
  );

  return {
    staffToken: {
      accessToken,
      expiresAt,
      firstName,
      lastName,
    },
    setToken,
  };
};

export default useStaffToken;

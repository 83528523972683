import {
  addressDraft,
  addToCartDraft,
  AttachmentFields,
  EyeType,
  HPrismType,
  LensBuilderQuery_productLensBuilder_products_variants_currentPrice_cashPrice,
  LensBuilderQuery_productLensBuilder_products_variants_currentPrice_discounted,
  MarketingCompOpt_posSelectPatient_marketingComOpt,
  PatientOptomateOrders_posSelectPatient_optomateOrders,
  ResourceAction,
  SearchProductsContactLensesQuery_search_products,
  SearchProductsContactLensesQuery_search_products_variants,
  SearchProductsContactLensesQuery_search_products_variants_stocks,
  SearchProductsFrameQuery_search_products,
  SearchProductsFrameQuery_search_products_variants,
  SearchProductsFrameQuery_search_products_variants_stocks,
  SearchProductsLensesQuery_search_products_variants,
  SearchProductsLensesQuery_search_products_variants_stocks,
  SearchProductsOtherQuery_search_products,
  SearchProductsOtherQuery_search_products_variants,
  SearchProductsOtherQuery_search_products_variants_stocks,
  SearchProductsQuery_search_products,
  SearchProductsQuery_search_products_variants,
  SearchProductsQuery_search_products_variants_currentPrice_cashPrice,
  SearchProductsQuery_search_products_variants_currentPrice_discounted,
  SearchProductsQuery_search_products_variants_stocks,
  UpdateCustomerProfileNote_updateCustomerProfileNote_profileNotes,
  VPrismType,
} from "./generated";

export enum SPHERE_TYPE {
  SPHERIC = "spheric",
  TORIC = "toric",
  MULTI_FOCAL = "multifocal",
  MULTI_FOCAL_TORIC = "multifocal-toric",
}

export enum CATEGORY_SLUG {
  CONTACT_LENSES = "contact-lenses",
  LENSES = "lenses",
  SERVICES = "services",
  FRAME = "frame",
  ADD_ON = "addons",
  ACCESSORIES = "accessories",
  CONTACT = "CONTACT",
}

export enum PRODUCT_TYPE_KEY {
  CONTACT_LENSES = "contactLenses",
  LENSES = "lenses",
  SERVICES = "services",
  FRAME = "frame",
  ADD_ON = "addons",
  ACCESSORIES = "accessories",
  GIFTS = "gifts",
}

export enum RX_TYPE {
  GLASSES = "glasses",
  CONTACTS = "contact-lenses",
  NONE = "none",
}

export type CurrentPrice = {
  cashPrice:
    | SearchProductsQuery_search_products_variants_currentPrice_cashPrice
    | LensBuilderQuery_productLensBuilder_products_variants_currentPrice_cashPrice
    | null;
  discounted:
    | SearchProductsQuery_search_products_variants_currentPrice_discounted
    | LensBuilderQuery_productLensBuilder_products_variants_currentPrice_discounted
    | null;
};

export type Stock = SearchProductsQuery_search_products_variants_stocks & {
  storeStockType?: "shape-library" | "trace-required" | "fit-to-frame";
  keyAndType?: string;
};

export interface Variant extends Omit<SearchProductsQuery_search_products_variants, "currentPrice"> {
  sku: string;
  name: string;
  sphere: SPHERE_TYPE;
  cyl: string[];
  axis: string[];
  add: string[];
  power: string[];
  modality: string;
  daysUsage: string;
  currentPrice: CurrentPrice;
  supplyChannelKey?: string;
  stocks: Stock[];
  sunOpt?: boolean;
  lineItemId?: string;
  lensSKUs?: [];
  allAttributes?: {
    olnsMaterial?: {
      key?: string;
      label?: string;
    };
    description?: string;
    colourDescription?: string;
    productHandle?: string;
    winkSku?: string;
    storeStockAvailability?: string[];
  };
}

export interface Product extends Omit<SearchProductsQuery_search_products, "variants"> {
  variants: Variant[];
}

export type DataProduct = {
  name: string;
  key: string;
  variants: Variant[];
};

export interface AddToCartDraftWithLineItemId extends addToCartDraft {
  lineItemId?: string;
}

export interface SelectedProductItemToAdd {
  itemToAdd: AddToCartDraftWithLineItemId;
}

export interface ContactLensSelectedProduct extends SelectedProductItemToAdd {
  productFamily: string;
  productName?: string;
  variant?: Variant;
}

export type ContactLensSelectedProducts = Record<EyeType, ContactLensSelectedProduct>;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type TransformKVPair = {
  key: string;
  value: string;
};

export type Address = Omit<addressDraft, "saveToProfile" | "title" | "firstName" | "lastName" | "phone" | "mobile" | "email">;

export interface PatientBase {
  id: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  email: string;
  access_token: string;
  refresh_token: string;
  expiresAt: string;
}

export type PatientMarketingComOpt = MarketingCompOpt_posSelectPatient_marketingComOpt;

export interface Patient extends PatientBase {
  age?: string;
  consent?: boolean;
  dateOfBirth: string;
  externalId?: string;
  gender?: string;
  healthfundName?: string;
  landline?: string;
  marketingEmailsOptin?: boolean;
  middleName?: string;
  notes?: string | null;
  postalAddress?: Address | string;
  preferredName?: string;
  workNumber?: string;
  medicareExpiry?: string; //mm-yyyy
  medicare?: string; // 10 digits
  medicareReference?: string; //1 digit
  isMedicareValidated?: boolean;
  privateHealthFundCode?: string | null;
  privateHealthFundMemberNumber?: string | null;
  privateHealthFundMemberReference?: string | null;
  patientAlreadyExist?: boolean;
  profileNotes?: ProfileNote[];
  marketingComOpt?: PatientMarketingComOpt | null;
  provincialHealthProvider?: string | null;
  provincialHealthNumber?: string | null;
  provincialHealthVersion?: string | null;
  provincialHealthExpiry?: string | null;
}

export interface PatientPayload {
  firstName: string;
  lastName?: string;
  preferredName?: string;
  mobilePhone?: string;
  email?: string;
  dateOfBirth?: string;
  marketingEmailsOptin?: boolean;
  landline?: string;
  workNumber?: string;
  medicareExpiry?: string; //mm-yyyy
  medicare?: string; // 10 digits
  medicareReference?: string; //1 digit
  isMedicareValidated?: boolean;
}

export interface CustomerOrder {
  distributionChannelKey: string | null;
  batchNumber: string | null;
  createdAt: string | null;
}

export interface ReplenishmentOrder {
  essilorStoreCode: string | null;
  batchNumber: string | null;
  date: string | null;
}

export type SearchResultProducts =
  | SearchProductsFrameQuery_search_products
  | SearchProductsContactLensesQuery_search_products
  | SearchProductsLensesQuery_search_products_variants
  | SearchProductsOtherQuery_search_products
  | SearchProductsQuery_search_products;

export type SearchResultProductsVariants =
  | SearchProductsFrameQuery_search_products_variants
  | SearchProductsContactLensesQuery_search_products_variants
  | SearchProductsLensesQuery_search_products_variants
  | SearchProductsOtherQuery_search_products_variants
  | SearchProductsQuery_search_products_variants;

export type SearchProductsResultVariantStock =
  | SearchProductsFrameQuery_search_products_variants_stocks
  | SearchProductsContactLensesQuery_search_products_variants_stocks
  | SearchProductsLensesQuery_search_products_variants_stocks
  | SearchProductsOtherQuery_search_products_variants_stocks
  | SearchProductsQuery_search_products_variants_stocks;

export type ProfileNote = UpdateCustomerProfileNote_updateCustomerProfileNote_profileNotes & {
  id?: string;
  patientId?: string;
  content: string;
  isTask?: boolean;
  dueDate?: string;
  isCompleted?: boolean;
  isPrivate?: boolean;
  action?: ResourceAction;
};

export enum PaymentMethod {
  ACTConcessionScheme = "ACTConcessionScheme",
  Afterpay = "Afterpay",
  Amex = "Amex",
  BNGiftVoucher = "BNGiftVoucher",
  Cash = "Cash",
  DefenceHealth = "DefenceHealth",
  EFTPOS = "EFTPOS",
  HealthFund = "HealthFund",
  Laybuy = "Laybuy",
  Mastercard = "Mastercard",
  NTConcessionScheme = "NTConcessionScheme",
  RoyalPay = "RoyalPay",
  Visa = "Visa",
  ZipPay = "ZipPay",
  Other = "Other",
  ProvincialBilling = "ProvincialBilling",
}

export type LensePrescription = {
  eye: EyeType | null;
  sphere: string | null;
  cyl: string | null;
  axis: string | null;
  add: string | null;
  power: string | null;
  nearpd: string | null;
  height: string | null;
  pd: string | null;
  intAdd: string | null;
  hPrism: string | null;
  vPrism: string | null;
  hPrismType: HPrismType | null;
  vPrismType: VPrismType | null;
  genericAdd: string | null;
  sku?: string | null;
};

export type CollapsedOrder = {
  createdAt: string;
  orderNumber: string;
  status: string;
  notes: string;
  optomateOrder?: OptomateOrder;
};

export type OptomateOrder = PatientOptomateOrders_posSelectPatient_optomateOrders;

export type ConfigReportItem = {
  id?: string;
  name?: string;
  url?: string;
};

export type ConfigCommon = {
  country: string;
  id: string;
  value: number | string;
};

export type Attachment = AttachmentFields;

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { AddToCartMutation } from "../../types";
import { addToCartDraft, AddToCartMutationVariables } from "./../../types/generated";

export const ADD_TO_CART = gql`
  mutation AddToCartMutation($addToCartRequest: addToCartDraft!, $bundleNumber: Int) {
    addToCart(addToCartRequest: $addToCartRequest, bundleNumber: $bundleNumber) {
      id
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  addToCart: (item: addToCartDraft, bundleNumber?: number) => Promise<FetchResult<AddToCartMutation>>;
}

export const useAddToCartMutation = (): ExtendedMutation => {
  const [mutate, { loading, error, called, data }] = useMutation<AddToCartMutation, AddToCartMutationVariables>(ADD_TO_CART, {
    onError: void 0,
  });

  const addToCart = useCallback(
    (item: addToCartDraft, bundleNumber?: number) => {
      return mutate({
        variables: {
          addToCartRequest: item,
          bundleNumber: bundleNumber,
        },
      });
    },
    [mutate],
  );

  return {
    addToCart,
    loading,
    error,
    called,
    data,
  };
};

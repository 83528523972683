import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { RemoveItem, RemoveItemVariables } from "../../types";

export const DELETE_LINE_ITEM = gql`
  mutation RemoveItem($removeItemRequest: removeItemDraft!) {
    removeItem(removeItemRequest: $removeItemRequest) {
      id
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  deleteLineItem: (lineItemId: string, bundleNumber?: number, keepBundle?: boolean) => Promise<FetchResult<RemoveItem>>;
}

export const useDeleteLineItemMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<RemoveItem, RemoveItemVariables>(DELETE_LINE_ITEM, {
    onError: () => void 0,
  });

  const deleteLineItem = useCallback(
    (lineItemId?: string, bundleNumber?: number, keepBundle?: boolean) => {
      const variables: RemoveItemVariables = {
        removeItemRequest: {
          lineItemId,
          bundleNumber,
          keepBundle,
        },
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { deleteLineItem, data, error, loading, called };
};

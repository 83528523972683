import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosLoadBulkCustomerOrderTracking, PosLoadBulkCustomerOrderTrackingVariables } from "../../types";
import { useCallback } from "react";

export const LOAD_BULK_CUSTOMER_ORDER = gql`
  mutation PosLoadBulkCustomerOrderTracking($batchNumber: String!, $distributionChannelKey: String!) {
    newPosLoadBulkCustomerOrderTracking(batchNumber: $batchNumber, distributionChannelKey: $distributionChannelKey) {
      status
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  loadBulkCustomerOrderTracking: (batchNumber: string, distributionChannelKey: string) => Promise<FetchResult<PosLoadBulkCustomerOrderTracking>>;
}

export const useLoadBulkCustomerOrderTrackingMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosLoadBulkCustomerOrderTracking, PosLoadBulkCustomerOrderTrackingVariables>(
    LOAD_BULK_CUSTOMER_ORDER,
    {
      onError: () => void 0,
    },
  );

  const loadBulkCustomerOrderTracking = useCallback(
    (batchNumber: string, distributionChannelKey: string) => {
      const variables: PosLoadBulkCustomerOrderTrackingVariables = {
        batchNumber,
        distributionChannelKey,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { loadBulkCustomerOrderTracking, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { AppointmentTypeRequest, PosCreateAppointmentTypeMutation, PosCreateAppointmentTypeMutationVariables } from "../../types";
import { useCallback } from "react";

export const CREATE_APPOINTMENT_TYPE = gql`
  mutation PosCreateAppointmentTypeMutation($posAppointmentTypeRquest: AppointmentTypeRequest) {
    posCreateAppointmentType(appointmentTypeRequest: $posAppointmentTypeRquest) {
      id
      color
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  createPosAppointmentType: (posAppointmentTypeRquest: AppointmentTypeRequest) => Promise<FetchResult<PosCreateAppointmentTypeMutation>>;
}

export const useAppointmentTypeMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosCreateAppointmentTypeMutation, PosCreateAppointmentTypeMutationVariables>(
    CREATE_APPOINTMENT_TYPE,
    {
      onError: () => void 0,
    },
  );

  const createPosAppointmentType = useCallback(
    (appointmentTypeRequest: AppointmentTypeRequest) => {
      const variables: PosCreateAppointmentTypeMutationVariables = {
        posAppointmentTypeRquest: appointmentTypeRequest,
      };

      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { createPosAppointmentType, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { MarketingCommunicationOptinInput, PosUpdateMarketingOptin, PosUpdateMarketingOptinVariables } from "../../types";
import { useCallback } from "react";

export const UPDATE_MARKETING_COM_OPTIN = gql`
  mutation PosUpdateMarketingOptin($patientId: ID!, $optin: MarketingCommunicationOptinInput!) {
    posUpdateMarketingOptin(patientId: $patientId, optin: $optin)
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  posUpdateMarketingOptin: (patientId: string, optin: MarketingCommunicationOptinInput) => Promise<FetchResult<PosUpdateMarketingOptin>>;
}

export const usePosUpdateMarketingComOptin = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosUpdateMarketingOptin, PosUpdateMarketingOptinVariables>(
    UPDATE_MARKETING_COM_OPTIN,
    {
      onError: () => void 0,
    },
  );

  const updateOptin = useCallback(
    (patientId: string, optin: MarketingCommunicationOptinInput) => {
      const variables: PosUpdateMarketingOptinVariables = {
        patientId,
        optin,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { posUpdateMarketingOptin: updateOptin, data, error, loading, called };
};

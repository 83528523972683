import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { RemovePromotion, RemovePromotionVariables } from "../../types";
import { useCallback } from "react";

export const REMOVE_DISCOUNT_CODE = gql`
  mutation RemovePromotion($discountId: String!) {
    removePromotion(discountId: $discountId) {
      id
      version
      totalPrice {
        centAmount
      }
      discountCodes {
        discountCode {
          code
          id
        }
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  removeDiscountCode: (discountId: string) => Promise<FetchResult<RemovePromotion>>;
}

export const useRemoveDiscountCodeMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<RemovePromotion, RemovePromotionVariables>(REMOVE_DISCOUNT_CODE, {
    onError: () => void 0,
  });

  const removeDiscountCode = useCallback(
    (discountId: string) => {
      const variables: RemovePromotionVariables = {
        discountId,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { removeDiscountCode, data, error, loading, called };
};

import { gql } from "@apollo/client";

export const APPOINTMENT_TYPE_FIELDS = gql`
  fragment AppointmentTypeFields on AppointmentType {
    id
    name
    publicName
    publicDescription
    durationPeakA
    durationOffPeakA
    durationPeakB
    durationOffPeakB
    durationPeakC
    durationOffPeakC
    onlineBooking
    requiresPatient
    color
    active
  }
`;

export const PATIENT_FIELDS = gql`
  fragment PatientFields on Patient {
    id
    firstName
    lastName
    preferredName
    dateOfBirth
    externalId
    mobilePhone
    landline
    workNumber
    email
    access_token
    refresh_token
    expiresAt
    medicare
    medicareReference
    medicareExpiry
    isMedicareValidated
    notes
    externalId
    countryCode
    gender
    privateHealthFundCode
    privateHealthFundMemberNumber
    privateHealthFundMemberReference
    provincialHealthProvider
    provincialHealthNumber
    provincialHealthVersion
    provincialHealthExpiry
    patientAlreadyExist
  }
`;

export const CORE_OPTOMETRIST_FIELDS = gql`
  fragment CoreOptometristFields on Optometrist {
    id
    firstName
    lastName
    email
    appointmentSet
  }
`;

export const ADDRESS_FIELDS = gql`
  fragment AddressFields on Address {
    createdAt
    updatedAt
    additionalAddressInfo
    additionalStreetInfo
    apartment
    building
    city
    company
    country {
      code
    }
    department
    email
    externalId
    firstName
    id
    key
    lastName
    mobile
    pOBox
    phone
    postalCode
    region
    salutation
    state
    streetName
    streetNumber
    title
    isDefault
    isShipping
    addressLine1
    addressLine2
    suburb
  }
`;

export const LINE_ITEM_FIELDS = gql`
  fragment LineItemFields on LineItem {
    id
    productId
    name {
      en
    }
    productSlug {
      en
    }
    productType {
      key
      id
    }
    variant {
      name
      key
      sku
      singleHealthFundCode
      multiHealthFundCode
      singleHealthFundCodeAddOns
      multiHealthFundCodeAddOns
      customerGroupHealthFundCode
      eoltTraceLib
      bnTraceLib
      localTier {
        key
      }
      images {
        url
        label
      }
    }
    price {
      value {
        currencyCode
        centAmount
      }
      discounted {
        value {
          currencyCode
          centAmount
        }
      }
    }
    quantity
    discounts {
      totalDiscount {
        currencyCode
        centAmount
      }
      includedDiscounts {
        displayInCart
        displayOnTaxInvoice
        cartDisplayName
        invoiceDisplayName
        name
        description
        amount {
          type
          currencyCode
          centAmount
          fractionDigits
        }
      }
    }
    supplyChannel {
      key
    }
    distributionChannel {
      id
      name {
        en
      }
    }
    shippingDetails {
      targets {
        addressKey
        quantity
      }
      valid
    }
    totalPrice {
      currencyCode
      centAmount
    }
    customFields {
      eye
      sphere
      cyl
      axis
      add
      power
      nearpd
      height
      pd
      bvd
      bundleNumber
      intAdd
      hPrism
      vPrism
      lensType
      hPrismType
      vPrismType
      physicalTraceRequired
      frameSku
      sunOpt
      genericAdd
      isQuote
      fitToFrame
      originalLensSku
    }
  }
`;

export const CART_FIELDS = gql`
  ${LINE_ITEM_FIELDS}
  ${ADDRESS_FIELDS}
  fragment CartFields on Cart {
    id
    createdAt
    isQuote
    lineItems {
      ...LineItemFields
    }
    customFields {
      specialInstructions
      contactMethod
      invoiceOnlyOrder
    }
    totalPrice {
      currencyCode
      centAmount
    }
    taxedPrice {
      totalNet {
        centAmount
        currencyCode
      }
      totalGross {
        centAmount
        currencyCode
      }
      taxPortions {
        rate
        amount {
          centAmount
          currencyCode
        }
        name
      }
    }
    shippingAddress {
      ...AddressFields
    }
    shippingInfo {
      shippingMethodName
      shippingMethod {
        key
      }
      price {
        currencyCode
        centAmount
      }
    }
    paymentInfo {
      payments {
        key
        amountPlanned {
          centAmount
          fractionDigits
          currencyCode
          type
        }
        creditCardNumber
        paymentMethod
      }
    }
    discountCodes {
      discountCodeRef {
        typeId
        id
      }
      state
      discountCode {
        code
        validFrom
        validUntil
        name {
          en
        }
        description {
          en
        }
        id
      }
    }
  }
`;

export const ORDER_FIELD = gql`
  fragment OrderField on Order {
    id
    status
    orderNumber
    createdAt
    updatedAt
    totalPrice {
      currencyCode
      centAmount
    }
    taxedPrice {
      totalNet {
        currencyCode
        centAmount
      }
      totalGross {
        currencyCode
        centAmount
      }
      taxPortions {
        rate
        name
        amount {
          centAmount
          currencyCode
        }
      }
    }
    patientId
    shippingInfo {
      shippingMethodName
      shippingMethod {
        key
      }
      price {
        currencyCode
        centAmount
      }
    }
    shippingAddress {
      id
      title
      salutation
      firstName
      lastName
      streetName
      streetNumber
      additionalStreetInfo
      postalCode
      city
      region
      state
      country {
        code
      }
      company
      department
      building
      apartment
      pOBox
      additionalAddressInfo
      externalId
      key
      phone
      mobile
      email
      fax
      suburb
      addressLine1
      addressLine2
      isDefault
      isShipping
      createdAt
      updatedAt
    }
    customFields {
      specialInstructions
      customerNotified
      contactMethod
      smsStatus
      emailStatus
      isOrderChild
      orderParentNumber
    }
    lineItems {
      id
      quantity
      productId
      name {
        en
      }
      productType {
        key
      }
      supplyChannel {
        key
      }
      distributionChannel {
        key
        name {
          en
        }
      }
      variant {
        name
        key
        sku
        images {
          url
        }
        singleHealthFundCode
        multiHealthFundCode
        singleHealthFundCodeAddOns
        multiHealthFundCodeAddOns
        customerGroupHealthFundCode
      }
      returnInfos {
        comment
      }
      totalPrice {
        centAmount
        currencyCode
      }
      discounts {
        totalDiscount {
          currencyCode
          centAmount
        }
        includedDiscounts {
          displayInCart
          displayOnTaxInvoice
          cartDisplayName
          invoiceDisplayName
          name
          description
          amount {
            type
            currencyCode
            centAmount
            fractionDigits
          }
        }
      }
      state {
        quantity
        state {
          id
          key
          name {
            en
          }
        }
      }
      customFields {
        eye
        bundleNumber
        sphere
        lensType
        cyl
        axis
        add
        height
        nearpd
        power
        pd
        bvd
        intAdd
        hPrism
        vPrism
        hPrismType
        vPrismType
        physicalTraceRequired
        sunOpt
        frameSku
        collectedDate
        isTrace
      }
    }
    fullPaymentInfo {
      id
      amountPlanned {
        currencyCode
        centAmount
        fractionDigits
      }
      paymentGateway
      paymentMethod
      transactions {
        type
        timestamp
        amount {
          currencyCode
          centAmount
        }
        state
        id
      }
    }
  }
`;

export const ORDER_ROW = gql`
  fragment OrderRow on Order {
    id
    status
    orderNumber
    createdAt
    updatedAt
    totalPrice {
      currencyCode
      centAmount
    }
    patientId
    shippingInfo {
      shippingMethodName
    }
    shippingAddress {
      firstName
      lastName
    }
    customFields {
      specialInstructions
      customerNotified
      contactMethod
      smsStatus
      emailStatus
    }
    lineItems {
      id
      quantity
      productId
      name {
        en
      }
      productType {
        key
      }
      supplyChannel {
        key
      }
      distributionChannel {
        key
        name {
          en
        }
      }
      variant {
        name
        key
        sku
        singleHealthFundCode
        multiHealthFundCode
        singleHealthFundCodeAddOns
        multiHealthFundCodeAddOns
        customerGroupHealthFundCode
      }
      returnInfos {
        comment
      }
      totalPrice {
        centAmount
        currencyCode
      }
      state {
        quantity
        state {
          id
          key
          name {
            en
          }
        }
      }
      customFields {
        bundleNumber
        lensType
        physicalTraceRequired
        sunOpt
        frameSku
        collectedDate
        isTrace
      }
    }
  }
`;

export const CONTACTS_PRESCRIPTION_FIELDS = gql`
  fragment ContactsPrescriptionFields on ContactsPrescription {
    id
    optometristName
    practiceName
    issueDate
    expiresDate
    odSku
    odSupplyChannelsKey
    osSupplyChannelsKey
    odPower
    odCyl
    odAxis
    odAdd
    odGenericAdd
    osSku
    osPower
    osCyl
    osAxis
    osAdd
    osGenericAdd
    optometrist {
      id
      firstName
      lastName
    }
    recommendations
    practiceNotes
    downloadUrl
    source
    attachments {
      id
      name
      filename
      downloadUrl
      uploadUrl
      contentType
    }
  }
`;

export const GLASSES_PRESCRIPTION_FIELDS = gql`
  fragment GlassesPrescriptionFields on GlassesPrescription {
    id
    optometristName
    practiceName
    issueDate
    expiresDate
    odCyl
    odAxis
    odAdd
    osCyl
    osAxis
    osAdd
    optometrist {
      id
      firstName
      lastName
    }
    odSph
    odIntAdd
    odPd
    odNearPd
    odHPrismType
    odHPrism
    odVPrismType
    odVPrism
    osSph
    osIntAdd
    osPd
    osNearPd
    osHPrismType
    osHPrism
    osVPrismType
    osVPrism
    recommendations
    practiceNotes
    downloadUrl
    source
    attachments {
      id
      name
      filename
      downloadUrl
      uploadUrl
      contentType
    }
  }
`;

export const SEARCH_PRODUCT_FIELDS_FRAME = gql`
  fragment SearchProductFieldsFrame on ProductSearchResponse {
    name
    key
    variants {
      sku
      name
      allAttributes
      images {
        url
        label
      }
      stocks {
        key
        name
        availableQuantity
      }
      bnTrackLib
      eoltTrackLib
      currentPrice {
        cashPrice {
          currencyCode
          amount
        }
        discounted {
          value {
            currencyCode
            centAmount
          }
        }
      }
    }
  }
`;

export const SEARCH_PRODUCT_FIELDS_CONTACT_LENSES = gql`
  fragment SearchProductFieldsContactLenses on ProductSearchResponse {
    name
    key
    variants {
      allAttributes
      sku
      name
      images {
        url
        label
      }
      sphere
      cyl
      axis
      add
      power
      subscriptionFrequency
      modality
      daysUsage
      stocks {
        key
        name
        availableQuantity
      }
      bnTrackLib
      eoltTrackLib
      preferredCLSupplyChannelKey
      currentPrice {
        cashPrice {
          currencyCode
          amount
        }
        discounted {
          value {
            currencyCode
            centAmount
          }
        }
      }
    }
  }
`;

export const SEARCH_PRODUCT_FIELDS_LENSES = gql`
  fragment SearchProductFieldsLenses on ProductSearchResponse {
    name
    key
    variants {
      sku
      name
      cyl
      axis
      add
      power
      stocks {
        key
        name
        availableQuantity
      }
      currentPrice {
        cashPrice {
          currencyCode
          amount
        }
        discounted {
          value {
            currencyCode
            centAmount
          }
        }
      }
    }
  }
`;

export const SEARCH_PRODUCT_FIELDS_ADDON_ACCESSORIES_SERVICES = gql`
  fragment SearchProductFieldsOther on ProductSearchResponse {
    name
    key
    variants {
      sku
      name
      images {
        url
        label
      }
      stocks {
        key
        name
        availableQuantity
      }
      currentPrice {
        cashPrice {
          currencyCode
          amount
        }
        discounted {
          value {
            currencyCode
            centAmount
          }
        }
      }
    }
  }
`;

// Keep this fragment to generate all possible interface's properties
export const SEARCH_PRODUCT_FIELDS = gql`
  fragment SearchProductFields on ProductSearchResponse {
    name
    key
    variants {
      sku
      name
      images {
        url
        label
      }
      sphere
      cyl
      axis
      add
      power
      subscriptionFrequency
      modality
      daysUsage
      stocks {
        key
        name
        availableQuantity
      }
      bnTrackLib
      eoltTrackLib
      preferredCLSupplyChannelKey
      currentPrice {
        cashPrice {
          currencyCode
          amount
        }
        discounted {
          value {
            currencyCode
            centAmount
          }
        }
      }
    }
  }
`;

export const PROFILE_NOTES = gql`
  fragment ProfileNotes on Patient {
    profileNotes {
      id
      user {
        name
        email
      }
      date
      content
      isTask
      dueDate
      isCompleted
      isPrivate
      isReadOnly
      noteType
    }
  }
`;

export const ATTACHMENT_FIELDS = gql`
  fragment AttachmentFields on Attachment {
    id
    attachedTo {
      ... on ContactsPrescription {
        id
      }
      ... on GlassesPrescription {
        id
      }
    }
    name
    filename
    contentType
    downloadUrl
    uploadUrl
    createdAt
    updatedAt
    deletedAt
    uploadedAt
  }
`;

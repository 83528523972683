import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { OptometristRequest, PosCreateOptometristMutation, PosCreateOptometristMutationVariables } from "../../types";
import { useCallback } from "react";

export const CREATE_OPTOMETRIST = gql`
  mutation PosCreateOptometristMutation($posCreateOptometristOptometristRequest: OptometristRequest) {
    posCreateOptometrist(optometristRequest: $posCreateOptometristOptometristRequest) {
      id
      firstName
      lastName
      email
    }
  }
`;
interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  createOptometrist: (optometristRequest: OptometristRequest) => Promise<FetchResult<PosCreateOptometristMutation>>;
}

export const useCreateOptometristMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosCreateOptometristMutation, PosCreateOptometristMutationVariables>(
    CREATE_OPTOMETRIST,
    {
      onError: () => void 0,
    },
  );

  const createOptometrist = useCallback(
    (optometristRequest: OptometristRequest) => {
      const variables: PosCreateOptometristMutationVariables = {
        posCreateOptometristOptometristRequest: optometristRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { createOptometrist, data, error, loading, called };
};

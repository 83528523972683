import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { RetrieveHealthFundRefunds, RetrieveHealthFundRefundsVariables } from "../../types";

export const HEALTH_FUND_REFUNDS = gql`
  query RetrieveHealthFundRefunds($retrieveHfRefundRequest: RetrieveHFRefundRequest) {
    retrieveHealthFundRefunds(retrieveHFRefundRequest: $retrieveHfRefundRequest) {
      id
      staffName
      refundType
      customerName
      healthFund
      dateOfService
      healthFundNumber
      healthFundReference
      distributionChannelKey
      providerNumber
      providersName
      totalPrice
      healthFundPayment
      reason
      status
      createdAt
      orderNumber
      hfRefundAmount
      comment
      storeNotes
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<RetrieveHealthFundRefunds>, "data" | "loading" | "error" | "variables" | "called"> {
  getHealthFundRefunds: LazyQueryExecFunction<RetrieveHealthFundRefunds, RetrieveHealthFundRefundsVariables>;
}

export const useRetrieveHealthFundRefundsLazyQuery = (): ExtendedQueryResult => {
  const [getHealthFundRefunds, { data, error, loading, called, variables }] = useLazyQuery<
    RetrieveHealthFundRefunds,
    RetrieveHealthFundRefundsVariables
  >(HEALTH_FUND_REFUNDS, {
    onError: () => void 0,
  });

  return {
    getHealthFundRefunds,
    data,
    error,
    loading,
    called,
    variables,
  };
};

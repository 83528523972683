import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { useCallback } from "react";

import { SetOriginalSku } from "../../types";
import { CART_FIELDS } from "../fragments";

export const SET_ORIGINAL_SKU = gql`
  ${CART_FIELDS}
  mutation SetOriginalSku {
    setOriginalSku {
      ...CartFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setOriginalSku: () => Promise<FetchResult<SetOriginalSku>>;
}

export const useSetOriginalSku = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SetOriginalSku>(SET_ORIGINAL_SKU, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  const setOriginalSku = useCallback(() => {
    return mutate();
  }, [mutate]);

  return { setOriginalSku, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PrintQuote, PrintQuoteVariables } from "../../types";
import { useCallback } from "react";

export const PRINT_QUOTE = gql`
  mutation PrintQuote($cartId: String!) {
    printQuote(cartId: $cartId)
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  printQuote: (cartId: string) => Promise<FetchResult<PrintQuote>>;
}

export const usePrintQuote = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PrintQuote, PrintQuoteVariables>(PRINT_QUOTE, {
    onError: () => void 0,
  });

  const printQuote = useCallback(
    (cartId: string) => {
      const variables: PrintQuoteVariables = {
        cartId,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { printQuote, data, error, loading, called };
};

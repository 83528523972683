import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PatientUpdateRequest, PosUpdatePatient, PosUpdatePatientVariables } from "../../types";
import { getCountryCode } from "../../utils";
import { PATIENT_FIELDS } from "../fragments";
import { useCallback } from "react";

export const UPDATE_PATIENT = gql`
  ${PATIENT_FIELDS}
  mutation PosUpdatePatient($updatePatientRequest: PatientUpdateRequest) {
    posUpdatePatient(patientUpdateRequest: $updatePatientRequest) {
      ...PatientFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updatePatient: (patientUpdateRequest: PatientUpdateRequest) => Promise<FetchResult<PosUpdatePatient>>;
}

export const useUpdatePatientMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosUpdatePatient, PosUpdatePatientVariables>(UPDATE_PATIENT, {
    onError: () => void 0,
  });

  const countryCode = getCountryCode().toUpperCase();

  const updatePatient = useCallback(
    (patientRequest: PatientUpdateRequest) => {
      const variables: PosUpdatePatientVariables = {
        updatePatientRequest: {
          ...patientRequest,
          countryCode,
        },
      };
      return mutate({
        variables,
      });
    },
    [countryCode, mutate],
  );

  return { updatePatient, data, error, loading, called };
};

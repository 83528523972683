import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { ContactsPrescriptionRequest, CreateContactsPrescription, CreateContactsPrescriptionVariables } from "../../types";
import { CONTACTS_PRESCRIPTION_FIELDS } from "../fragments";
import { useCallback } from "react";

export const CREATE_CONTACTS_PRESCRIPTION = gql`
  ${CONTACTS_PRESCRIPTION_FIELDS}
  mutation CreateContactsPrescription($contactsPrescriptionRequest: ContactsPrescriptionRequest) {
    createContactsPrescription(contactsPrescriptionRequest: $contactsPrescriptionRequest) {
      ...ContactsPrescriptionFields
    }
  }
`;
interface ExtendedMutation extends Omit<MutationResult<CreateContactsPrescription>, "client" | "reset"> {
  createContactsPrescription: (contactsPrescriptionRequest: ContactsPrescriptionRequest) => Promise<FetchResult<CreateContactsPrescription>>;
}

export const useCreateContactsPrescriptionMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<CreateContactsPrescription, CreateContactsPrescriptionVariables>(
    CREATE_CONTACTS_PRESCRIPTION,
    {
      onError: () => void 0,
      notifyOnNetworkStatusChange: true,
    },
  );

  const createContactsPrescription = useCallback(
    (contactsPrescriptionRequest: ContactsPrescriptionRequest) => {
      const variables = {
        contactsPrescriptionRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { createContactsPrescription, data, error, loading, called };
};

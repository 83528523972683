import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { AvailabilityRequest, PosCreateAvailabilityMutation, PosCreateAvailabilityMutationVariables } from "../../types";
import { useCallback } from "react";

export const CREATE_AVAILABILITY = gql`
  mutation PosCreateAvailabilityMutation($availabilityRequests: [AvailabilityRequest]) {
    posCreateAvailability(availabilityRequests: $availabilityRequests) {
      availabilities {
        id
        distributionChannel {
          key
          name
        }
        startAt
        endAt
        peakStartAt
        peakEndAt
        optometrist {
          firstName
          lastName
          id
        }
      }
      errors
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  createAvailability: (availabilityRequests: AvailabilityRequest[]) => Promise<FetchResult<PosCreateAvailabilityMutation>>;
}

export const useCreateAvailabilityMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosCreateAvailabilityMutation, PosCreateAvailabilityMutationVariables>(
    CREATE_AVAILABILITY,
    {
      onError: () => void 0,
    },
  );

  const createAvailability = useCallback(
    (availabilityRequests: AvailabilityRequest[]) => {
      const variables: PosCreateAvailabilityMutationVariables = {
        availabilityRequests,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { createAvailability, data, error, loading, called };
};

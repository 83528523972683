import { gql, LazyQueryExecFunction, OperationVariables, QueryResult, useLazyQuery } from "@apollo/client";

import { ShippingMethodsForCart } from "../../types";

export const SHIPPING_METHODS = gql`
  query ShippingMethodsForCart {
    shippingMethodsForCart {
      shippingMethods {
        name
        description
        isDefault
        key
        id
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<ShippingMethodsForCart>, "data" | "loading" | "error" | "called"> {
  getShippingMethods: LazyQueryExecFunction<ShippingMethodsForCart, OperationVariables>;
}

export const useShippingMethodsQuery = (): ExtendedQueryResult => {
  const [getShippingMethods, { data, error, loading, called }] = useLazyQuery<ShippingMethodsForCart>(SHIPPING_METHODS, {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return {
    getShippingMethods,
    data,
    error,
    loading,
    called,
  };
};

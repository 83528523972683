import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AUTH_KEYS, DistributionChannels_distributionChannels } from "../../types";

export interface AuthenticationState {
  activeDistributionChannel?: DistributionChannels_distributionChannels;
  cognitoAuthEmail?: string;
  cognitoAuthName?: string;
  staffAuthenticated: boolean;
  storeAuthenticated: boolean;
}

export const initialAuthenticationState: AuthenticationState = {
  activeDistributionChannel: undefined,
  cognitoAuthEmail: undefined,
  cognitoAuthName: undefined,
  staffAuthenticated: false,
  storeAuthenticated: false,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialAuthenticationState,
  reducers: {
    setActiveDistributionChannel: (state, action: PayloadAction<DistributionChannels_distributionChannels>) => {
      state.activeDistributionChannel = action.payload;
    },
    setCognitoAuthEmail: (state, action: PayloadAction<string | undefined>) => {
      state.cognitoAuthEmail = process.env.REACT_APP_DEV_FLAG_STORE_OVERRIDE ?? action.payload;
    },
    setCognitoAuthName: (state, action: PayloadAction<string | undefined>) => {
      state.cognitoAuthName = action.payload;
    },
    storeLogout: (state) => {
      state.storeAuthenticated = false;
      sessionStorage.removeItem(AUTH_KEYS.STORE_AUTH_TOKEN);
      sessionStorage.removeItem(AUTH_KEYS.STORE_AUTH_EXPIRES);
    },
    storeLogin: (state) => {
      state.storeAuthenticated = true;
    },
    staffLogout: (state) => {
      state.staffAuthenticated = false;
      state.cognitoAuthEmail = undefined;
      state.cognitoAuthName = undefined;
    },
    staffLogin: (state) => {
      state.staffAuthenticated = true;
    },
  },
});

export const { storeLogout, storeLogin, staffLogin, staffLogout, setActiveDistributionChannel, setCognitoAuthEmail, setCognitoAuthName } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;

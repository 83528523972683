import { gql, QueryResult, useQuery } from "@apollo/client";

import { CartContactMethod } from "../../types";

export const CART_CONTACT_METHOD = gql`
  query CartContactMethod {
    cart {
      customFields {
        contactMethod
      }
    }
  }
`;

type UseCartContactMethodQuery = {
  onCompleted?: (data: CartContactMethod) => void;
};

export const useCartContactMethodQuery = ({ onCompleted }: UseCartContactMethodQuery): QueryResult<CartContactMethod> =>
  useQuery<CartContactMethod>(CART_CONTACT_METHOD, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (typeof onCompleted === "function") {
        onCompleted(data);
      }
    },
  });

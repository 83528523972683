import { gql, LazyQueryExecFunction, LazyQueryResult, useLazyQuery } from "@apollo/client";

import { PosRetrieveStoreAppointmentsQuery, PosRetrieveStoreAppointmentsQueryVariables } from "../../types";
import { APPOINTMENT_TYPE_FIELDS, CORE_OPTOMETRIST_FIELDS, PATIENT_FIELDS } from "../fragments";

export const STORE_APPOINTMENTS = gql`
  ${APPOINTMENT_TYPE_FIELDS}
  ${CORE_OPTOMETRIST_FIELDS}
  ${PATIENT_FIELDS}
  query PosRetrieveStoreAppointmentsQuery($appointmentRetrieveRequest: AppointmentRetrieveRequest!) {
    posRetrieveStoreAppointments(appointmentRetrieveRequest: $appointmentRetrieveRequest) {
      id
      source
      status
      notes
      startAt
      endAt
      distributionChannel {
        key
      }
      patient {
        ...PatientFields
      }
      optometrist {
        ...CoreOptometristFields
        appointmentTypes {
          ...AppointmentTypeFields
        }
      }
      appointmentType {
        ...AppointmentTypeFields
      }
    }
  }
`;

interface ExtendedQueryResult
  extends Pick<
    LazyQueryResult<PosRetrieveStoreAppointmentsQuery, PosRetrieveStoreAppointmentsQueryVariables>,
    "data" | "loading" | "error" | "variables" | "called"
  > {
  getAppointments: LazyQueryExecFunction<PosRetrieveStoreAppointmentsQuery, PosRetrieveStoreAppointmentsQueryVariables>;
  called: boolean;
}

export const useStoreAppointmentsQuery = (): ExtendedQueryResult => {
  const [getAppointments, { data, error, loading, called, variables }] = useLazyQuery<
    PosRetrieveStoreAppointmentsQuery,
    PosRetrieveStoreAppointmentsQueryVariables
  >(STORE_APPOINTMENTS, {
    onError: () => void 0,
  });

  return {
    getAppointments,
    data,
    error,
    loading,
    called,
    variables,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { addressDraft, UpdateCustomerShippingAddress, UpdateCustomerShippingAddressVariables } from "../../types";
import { useCallback } from "react";

export const CREATE_CUSTOMER_SHIPPING_ADDRESS = gql`
  mutation UpdateCustomerShippingAddress($addressRequest: addressDraft!) {
    updateCustomerShippingAddress(addressRequest: $addressRequest) {
      addresses {
        additionalAddressInfo
        apartment
        building
        city
        company
        country {
          code
        }
        department
        email
        externalId
        firstName
        id
        key
        lastName
        mobile
        pOBox
        phone
        postalCode
        region
        salutation
        state
        streetName
        streetNumber
        title
      }
    }
  }
`;
interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  createAddress: (addressRequest: addressDraft) => Promise<FetchResult<UpdateCustomerShippingAddress>>;
}

export const useCreateAddressMutation = (handleOnComplete?: (data: UpdateCustomerShippingAddress) => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<UpdateCustomerShippingAddress, UpdateCustomerShippingAddressVariables>(
    CREATE_CUSTOMER_SHIPPING_ADDRESS,
    {
      onError: () => void 0,
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    },
  );

  const createAddress = useCallback(
    (addressRequest: addressDraft) => {
      return mutate({
        variables: { addressRequest },
      });
    },
    [mutate],
  );

  return { createAddress, data, error, loading, called };
};

import { COLOURS } from "./colours";

export const TRACKING_STATUS_COLOR = {
  GREEN: COLOURS.GREEN,
  YELLOW: COLOURS.YELLOW,
  RED: COLOURS.SECONDARY_RED,
};

export interface ORDER_MESSAGE_TRACKING_STATUS {
  text: string;
  color: string;
}

export const TWILIO_SMS_STATUS_CODE = {
  accepted: "accepted",
  scheduled: "scheduled",
  queued: "queued",
  sending: "sending",
  sent: "sent",
  receiving: "receiving",
  received: "received",
  delivered: "delivered",
  undelivered: "undelivered",
  failed: "failed",
  read: "read",
  canceled: "canceled",
};

export const SENDGRID_EMAIL_STATUS_CODE = {
  processed: "processed",
  dropped: "dropped",
  delivered: "delivered",
  deferred: "deferred",
  bounce: "bounce",
  blocked: "blocked",
};

export const TWILIO_SMS_STATUS: Record<string, ORDER_MESSAGE_TRACKING_STATUS> = {
  [TWILIO_SMS_STATUS_CODE.accepted]: {
    text: "SMS Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [TWILIO_SMS_STATUS_CODE.scheduled]: {
    text: "SMS Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [TWILIO_SMS_STATUS_CODE.queued]: {
    text: "SMS Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [TWILIO_SMS_STATUS_CODE.sending]: {
    text: "SMS Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [TWILIO_SMS_STATUS_CODE.sent]: {
    text: "SMS Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [TWILIO_SMS_STATUS_CODE.receiving]: {
    text: "SMS Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [TWILIO_SMS_STATUS_CODE.received]: {
    text: "SMS Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [TWILIO_SMS_STATUS_CODE.delivered]: {
    text: "Customer Notified by SMS",
    color: TRACKING_STATUS_COLOR.GREEN,
  },
  [TWILIO_SMS_STATUS_CODE.undelivered]: {
    text: "SMS Failed",
    color: TRACKING_STATUS_COLOR.RED,
  },
  [TWILIO_SMS_STATUS_CODE.failed]: {
    text: "SMS Failed",
    color: TRACKING_STATUS_COLOR.RED,
  },
  [TWILIO_SMS_STATUS_CODE.read]: {
    text: "Customer Notified by SMS",
    color: TRACKING_STATUS_COLOR.GREEN,
  },
  [TWILIO_SMS_STATUS_CODE.canceled]: {
    text: "SMS Failed",
    color: TRACKING_STATUS_COLOR.RED,
  },
};

export const SENDGRID_EMAIL_STATUS: Record<string, ORDER_MESSAGE_TRACKING_STATUS> = {
  [SENDGRID_EMAIL_STATUS_CODE.processed]: {
    text: "Email Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [SENDGRID_EMAIL_STATUS_CODE.dropped]: {
    text: "Email Failed",
    color: TRACKING_STATUS_COLOR.RED,
  },
  [SENDGRID_EMAIL_STATUS_CODE.delivered]: {
    text: "Customer Notified by Email",
    color: TRACKING_STATUS_COLOR.GREEN,
  },
  [SENDGRID_EMAIL_STATUS_CODE.deferred]: {
    text: "Email Pending",
    color: TRACKING_STATUS_COLOR.YELLOW,
  },
  [SENDGRID_EMAIL_STATUS_CODE.bounce]: {
    text: "Email Failed",
    color: TRACKING_STATUS_COLOR.RED,
  },
  [SENDGRID_EMAIL_STATUS_CODE.blocked]: {
    text: "Email Failed",
    color: TRACKING_STATUS_COLOR.RED,
  },
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { AvailabilityUpdateRequest, PosUpdateAvailabilityMutation, PosUpdateAvailabilityMutationVariables } from "../../types";
import { useCallback } from "react";

export const UPDATE_AVAILABILITY = gql`
  mutation PosUpdateAvailabilityMutation($availabilityRequests: [AvailabilityUpdateRequest]) {
    posUpdateAvailability(availabilityUpdateRequests: $availabilityRequests) {
      availabilities {
        id
        distributionChannel {
          key
          name
        }
        startAt
        endAt
        peakStartAt
        peakEndAt
        optometrist {
          firstName
          lastName
          id
        }
      }
      errors
    }
  }
`;
interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updateAvailability: (requests: AvailabilityUpdateRequest[]) => Promise<FetchResult<PosUpdateAvailabilityMutation>>;
}

export const useUpdateAvailabilityMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosUpdateAvailabilityMutation, PosUpdateAvailabilityMutationVariables>(
    UPDATE_AVAILABILITY,
    {
      onError: () => void 0,
    },
  );

  const updateAvailability = useCallback(
    (requests: AvailabilityUpdateRequest[]) => {
      const variables: PosUpdateAvailabilityMutationVariables = {
        availabilityRequests: requests,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateAvailability, data, error, loading, called };
};

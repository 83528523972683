import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { PosRetrieveStoreAvailabilitiesQuery, PosRetrieveStoreAvailabilitiesQueryVariables } from "../../types";
import { APPOINTMENT_TYPE_FIELDS, CORE_OPTOMETRIST_FIELDS } from "../fragments";

export const STORE_AVAILABILITY = gql`
  ${APPOINTMENT_TYPE_FIELDS}
  ${CORE_OPTOMETRIST_FIELDS}
  query PosRetrieveStoreAvailabilitiesQuery($availabilityRetrieveRequest: AvailabilityRetrieveRequest!) {
    posRetrieveStoreAvailabilities(availabilityRetrieveRequest: $availabilityRetrieveRequest) {
      id
      endAt
      startAt
      peakStartAt
      peakEndAt
      distributionChannel {
        name
        key
        timezone
        peakAppointmentTimes
      }
      optometrist {
        ...CoreOptometristFields
        appointmentTypes {
          ...AppointmentTypeFields
        }
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<PosRetrieveStoreAvailabilitiesQuery>, "data" | "loading" | "error" | "variables" | "called"> {
  getAvailability: LazyQueryExecFunction<PosRetrieveStoreAvailabilitiesQuery, PosRetrieveStoreAvailabilitiesQueryVariables>;
}

export const useAvailabilityQuery = (
  query: PosRetrieveStoreAvailabilitiesQueryVariables | undefined,
): QueryResult<PosRetrieveStoreAvailabilitiesQuery, PosRetrieveStoreAvailabilitiesQueryVariables> =>
  useQuery<PosRetrieveStoreAvailabilitiesQuery, PosRetrieveStoreAvailabilitiesQueryVariables>(STORE_AVAILABILITY, {
    variables: query,
    fetchPolicy: "no-cache",
    onError: () => void 0,
    skip: query === undefined,
  });

export const useStoreAvailabilityQuery = (): ExtendedQueryResult => {
  const [getAvailability, { data, error, loading, called, variables }] = useLazyQuery<
    PosRetrieveStoreAvailabilitiesQuery,
    PosRetrieveStoreAvailabilitiesQueryVariables
  >(STORE_AVAILABILITY, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
  });

  return { getAvailability, data, error, loading, called, variables };
};

import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PATHS } from "../constants";

const Home: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(PATHS.APPOINTMENTS);
  }, [navigate]);
  return <></>;
};

export default Home;

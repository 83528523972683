import { DocumentNode, gql, LazyQueryExecFunction, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { CATEGORY_SLUG, SearchProductsQuery, SearchProductsQueryVariables } from "../../types";
import {
  SEARCH_PRODUCT_FIELDS,
  SEARCH_PRODUCT_FIELDS_ADDON_ACCESSORIES_SERVICES,
  SEARCH_PRODUCT_FIELDS_CONTACT_LENSES,
  SEARCH_PRODUCT_FIELDS_FRAME,
  SEARCH_PRODUCT_FIELDS_LENSES,
} from "../fragments";
import {
  SearchProductsContactLensesQuery,
  SearchProductsContactLensesQueryVariables,
  SearchProductsFrameQuery,
  SearchProductsFrameQueryVariables,
  SearchProductsLensesQuery,
  SearchProductsLensesQueryVariables,
  SearchProductsOtherQuery,
  SearchProductsOtherQueryVariables,
} from "./../../types/generated";
import { useCallback, useMemo, useRef } from "react";

export const QUERY_SEARCH_FRAME = gql`
  ${SEARCH_PRODUCT_FIELDS_FRAME}
  query SearchProductsFrameQuery($searchCategorySlug: String, $queryString: String) {
    search(categorySlug: $searchCategorySlug, query: $queryString) {
      products {
        ...SearchProductFieldsFrame
      }
    }
  }
`;

export const QUERY_SEARCH_LENSES = gql`
  ${SEARCH_PRODUCT_FIELDS_LENSES}
  query SearchProductsLensesQuery($searchCategorySlug: String, $queryString: String) {
    search(categorySlug: $searchCategorySlug, query: $queryString) {
      products {
        ...SearchProductFieldsLenses
      }
    }
  }
`;

export const QUERY_SEARCH_CONTACT_LENSES = gql`
  ${SEARCH_PRODUCT_FIELDS_CONTACT_LENSES}
  query SearchProductsContactLensesQuery($searchCategorySlug: String, $queryString: String) {
    search(categorySlug: $searchCategorySlug, query: $queryString) {
      products {
        ...SearchProductFieldsContactLenses
      }
    }
  }
`;

export const QUERY_SEARCH_ADDON_ACCESSORIES_SERVICES = gql`
  ${SEARCH_PRODUCT_FIELDS_ADDON_ACCESSORIES_SERVICES}
  query SearchProductsOtherQuery($searchCategorySlug: String, $queryString: String) {
    search(categorySlug: $searchCategorySlug, query: $queryString) {
      products {
        ...SearchProductFieldsOther
      }
    }
  }
`;

export const QUERY_SEARCH_PRODUCTS = gql`
  ${SEARCH_PRODUCT_FIELDS}
  query SearchProductsQuery($searchCategorySlug: String, $queryString: String) {
    search(categorySlug: $searchCategorySlug, query: $queryString) {
      products {
        ...SearchProductFields
      }
    }
  }
`;

export const makeQuerySearchProduct = (categorySlug?: CATEGORY_SLUG): DocumentNode => {
  switch (categorySlug) {
    case CATEGORY_SLUG.FRAME:
      return QUERY_SEARCH_FRAME;
    case CATEGORY_SLUG.CONTACT_LENSES:
      return QUERY_SEARCH_CONTACT_LENSES;
    case CATEGORY_SLUG.LENSES:
      return QUERY_SEARCH_LENSES;
    case CATEGORY_SLUG.ADD_ON:
    case CATEGORY_SLUG.ACCESSORIES:
    case CATEGORY_SLUG.SERVICES:
      return QUERY_SEARCH_ADDON_ACCESSORIES_SERVICES;
    default:
      return QUERY_SEARCH_PRODUCTS;
  }
};

export type SearchProductsQueryCommon =
  | SearchProductsFrameQuery
  | SearchProductsLensesQuery
  | SearchProductsContactLensesQuery
  | SearchProductsOtherQuery
  | SearchProductsQuery;

export type SearchProductsQueryCommonVariables =
  | SearchProductsFrameQueryVariables
  | SearchProductsLensesQueryVariables
  | SearchProductsContactLensesQueryVariables
  | SearchProductsOtherQueryVariables
  | SearchProductsQueryVariables;

export const useSearchProductsQuery = (
  searchCategorySlug: CATEGORY_SLUG,
  queryString: string,
): QueryResult<SearchProductsQueryCommon, SearchProductsQueryCommonVariables> =>
  useQuery<SearchProductsQueryCommon, SearchProductsQueryCommonVariables>(makeQuerySearchProduct(searchCategorySlug), {
    variables: { searchCategorySlug: searchCategorySlug, queryString: queryString },
    fetchPolicy: "no-cache",
  });

interface ExtendedQueryResult extends Pick<QueryResult<SearchProductsQueryCommon>, "data" | "loading" | "error" | "variables" | "called"> {
  searchProducts: LazyQueryExecFunction<SearchProductsQueryCommon, SearchProductsQueryCommonVariables>;
  abort: () => void;
}

export const useLazySearchProductsQuery = (
  handleOnComplete?: (data: SearchProductsQueryCommon) => void,
  categorySlug?: CATEGORY_SLUG,
): ExtendedQueryResult => {
  const abortController = useRef(new AbortController());
  const QUERY = useMemo(() => makeQuerySearchProduct(categorySlug), [categorySlug]);
  const [searchProducts, { data, error, loading, called, variables }] = useLazyQuery<SearchProductsQueryCommon, SearchProductsQueryCommonVariables>(
    QUERY,
    {
      fetchPolicy: "no-cache",
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
      notifyOnNetworkStatusChange: true,
      context: {
        fetchOptions: {
          signal: abortController.current.signal,
        },
      },
    },
  );

  const abort = useCallback(() => {
    abortController.current.abort();
    abortController.current = new AbortController();
  }, []);

  return {
    searchProducts,
    data,
    error,
    loading,
    called,
    variables,
    abort,
  };
};

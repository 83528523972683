import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import {
  PatientRetrieveAppointmentsQuery,
  PatientRetrieveAppointmentsQuerySummaryTable,
  PatientRetrieveAppointmentsQuerySummaryTableVariables,
  PatientRetrieveAppointmentsQueryVariables,
} from "../../types";
import { APPOINTMENT_TYPE_FIELDS, CORE_OPTOMETRIST_FIELDS, PATIENT_FIELDS } from "../fragments";

export const PATIENT_APPOINTMENTS = gql`
  ${APPOINTMENT_TYPE_FIELDS}
  ${CORE_OPTOMETRIST_FIELDS}
  ${PATIENT_FIELDS}
  query PatientRetrieveAppointmentsQuery($patientId: String!) {
    patientRetrieveAppointments(patientId: $patientId) {
      id
      source
      status
      notes
      startAt
      endAt
      distributionChannel {
        key
        timezone
        name
      }
      patient {
        ...PatientFields
      }
      optometrist {
        ...CoreOptometristFields
        appointmentTypes {
          ...AppointmentTypeFields
        }
      }
      appointmentType {
        ...AppointmentTypeFields
      }
    }
  }
`;

export const PATIENT_APPOINTMENTS_SUMMARY_TABLE = gql`
  query PatientRetrieveAppointmentsQuerySummaryTable($patientId: String!) {
    patientRetrieveAppointments(patientId: $patientId) {
      id
      startAt
      endAt
      source
      status
      distributionChannel {
        key
        name
      }
      appointmentType {
        name
      }
      optometrist {
        id
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<PatientRetrieveAppointmentsQuery>, "data" | "loading" | "error" | "variables" | "called"> {
  getPatientAppointments: LazyQueryExecFunction<PatientRetrieveAppointmentsQuery, PatientRetrieveAppointmentsQueryVariables>;
}

interface ExtendedQueryResultSummaryTable
  extends Pick<QueryResult<PatientRetrieveAppointmentsQuerySummaryTable>, "data" | "loading" | "error" | "variables"> {
  getPatientAppointments: ({
    variables,
  }: {
    variables: PatientRetrieveAppointmentsQueryVariables;
  }) => Promise<QueryResult<PatientRetrieveAppointmentsQuerySummaryTable, PatientRetrieveAppointmentsQuerySummaryTableVariables>>;
  called: boolean;
}

export const usePatientAppointmentsQuery = (): ExtendedQueryResult => {
  const [getPatientAppointments, { data, error, loading, called, variables }] = useLazyQuery<
    PatientRetrieveAppointmentsQuery,
    PatientRetrieveAppointmentsQueryVariables
  >(PATIENT_APPOINTMENTS, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
  });

  return { getPatientAppointments, data, error, loading, called, variables };
};

export const usePatientAppointmentsSummaryTableQuery = (): ExtendedQueryResultSummaryTable => {
  const [getPatientAppointments, { data, error, loading, called, variables }] = useLazyQuery<
    PatientRetrieveAppointmentsQuerySummaryTable,
    PatientRetrieveAppointmentsQuerySummaryTableVariables
  >(PATIENT_APPOINTMENTS_SUMMARY_TABLE, {
    onError: () => void 0,
    fetchPolicy: "no-cache",
  });

  return { getPatientAppointments, data, error, loading, called, variables };
};

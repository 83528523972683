import {
  addToCartDraft,
  EyeType,
  LensType,
  ListContactsPrescriptions_listContactsPrescriptions,
  ListContactsPrescriptions_listContactsPrescriptions_optometrist,
  ListGlassesPrescriptions_listGlassesPrescriptions,
  ListGlassesPrescriptions_listGlassesPrescriptions_optometrist,
  StoreOrderByNumber_storeOrderByNumber_lineItems_customFields,
} from "./generated";
import { CATEGORY_SLUG, RecursivePartial, SelectedProductItemToAdd } from "./shared";

export enum LENS_BUILDER_STEP {
  SERVICE_TYPE = 0,
  SEARCH_PRODUCT = 1,
  ENTER_PRESCRIPTION = 2,
  SELECT_EYE_OPTION = 3,
  LENS_TYPE = 4,
  LENS_TREATMENT = 5,
  LENS_REVIEW = 6,
  ADD_ON = 7,
  MEASUREMENT = 8,
  REVIEW = 9,
}

export enum LensBuilderStepType {
  NEXT = "next",
  BACK = "back",
}
export interface LensThicknessOption {
  name: string;
  nameShort?: string;
  image: string;
  lensIndex: number;
  description: string;
  type: string;
  price: {
    amount: number;
    currencyCode: string;
  };
}

export interface AddOn {
  name: string;
  sku: string;
  supplyChannelKey: string;
  price: {
    amount: number;
    currencyCode: string;
  };
  lineItemId?: string;
}

export interface EyeOption {
  image: string;
  name: string;
  type: EYE_OPTION_TYPE;
  description: string;
}

export enum EYE_OPTION_TYPE {
  LEFT_ONLY = "left_only",
  RIGHT_ONLY = "right_only",
  BOTH = "both",
}

export type CartMeasurement = Pick<addToCartDraft, "nearpd" | "height" | "pd" | "bvd" | "pantoscopic_tilt" | "frame_wrap">;

export type LensMeasurment = {
  osHeight?: string;
  odHeight?: string;
  osPd?: string;
  odPD?: string;
  osNearPd?: string;
  odNearPd?: string;
  bvd?: string;
  pantoscopic_tilt: string;
  frame_wrap: string;
};

export type ContactsPrescription = ListContactsPrescriptions_listContactsPrescriptions;
export type GlassesPrescription = ListGlassesPrescriptions_listGlassesPrescriptions;
export type LineItemPrescription = StoreOrderByNumber_storeOrderByNumber_lineItems_customFields;

export type PrescriptionOptometrist =
  | ListGlassesPrescriptions_listGlassesPrescriptions_optometrist
  | ListContactsPrescriptions_listContactsPrescriptions_optometrist;

export interface OverlappingPrescription extends GlassesPrescription {
  osSku: string | null;
  odSku: string | null;
  odSupplyChannelsKey: string | null;
  osSupplyChannelsKey: string | null;
  odPower: number | null;
  osPower: number | null;
  practiceName: string | null;
  odGenericAdd?: string | null;
  osGenericAdd?: string | null;
}

export enum PRODUCT_TYPE {
  OPTICAL = "optical",
  SUNGLASSES = "sunglasses",
}

export enum PRESCRIPTION_OPTION_TYPE {
  ENTER_SCRIPT = "enter-script",
  INVALID_SCRIPT = "invalid-script",
  PLANO = "plano",
}

export interface TreatmentOption {
  image: string;
  name: string;
  nameShort?: string;
  type: string;
  description: string;
  productType: PRODUCT_TYPE[];
}

export type PrescriptionLensType =
  | "Relax"
  | "Bifocal"
  | "Single Vision"
  | "Office"
  | "Plano"
  | "Premium Multifocal"
  | "Everyday Multifocal"
  | "Ultra Multifocal"
  | "Office Low"
  | "Office High"
  | "Stellest";

export interface PrescriptionTypeOption {
  header: string;
  short?: string;
  image: string;
  type: PrescriptionLensType;
  lensType: LensType | null;
  extra?: string;
  requiredFields?: string[]; // prescription fields that are required for this lens type
}

export interface PrescriptionSource {
  name: string;
  type: string;
  image: string;
  description: string;
}

export type ServiceOption = {
  slug: CATEGORY_SLUG;
  displayName: string;
  displayNameV2?: string;
  steps: LENS_BUILDER_STEP[];
  requiresPatient?: boolean;
  quote?: boolean;
};

export type LensEyeDetail = {
  color?: string;
  thickness?: LensThicknessOption;
  treatment?: TreatmentOption;
  prescriptionLensType?: PrescriptionLensType;
  prescriptionLensExtra?: string;
  variantName?: string;
  variantPrice?: number;
};

export interface LensEye extends SelectedProductItemToAdd {
  detail: LensEyeDetail;
}

export type SelectedLens = Record<EyeType, LensEye>;

export type LensPayload = RecursivePartial<LensEye>;

export interface EyeLensPayload extends LensPayload {
  eye: EyeType;
}

export type AddItemPayload = { [EyeType.LEFT_OS]: Partial<addToCartDraft>; [EyeType.RIGHT_OD]: Partial<addToCartDraft> };

export type CartDemoLensPayload = {
  demoLensInCart: boolean;
  cartHasItems: boolean;
};

import { useMemo } from "react";
import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { DistributionChannels, DistributionChannelsVariables } from "../../types";
import { getCountryCode } from "../../utils";

export const DISTRIBUTION_CHANNELS = gql`
  query DistributionChannels($distributionChannelsCountry: String!) {
    distributionChannels(country: $distributionChannelsCountry) {
      key
      id
      name
      timezone
      email
      address {
        streetName
        streetNumber
        postalCode
        city
        region
        state
        country
      }
      latitude
      longitude
      storeMigrated30
      storeMigratedPOS
      peakAppointmentTimes
    }
  }
`;

export const useDistributionChannelsQuery = (distributionChannelsCountry: string): QueryResult<DistributionChannels, DistributionChannelsVariables> =>
  useQuery<DistributionChannels, DistributionChannelsVariables>(DISTRIBUTION_CHANNELS, {
    variables: { distributionChannelsCountry },
    fetchPolicy: "no-cache",
    onError: () => void 0,
  });

interface ExtendedQueryResult extends Pick<QueryResult<DistributionChannels>, "data" | "loading" | "error" | "variables" | "called"> {
  getDistributionChannels: LazyQueryExecFunction<DistributionChannels, DistributionChannelsVariables>;
}

export const useDistributionChannelsLazyQuery = (handleOnComplete?: (data: DistributionChannels) => void): ExtendedQueryResult => {
  const distributionChannelsCountry = useMemo(() => getCountryCode().toUpperCase(), []);
  const [getDistributionChannels, { data, error, loading, called, variables }] = useLazyQuery<DistributionChannels, DistributionChannelsVariables>(
    DISTRIBUTION_CHANNELS,
    {
      variables: { distributionChannelsCountry },
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
      onError: () => void 0,
    },
  );

  return {
    getDistributionChannels,
    data,
    error,
    loading,
    called,
    variables,
  };
};

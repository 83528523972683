import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { CustomerAddressInput, PosUpdateCustomerAddress, PosUpdateCustomerAddressVariables } from "../../types";
import { ADDRESS_FIELDS } from "../fragments";
import { useCallback } from "react";

export const UPDATE_CUSTOMER_ADDRESS = gql`
  ${ADDRESS_FIELDS}
  mutation PosUpdateCustomerAddress($addressRequest: CustomerAddressInput!) {
    updateCustomerAddress(addressRequest: $addressRequest) {
      addresses {
        ...AddressFields
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updateCustomerAddress: (addressRequest: CustomerAddressInput) => Promise<FetchResult<PosUpdateCustomerAddress>>;
}

export const useUpdateCustomerAddressMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosUpdateCustomerAddress, PosUpdateCustomerAddressVariables>(
    UPDATE_CUSTOMER_ADDRESS,
    {
      onError: () => void 0,
    },
  );

  const updateCustomerAddress = useCallback(
    (addressRequest: CustomerAddressInput) => {
      const variables: PosUpdateCustomerAddressVariables = {
        addressRequest: {
          ...addressRequest,
        },
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateCustomerAddress, data, error, loading, called };
};

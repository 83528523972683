import { HealthFundRefundStatus, RefundedItem, RefundType } from "../types";

export const refundTypeToString = (type: RefundType | undefined | null): string => {
  switch (type) {
    case RefundType.ClaimAdjustment:
      return "Claim Adjustment";
    case RefundType.FullRefund:
      return "Full Refund";
    case RefundType.PartialRefund:
      return "Partial Refund";
    default:
      return "";
  }
};

export const refundStatusToString = (status: HealthFundRefundStatus | undefined | null): string => {
  if (status)
    switch (status) {
      case HealthFundRefundStatus.PendingFinanceTransfer:
        return "Pending Finance Transfer";
      case HealthFundRefundStatus.PendingHealthFundConfirmation:
        return "Pending Health Fund Confirmation";
      default:
        return status.toString();
    }
  return "";
};

//we treat the defence health the same way as health fund for return/refund
export const isHF = (paymentMethodString: string | null | undefined): boolean => {
  if (!paymentMethodString) {
    return false;
  }

  return ["Health Fund", "Defence Health", "ACT Concession Scheme", "NT Concession Scheme"].includes(paymentMethodString);
};

export const isAllowReturnToFaultyChannel = (items: RefundedItem[]): boolean => {
  const types = items.map((item) => item.type);
  return types.includes("frame") || types.includes("accessories");
};

export const validateRefundItems = (items: RefundedItem[]): boolean => {
  const result = items.map((item) => {
    //every refund item needs to have refund reason,
    if (item.refundReason.length > 0) {
      if (item.isMoveToFaultyChannel) {
        //every refund item that goes to faulty channel needs to have both faultyReason and faultyComment
        return item?.faultyReason && item?.faultyComment;
      }
      return true;
    }
    return false;
  });
  return result.length > 0 ? result.every((item) => item) : false;
};

export const updateRefundItem = (currentItems: RefundedItem[], newItem: RefundedItem): RefundedItem[] => {
  if (currentItems.find((item) => item.id[0] === newItem.id[0])) {
    return currentItems.map((item) => {
      if (item.id[0] === newItem.id[0]) {
        return newItem; // update existing refund item
      }
      return item;
    });
  } else {
    return [...currentItems, newItem];
  }
};

//if idsLength is greater than the quantity, means it includes ids for BASEMATERIAL
export const getQuantityByIds = (quantity: number, idsLength: number): number => {
  if (quantity < idsLength) {
    return (quantity / idsLength) * 2;
  }
  return quantity / idsLength;
};

export const processRefundComment = (reasonCode: string, customComment?: string): string => {
  const parts = reasonCode.split(/\s?-\s?/);
  const code = parts[0];
  const comment = customComment || parts[1] || "";
  const refundComment = comment ? `${code} - ${comment}` : code;
  return refundComment;
};

export const getRefundComment = (refundedItem: RefundedItem): string => {
  return processRefundComment(refundedItem.refundReason, refundedItem.comment);
};

import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { StoreOrders, StoreOrdersTable, StoreOrdersVariables } from "../../types";
import { ORDER_FIELD, ORDER_ROW } from "../fragments";

export const STORE_ORDERS = gql`
  ${ORDER_FIELD}
  query StoreOrders($storeOrdersRequest: StoreOrdersRequest!) {
    storeOrders(storeOrdersRequest: $storeOrdersRequest) {
      ...OrderField
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<StoreOrders>, "data" | "loading" | "error" | "called"> {
  getOrder: LazyQueryExecFunction<StoreOrders, StoreOrdersVariables>;
  called: boolean;
}

export const useStoreOrdersQuery = (): ExtendedQueryResult => {
  const [getOrder, { data, error, loading, called }] = useLazyQuery<StoreOrders, StoreOrdersVariables>(STORE_ORDERS, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  return {
    getOrder,
    data,
    error,
    loading,
    called,
  };
};

export const STORE_ORDERS_TABLE = gql`
  ${ORDER_ROW}
  query StoreOrdersTable($storeOrdersRequest: StoreOrdersRequest!) {
    storeOrders(storeOrdersRequest: $storeOrdersRequest) {
      ...OrderRow
    }
  }
`;

interface ExtendedQueryTableResult extends Pick<QueryResult<StoreOrdersTable, StoreOrdersVariables>, "data" | "loading" | "error" | "called"> {
  getOrder: LazyQueryExecFunction<StoreOrdersTable, StoreOrdersVariables>;
  called: boolean;
}

export const useStoreOrdersTableQuery = (): ExtendedQueryTableResult => {
  const [getOrder, { data, error, loading, called }] = useLazyQuery<StoreOrdersTable, StoreOrdersVariables>(STORE_ORDERS_TABLE, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  return {
    getOrder,
    data,
    error,
    loading,
    called,
  };
};

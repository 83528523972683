import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { ReadConfig, ReadConfigVariables } from "../../types";

export const READ_CONFIG = gql`
  query ReadConfig($type: ConfigType!) {
    readConfig(type: $type)
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<ReadConfig>, "data" | "loading" | "error" | "refetch" | "called"> {
  readConfig: LazyQueryExecFunction<ReadConfig, ReadConfigVariables>;
}
export const useReadConfig = (): ExtendedQueryResult => {
  const [readConfig, { data, error, loading, called, refetch }] = useLazyQuery<ReadConfig, ReadConfigVariables>(READ_CONFIG, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  return {
    readConfig,
    data,
    error,
    loading,
    called,
    refetch,
  };
};

import { CountryCode, parsePhoneNumberWithError } from "libphonenumber-js";
import { AnyObject, TestConfig, TestContext } from "yup";

import { getCountryCode, toE164 } from "./helpers";
import { validatePhoneNumber } from "./patient";

export const phoneTestValidator: TestConfig<string | undefined, AnyObject> = {
  name: "phone",
  skipAbsent: true,
  test: (value: string | undefined, ctx: TestContext) => {
    const countryCode = getCountryCode().toUpperCase();
    if (!value) {
      return true;
    }
    const parsedNumber = toE164(value, countryCode);
    const isValid = validatePhoneNumber(parsedNumber);
    if (isValid) {
      return true;
    }
    return ctx.createError({ message: "Invalid phone format" });
  },
};

/**
 * a function to format mobile phone numbers
 * input: +xx012345678
 * output: +xx 012 345 678
 *
 */
export const formatPhoneNumber = (value: string | null | undefined, countryCode = "AU"): string => {
  if (!value) {
    return "";
  }

  const systemCountryCode = getCountryCode().toUpperCase() || countryCode;

  try {
    return parsePhoneNumberWithError(value as string, systemCountryCode as CountryCode).formatInternational();
  } catch (e) {
    return value;
  }
};

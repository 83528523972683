import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import React, { FC, FormEventHandler, useCallback, useEffect, useMemo, useState } from "react";

import { useGetStaffToken } from "../../data/mutations";
import { StaffToken } from "./useStaffToken";

export type PinModalProps = {
  open: boolean;
  onVerified: (token: StaffToken | null) => void;
};

const PinModal: FC<PinModalProps> = ({ open, onVerified }) => {
  const [pin, setPin] = useState("");
  const { getStaffToken, loading, error } = useGetStaffToken();

  const isSubmitDisabled = useMemo(() => {
    return loading || !pin || pin.length < 3;
  }, [pin, loading]);

  useEffect(() => {
    if (open) {
      setPin("");
    }
  }, [open]);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      getStaffToken(pin)
        .then((res) => {
          const staffToken = res.data?.staffToken;
          if (staffToken) {
            const { access_token, expiresAt, firstName, lastName } = staffToken;
            onVerified({
              accessToken: access_token || "",
              expiresAt: expiresAt || "",
              firstName: firstName || "",
              lastName: lastName || "",
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        })
        .finally(() => {
          setPin("");
        });
      return false;
    },
    [onVerified, pin, getStaffToken],
  );

  return (
    <Backdrop open={open} sx={{ backdropFilter: "blur(10px)", zIndex: 100000 }}>
      <Dialog open={open} maxWidth="sm" fullWidth sx={{ zIndex: 100001 }} data-testid="modal-pin">
        <form noValidate onSubmit={onSubmit}>
          <DialogTitle>Please enter your PIN</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ marginTop: 3 }}>
              <TextField
                value={pin}
                onChange={(e) => {
                  setPin(e.target.value);
                }}
                type="password"
                fullWidth
                label="Enter your PIN"
                error={!!error}
                helperText={error?.message}
                disabled={loading}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitDisabled}
              size="large"
              fullWidth
              startIcon={loading ? <CircularProgress size={18} /> : null}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Backdrop>
  );
};

export default PinModal;

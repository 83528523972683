import styled from "@emotion/styled";
import { ExitToAppOutlined } from "@mui/icons-material";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FC, useCallback, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import GlobalSearch from "../components/GlobalSearch";
import { SmallButton } from "../components/LensBuilder/useStyles";
import StaffContext from "../components/Staff/StaffContext";
import StoreSelect from "../components/StoreSelect";
import { COLOURS, PATHS, SIZES } from "../constants";
import { setShowCalendarNote } from "../state/appointments";
import { selectActiveDistributionChannel, setActiveDistributionChannel } from "../state/authentication";
import { useAppDispatch, useAppSelector } from "../state/hooks";
import { DistributionChannels_distributionChannels } from "../types";
import { getCountryCode } from "../utils";

const HeaderContainer = styled.div`
  height: ${SIZES.HEADER_HEIGHT}rem;
  width: 100%;
  background-color: ${COLOURS.LIGHT_GREY_HEADER};
  z-index: 99;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Header: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const activeDistributionChannel = useAppSelector(selectActiveDistributionChannel) as DistributionChannels_distributionChannels | undefined;
  const { staffPINAuth } = useFlags();
  const { staffToken, logout } = useContext(StaffContext);

  const handleNewPatientClicked = useCallback((): void => {
    navigate(PATHS.NEW_PATIENT);
  }, [navigate]);

  const handleStoreSelect = useCallback(
    (store: DistributionChannels_distributionChannels): void => {
      const pathMatch = pathname.split("/")[1] === PATHS.APPOINTMENTS.split("/")[1];
      if (pathMatch && staffToken?.accessToken) {
        dispatch(setShowCalendarNote(true));
      }
      dispatch(setActiveDistributionChannel(store));
    },
    [dispatch, pathname, staffToken],
  );

  const staffName = useMemo(() => {
    if (staffPINAuth && staffToken) {
      return `${staffToken?.firstName} ${staffToken?.lastName?.[0]}`;
    }
    return `Bailey Nelson ${getCountryCode()?.toUpperCase()}`;
  }, [staffPINAuth, staffToken]);

  return (
    <HeaderContainer>
      <Grid container spacing={1} sx={{ justifyContent: "flex-end", alignItems: "center", flexWrap: "nowrap", paddingRight: "1rem" }}>
        <Grid item sx={{ flex: 1 }}>
          {staffPINAuth ? (
            <Stack paddingLeft={1} direction={"row"} spacing={1} alignItems={"center"}>
              <Button variant="contained" onClick={logout} size="small" data-testid="btn-logout" startIcon={<ExitToAppOutlined />}>
                Logout
              </Button>
              <Typography fontWeight={"bold"}>{staffName}</Typography>
            </Stack>
          ) : null}
        </Grid>
        <Grid item sx={{ width: "16rem" }}>
          <StoreSelect onSelect={handleStoreSelect} selected={activeDistributionChannel} authStoreFilter />
        </Grid>
        <Grid item sx={{ width: "27rem" }}>
          <GlobalSearch />
        </Grid>
        <Grid item sx={{ width: "11rem" }}>
          <SmallButton
            variant="contained"
            size="small"
            onClick={handleNewPatientClicked}
            sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "100%", display: "block", lineHeight: "100%" }}
          >
            add new patient
          </SmallButton>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
};

export default Header;

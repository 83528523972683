import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PartialOrderRefundRequest, PosPartialOrderRefund, PosPartialOrderRefundVariables } from "../../types";
import { ORDER_FIELD } from "../fragments";
import { useCallback } from "react";

//only change status of the line items, doesn't refund the payment
export const REFUND_LINE_ITEM = gql`
  ${ORDER_FIELD}
  mutation PosPartialOrderRefund($partialOrderRefundRequest: PartialOrderRefundRequest!) {
    partialOrderRefund(partialOrderRefundRequest: $partialOrderRefundRequest) {
      ...OrderField
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  partialRefundOrder: (partialOrderRefundRequest: PartialOrderRefundRequest) => Promise<FetchResult<PosPartialOrderRefund>>;
}

export const usePartialRefundOrderMutation = (handleOnComplete?: (data: PosPartialOrderRefund) => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosPartialOrderRefund, PosPartialOrderRefundVariables>(REFUND_LINE_ITEM, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const partialRefundOrder = useCallback(
    (partialOrderRefundRequest: PartialOrderRefundRequest) => {
      const variables: PosPartialOrderRefundVariables = {
        partialOrderRefundRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { partialRefundOrder, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { CART_FIELDS } from "../../data/fragments";
import { selectActiveDistributionChannelKey } from "../../state/authentication";
import { selectOrderItems } from "../../state/contactLens";
import { useAppSelector } from "../../state/hooks";
import { selectAddToCartDraftItems, selectOrderInvoiceOnly } from "../../state/lensBuilder";
import { AddBundleToCartMutation, AddBundleToCartMutationVariables, addToCartDraft } from "../../types";
import { nullishStringify } from "../../utils";
import { useCallback, useMemo } from "react";

export const ADD_BUNDLE_TO_CART = gql`
  ${CART_FIELDS}
  mutation AddBundleToCartMutation($addBundleToCartAddBundleToCartRequest: addBundleToCartDraft!) {
    addBundleToCart(addBundleToCartRequest: $addBundleToCartAddBundleToCartRequest) {
      ...CartFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  addBundleToCartMutation: () => Promise<FetchResult<AddBundleToCartMutation>>;
}

export const useAddContactLensBundleToCart = (): ExtendedMutation => {
  const itemsToAdd = useAppSelector(selectOrderItems);
  const distributionChannelKey = useAppSelector(selectActiveDistributionChannelKey);

  const withDC = itemsToAdd.map(nullishStringify).map(({ supplyChannelKey, frequency, ...rest }: addToCartDraft) => ({
    ...rest,
    ...(supplyChannelKey ? { supplyChannelKey } : {}),
    ...(frequency ? { frequency } : {}), // middleware will die if we send a non int frequency
    distributionChannelKey,
  }));

  const addBundleToCartVariable: AddBundleToCartMutationVariables = useMemo(
    () => ({
      addBundleToCartAddBundleToCartRequest: {
        itemsToAdd: withDC,
      },
    }),
    [withDC],
  );

  const [mutate, { data, error, loading, called }] = useMutation<AddBundleToCartMutation, AddBundleToCartMutationVariables>(ADD_BUNDLE_TO_CART, {
    onError: () => void 0,
  });

  const addBundleToCartMutation = useCallback(() => {
    return mutate({
      variables: addBundleToCartVariable,
    });
  }, [addBundleToCartVariable, mutate]);

  return { addBundleToCartMutation, data, error, loading, called };
};

export const useAddDispensingBundleToCart = (
  handleOnComplete?: (data: AddBundleToCartMutation) => void,
  enableNewPricing?: boolean,
): ExtendedMutation => {
  const itemsToAdd = useAppSelector(selectAddToCartDraftItems);
  // this dc is where the sale is attributed to
  const distributionChannelKey = useAppSelector(selectActiveDistributionChannelKey);
  const validItems = useMemo(() => itemsToAdd.filter((item) => item.quantity > 0 && !!item.sku), [itemsToAdd]);
  const invoiceOnlyOrder = useAppSelector(selectOrderInvoiceOnly);
  // middleware interprets some fields with empty strings as undefined and some others will throw errors
  const withDC = useMemo(
    () =>
      validItems.map(nullishStringify).map(({ frequency, supplyChannelKey, eye, lensSKUs, ...rest }: addToCartDraft) => ({
        ...rest,
        ...(supplyChannelKey ? { supplyChannelKey } : {}),
        ...(distributionChannelKey ? { distributionChannelKey } : {}),
        ...(frequency ? { frequency } : {}),
        ...(eye ? { eye } : {}),
        ...(enableNewPricing && lensSKUs ? { lensSKUs } : {}),
        ...{ invoiceOnlyOrder },
      })),
    [distributionChannelKey, enableNewPricing, invoiceOnlyOrder, validItems],
  );

  const addBundleToCartVariable: AddBundleToCartMutationVariables = useMemo(
    () => ({
      addBundleToCartAddBundleToCartRequest: {
        itemsToAdd: withDC,
      },
    }),
    [withDC],
  );

  const [mutate, { data, error, loading, called }] = useMutation<AddBundleToCartMutation, AddBundleToCartMutationVariables>(ADD_BUNDLE_TO_CART, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const addBundleToCartMutation = useCallback(() => {
    return mutate({
      variables: addBundleToCartVariable,
    });
  }, [addBundleToCartVariable, mutate]);

  return { addBundleToCartMutation, data, error, loading, called };
};

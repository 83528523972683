import * as Sentry from "@sentry/react";
import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import React, { FC, useEffect } from "react";
import jwt_decode from "jwt-decode";

import { AUTH_KEYS, StoreTokenInfo } from "../../types";
import { useAppSelector } from "../../state/hooks";
import { selectStoreAuthenticated } from "../../state/authentication";

const LAUNCHDARKLY_CLIENT_ID: string = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID || "";

type Props = {
  children?: React.ReactNode;
};

const ldUser = {
  key: "anonymous",
  name: "",
  email: "",
  custom: {
    storeCode: process.env.REACT_APP_STORE_COUNTRY_CODE ?? "no-store-code-provided",
  },
};

const storeAuthToken = sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_TOKEN);

if (storeAuthToken) {
  try {
    const decoded: StoreTokenInfo = jwt_decode(storeAuthToken);
    if (decoded.email) {
      localStorage.setItem(AUTH_KEYS.LD_USER_KEY, decoded.email);
      ldUser.key = decoded.email || "";
      ldUser.name = decoded.name || "";
      ldUser.email = decoded.email || "";
    }
  } catch {
    Sentry.captureMessage("Error decoding store token from browser storage, launch darkly init");
  }
}

if (!LAUNCHDARKLY_CLIENT_ID) {
  Sentry.captureMessage("REACT_APP_LAUNCHDARKLY_CLIENT_ID env var not set, launch darkly init");
}

// const returnLDProvider = !!LAUNCHDARKLY_CLIENT_ID && !!ldUser?.key;

const FeatureFlags: FC<Props> = ({ children }) => {
  const storeLogin = useAppSelector(selectStoreAuthenticated);
  const lDClient = useLDClient();

  useEffect(() => {
    if (storeLogin && lDClient) {
      const storeAuthToken = sessionStorage.getItem(AUTH_KEYS.STORE_AUTH_TOKEN);
      if (storeAuthToken) {
        try {
          const decoded: StoreTokenInfo = jwt_decode(storeAuthToken);
          if (decoded.email) {
            lDClient?.identify({
              kind: "user",
              key: decoded.email,
              name: decoded.name,
              email: decoded.email,
              custom: {
                storeCode: process.env.REACT_APP_STORE_COUNTRY_CODE ?? "no-store-code-provided",
              },
              anonymous: false,
            });
          }
        } catch {
          Sentry.captureMessage("Error decoding store token from browser storage, launch darkly init");
        }
      }
    } else {
      lDClient?.identify({
        kind: "user",
        key: "anonymous",
        name: "",
        email: "",
        custom: {
          storeCode: process.env.REACT_APP_STORE_COUNTRY_CODE ?? "no-store-code-provided",
        },
        anonymous: true,
      });
    }
  }, [storeLogin, lDClient]);
  return <>{children}</>;
};

const FeatureFlagWrapper = withLDProvider({
  clientSideID: LAUNCHDARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  context: {
    ...(ldUser.email ? ldUser : {}),
    anonymous: ldUser.email ? false : true,
  },
})(FeatureFlags);

export default FeatureFlagWrapper as FC<{ children: React.ReactNode }>;

import isNil from "lodash/isNil";
import { DateTime } from "luxon";

import { EyeLensesRx } from "../components/InlineDispensing/hooks";
import { PRESCRIPTION_FIELDS_EXPLICIT_POSITIVES } from "../constants";
import {
  AddItemPayload,
  ContactsPrescription,
  ContactsPrescriptionRequest,
  DataSource,
  EyeType,
  GlassesPrescription,
  GlassesPrescriptionRequest,
  OverlappingPrescription,
  RecursivePartial,
  UpdateContactsPrescriptionRequest,
  UpdateGlassesPrescriptionRequest,
} from "../types";
import { isAfter, localShortDate } from "./dateTime";
import { removeEmptyValues } from "./helpers";

export const decmialFmt = new Intl.NumberFormat("en", {
  style: "decimal",
  minimumFractionDigits: 2,
});

export const mapPrescriptionToCartItems = (prescription: RecursivePartial<OverlappingPrescription>): AddItemPayload => {
  // cart properties we want from the prescription
  const validKeys = [
    "power",
    "cyl",
    "axis",
    "add",
    "intAdd",
    "nearpd",
    "height",
    "genericAdd",
    "pd",
    "sku",
    "vPrism",
    "hPrism",
    "vPrismType",
    "hPrismType",
    "supplyChannelKey",
  ];

  return Object.entries(prescription).reduce((acc, [key, value]) => {
    const nextKey = key
      .toLowerCase()
      .replace(/^(os|od)/, "")
      .replace(/^sph/, "power")
      .replace(/^genericadd$/, "genericAdd")
      .replace(/^intadd$/, "intAdd")
      .replace(/^vprism$/, "vPrism")
      .replace(/^hprism$/, "hPrism")
      .replace(/^vprismtype/, "vPrismType")
      .replace(/^hprismtype/, "hPrismType")
      .replace(/^supplychannelskey/, "supplyChannelKey");

    if (isNil(value) || `${value}`.length === 0 || !validKeys.includes(nextKey)) {
      return acc;
    }

    const eye = key.slice(0, 2);

    const explicitPositive = PRESCRIPTION_FIELDS_EXPLICIT_POSITIVES.indexOf(key) !== -1;
    // pass through rx values like prism type strings, format other number values
    const formattedValue = isNaN(Number(value)) ? (value as string | null) : formatPrescriptionValue(Number(value), explicitPositive);
    if (eye === "os") {
      acc[EyeType.LEFT_OS as EyeType] = {
        ...(acc[EyeType.LEFT_OS as EyeType] ?? {}),
        [nextKey]: `${formattedValue}`,
      };
    }

    if (eye === "od") {
      acc[EyeType.RIGHT_OD as EyeType] = {
        ...(acc[EyeType.RIGHT_OD as EyeType] ?? {}),
        [nextKey]: `${formattedValue}`,
      };
    }

    return acc;
  }, {} as AddItemPayload);
};

/**
 * generate an array of numbers between min and max given an increment
 */
export const genNumberRange = (min: number, max: number, increment?: number): number[] => {
  const incrementValue = increment || 1;
  const range = [];
  for (let i = max; i >= min; i -= incrementValue) {
    range.push(i);
  }
  return range;
};

export const mapToPrescriptionRequest = <T = GlassesPrescriptionRequest | UpdateGlassesPrescriptionRequest>(
  formPrescription: RecursivePartial<OverlappingPrescription>,
  activePatientId: string | undefined,
  shouldRemoveEmptyValues = true,
): T => {
  const prescription = formPrescription as GlassesPrescription;
  const optometristName = prescription?.optometristName
    ? prescription.optometristName
    : prescription.optometrist
      ? `${prescription.optometrist.firstName} ${prescription.optometrist.lastName}`
      : undefined;

  const composedObj = {
    ...prescription,
    id: prescription.id || undefined,
    odSph: Number(prescription.odSph),
    odCyl: prescription?.odCyl && Number(prescription.odCyl),
    odAxis: prescription?.odAxis && Number(prescription.odAxis),
    odAdd: prescription?.odAdd && Number(prescription.odAdd),
    odIntAdd: prescription?.odIntAdd && Number(prescription.odIntAdd),
    odPd: Number(prescription.odPd),
    odNearPd: prescription?.odNearPd && Number(prescription.odNearPd),
    odHPrism: prescription?.odHPrism && Number(prescription.odHPrism),
    odVPrism: prescription?.odVPrism && Number(prescription.odVPrism),
    osSph: Number(prescription.osSph),
    osCyl: prescription?.osCyl && Number(prescription.osCyl),
    osAxis: prescription?.osAxis && Number(prescription.osAxis),
    osAdd: prescription?.osAdd && Number(prescription.osAdd),
    osIntAdd: prescription?.osIntAdd && Number(prescription.osIntAdd),
    osPd: Number(prescription.osPd),
    osNearPd: prescription?.osNearPd && Number(prescription.osNearPd),
    osHPrism: prescription?.osHPrism && Number(prescription.osHPrism),
    osVPrism: prescription?.osVPrism && Number(prescription.osVPrism),
    optometristId: prescription?.optometrist?.id,
    optometristName,
    patientId: activePatientId,
    practiceName: prescription?.practiceName,
    recommendations: prescription?.recommendations,
    practiceNotes: prescription?.practiceNotes,
  };

  const { expiresDate, patientId, ...optional } = shouldRemoveEmptyValues ? removeEmptyValues(composedObj) : composedObj;

  const issueDate = optional?.issueDate ? localShortDate(new Date(optional.issueDate)) : undefined;

  return {
    ...optional,
    ...(issueDate ? { issueDate } : {}),
    ...(typeof patientId === "undefined" ? {} : { patientId }),
    expiresDate: localShortDate(new Date(expiresDate)),
  } as unknown as T;
};

export const mapToContactsPrescriptionRequest = <T = ContactsPrescriptionRequest | UpdateContactsPrescriptionRequest>(
  formPrescription: RecursivePartial<OverlappingPrescription>,
  activePatientId: string | undefined,
  shouldRemoveEmptyValues = true,
): T => {
  const prescription = formPrescription as ContactsPrescription;
  const optometristName = prescription?.optometristName
    ? prescription.optometristName
    : prescription.optometrist
      ? `${prescription.optometrist.firstName} ${prescription.optometrist.lastName}`
      : undefined;

  const composedObj = {
    ...prescription,
    id: prescription.id || undefined,
    odPower: prescription?.odSku && prescription?.odPower ? Number(prescription.odPower) : null,
    odCyl: prescription?.odCyl && Number(prescription.odCyl),
    odAxis: prescription?.odAxis && Number(prescription.odAxis),
    odAdd: prescription?.odAdd && Number(prescription.odAdd),
    osPower: prescription?.osSku && prescription?.osPower ? Number(prescription.osPower) : null,
    osCyl: prescription?.osCyl && Number(prescription.osCyl),
    osAxis: prescription?.osAxis && Number(prescription.osAxis),
    osAdd: prescription?.osAdd && Number(prescription.osAdd),
    optometristId: prescription?.optometrist?.id,
    optometristName,
    patientId: activePatientId,
    practiceName: prescription?.practiceName,
    recommendations: prescription?.recommendations,
    practiceNotes: prescription?.practiceNotes,
  };

  const { expiresDate, patientId, ...optional } = shouldRemoveEmptyValues ? removeEmptyValues(composedObj) : composedObj;

  const issueDate = optional?.issueDate ? localShortDate(new Date(optional.issueDate)) : undefined;

  return {
    ...optional,
    ...(issueDate ? { issueDate } : {}),
    ...(typeof patientId === "undefined" ? {} : { patientId }),
    expiresDate: localShortDate(new Date(expiresDate)),
  } as unknown as T;
};

type ValidationResponse = {
  isValid: boolean;
  errorMessage: string;
};

export const prescriptionValidation = (
  name: keyof OverlappingPrescription,
  value: string | null,
  options?: string[],
  formatted?: string,
): ValidationResponse => {
  const validationName = name.toLowerCase().replace(/^(os|od)/, "");
  const valueNumber = Number(value?.replace(/[+]/g, ""));

  if (isValidGenericAddValue(value ?? "") && (name === "odAdd" || name === "osAdd")) {
    return { isValid: true, errorMessage: "" };
  }

  if (value) {
    switch (validationName) {
      case "axis": {
        const isValid = !isNaN(valueNumber) && valueNumber >= 1 && valueNumber <= 180;
        return {
          isValid,
          errorMessage: isValid ? "" : "Axis must be a number between 1 and 180",
        };
      }

      // differs from api validation which is >= 1 && <= 100
      case "pd": {
        const isValid = !isNaN(valueNumber) && valueNumber >= 10 && valueNumber <= 40;
        return {
          isValid,
          errorMessage: isValid ? "" : "PD must be a number between 10 and 40",
        };
      }

      case "nearpd": {
        const isValid = !isNaN(valueNumber) && valueNumber >= 10 && valueNumber <= 40;
        return {
          isValid,
          errorMessage: isValid ? "" : "Near PD must be a number between 10 and 40",
        };
      }

      default: {
        if (options && formatted && options.length > 0 && !options.includes(formatted)) {
          return { isValid: false, errorMessage: "Select from available values" };
        } else {
          return { isValid: true, errorMessage: "" };
        }
      }
    }
  } else {
    switch (validationName) {
      case "sph":
      case "power": {
        return { isValid: false, errorMessage: "Sphere is required" };
      }
      default: {
        return { isValid: true, errorMessage: "" };
      }
    }
  }
  // return { isValid: true, errorMessage: "" };
};

/**
 * @param value if value is not NaN, format its string rep. to two decimal places
 * @param explicitPositive if true return string rep. of positive number with a + prefix
 */
export const formatPrescriptionValue = (
  value?: string | number | null,
  explicitPositive?: boolean,
  blankStr?: string,
  showBlankIfZero?: boolean,
  isInteger?: boolean,
): string => {
  if (value !== "" && !isNil(value) && !isNaN(Number(value))) {
    const [first, second = "00"] = Number(value).toString().replace(/[+-]/g, "").split(".");
    if (Number(value) === 0 && showBlankIfZero) {
      return blankStr ?? "-";
    }

    if (isInteger) {
      return `${Number(value) > 0 ? `${explicitPositive ? "+" : ""}` : Number(value) < 0 ? "-" : ""}${first || "0"}`;
    }

    return `${Number(value) > 0 ? `${explicitPositive ? "+" : ""}` : Number(value) < 0 ? "-" : ""}${first || "0"}.${second.concat("00").slice(0, 2)}`;
  }
  return blankStr ?? "-";
};

export const formatDecimalNumber = (value?: string | number | null, fractionDigits = 1): string => {
  if (!isNil(value) && !isNaN(Number(value)) && value !== "") {
    return Number(value).toFixed(fractionDigits);
  }
  return "";
};

export const isExpiredPrescription = (expiresDate: string | null): boolean =>
  expiresDate ? isAfter(DateTime.local().toJSDate(), DateTime.fromISO(expiresDate).toJSDate()) : true;

export const isValidGenericAddValue = (value: string): boolean => ["High", "Medium", "Low"].includes(value);

export const lensePrescriptionToOverlappingPrescription = (rx: EyeLensesRx): OverlappingPrescription => {
  const parseNumber = (value: string | null | undefined): number | null => {
    if (value !== "" && value !== undefined && value !== null) {
      return Number(value.replace(/[+]/g, ""));
    }
    return null;
  };
  const prescription: OverlappingPrescription = {
    odSph: parseNumber(rx[EyeType.RIGHT_OD]?.sphere) as number,
    odCyl: parseNumber(rx[EyeType.RIGHT_OD]?.cyl),
    odAxis: parseNumber(rx[EyeType.RIGHT_OD]?.axis),
    odAdd: parseNumber(rx[EyeType.RIGHT_OD]?.add),
    odPower: parseNumber(rx[EyeType.RIGHT_OD]?.power),
    odNearPd: parseNumber(rx[EyeType.RIGHT_OD]?.nearpd),
    odPd: parseNumber(rx[EyeType.RIGHT_OD]?.pd),
    odIntAdd: parseNumber(rx[EyeType.RIGHT_OD]?.intAdd),
    odHPrism: parseNumber(rx[EyeType.RIGHT_OD]?.hPrism),
    odVPrism: parseNumber(rx[EyeType.RIGHT_OD]?.vPrism),
    odHPrismType: rx[EyeType.RIGHT_OD]?.hPrismType || null,
    odVPrismType: rx[EyeType.RIGHT_OD]?.vPrismType || null,
    odGenericAdd: rx[EyeType.RIGHT_OD]?.genericAdd || null,
    odSku: rx[EyeType.RIGHT_OD]?.sku || "",

    osSph: parseNumber(rx[EyeType.LEFT_OS]?.sphere) as number,
    osCyl: parseNumber(rx[EyeType.LEFT_OS]?.cyl),
    osAxis: parseNumber(rx[EyeType.LEFT_OS]?.axis),
    osAdd: parseNumber(rx[EyeType.LEFT_OS]?.add),
    osPower: parseNumber(rx[EyeType.LEFT_OS]?.power),
    osNearPd: parseNumber(rx[EyeType.LEFT_OS]?.nearpd),
    osPd: parseNumber(rx[EyeType.LEFT_OS]?.pd),
    osIntAdd: parseNumber(rx[EyeType.LEFT_OS]?.intAdd),
    osHPrism: parseNumber(rx[EyeType.LEFT_OS]?.hPrism),
    osVPrism: parseNumber(rx[EyeType.LEFT_OS]?.vPrism),
    osHPrismType: rx[EyeType.LEFT_OS]?.hPrismType || null,
    osVPrismType: rx[EyeType.LEFT_OS]?.vPrismType || null,
    osGenericAdd: rx[EyeType.LEFT_OS]?.genericAdd || null,
    osSku: rx[EyeType.LEFT_OS]?.sku || "",

    odSupplyChannelsKey: null,
    osSupplyChannelsKey: null,
    practiceName: null,
    id: "",
    optometristName: null,
    issueDate: null,
    expiresDate: "",
    optometrist: null,
    recommendations: null,
    practiceNotes: null,
    downloadUrl: null,
    source: DataSource.INTERNAL,
    attachments: [],
  };

  return prescription;
};

export const overlappingPrescritionToLensePrescription = (prescription: OverlappingPrescription | null | undefined): EyeLensesRx => {
  return {
    [EyeType.RIGHT_OD]: {
      eye: EyeType.RIGHT_OD,
      sphere: prescription?.odSph?.toString() || "0",
      cyl: prescription?.odCyl?.toString() || null,
      axis: prescription?.odAxis?.toString() || null,
      add: prescription?.odAdd?.toString() || null,
      power: prescription?.odPower?.toString() || null,
      nearpd: prescription?.odNearPd?.toString() || null,
      pd: prescription?.odPd?.toString() || null,
      intAdd: prescription?.odIntAdd?.toString() || null,
      hPrism: prescription?.odHPrism?.toString() || null,
      vPrism: prescription?.odVPrism?.toString() || null,
      hPrismType: prescription?.odHPrismType || null,
      vPrismType: prescription?.odVPrismType || null,
      genericAdd: prescription?.odGenericAdd || null,
      sku: prescription?.odSku,
      height: null,
    },
    [EyeType.LEFT_OS]: {
      eye: EyeType.LEFT_OS,
      sphere: prescription?.osSph?.toString() || "0",
      cyl: prescription?.osCyl?.toString() || null,
      axis: prescription?.osAxis?.toString() || null,
      add: prescription?.osAdd?.toString() || null,
      power: prescription?.osPower?.toString() || null,
      nearpd: prescription?.osNearPd?.toString() || null,
      pd: prescription?.osPd?.toString() || null,
      intAdd: prescription?.osIntAdd?.toString() || null,
      hPrism: prescription?.osHPrism?.toString() || null,
      vPrism: prescription?.osVPrism?.toString() || null,
      hPrismType: prescription?.osHPrismType || null,
      vPrismType: prescription?.osVPrismType || null,
      genericAdd: prescription?.osGenericAdd || null,
      sku: prescription?.osSku,
      height: null,
    },
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { UpdateContactsPrescription, UpdateContactsPrescriptionRequest, UpdateContactsPrescriptionVariables } from "../../types";
import { CONTACTS_PRESCRIPTION_FIELDS } from "../fragments";
import { useCallback } from "react";

export const UPDATE_CONTACTS_PRESCRIPTION = gql`
  ${CONTACTS_PRESCRIPTION_FIELDS}
  mutation UpdateContactsPrescription($contactsPrescriptionRequest: UpdateContactsPrescriptionRequest) {
    updateContactsPrescription(updateContactsPrescriptionRequest: $contactsPrescriptionRequest) {
      ...ContactsPrescriptionFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult<UpdateContactsPrescription>, "client" | "reset"> {
  updateContactsPrescription: (contactsPrescriptionRequest: UpdateContactsPrescriptionRequest) => Promise<FetchResult<UpdateContactsPrescription>>;
}

export const useUpdateContactsPrescriptionMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<UpdateContactsPrescription, UpdateContactsPrescriptionVariables>(
    UPDATE_CONTACTS_PRESCRIPTION,
    {
      onError: () => void 0,
      notifyOnNetworkStatusChange: true,
    },
  );

  const updateContactsPrescription = useCallback(
    (contactsPrescriptionRequest: UpdateContactsPrescriptionRequest) => {
      const variables = {
        contactsPrescriptionRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateContactsPrescription, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { OrderLineItemStatesUpdate, OrderLineItemStateUpdateRequest } from "../../types";
import { useCallback } from "react";

export const ORDER_LINE_ITEM_STATES_UPDATE = gql`
  mutation OrderLineItemStatesUpdate($orderLineItemStateUpdateRequest: OrderLineItemStateUpdateRequest!) {
    orderLineItemStatesUpdate(orderLineItemStateUpdates: $orderLineItemStateUpdateRequest) {
      id
      lineItems {
        state {
          state {
            key
            id
          }
        }
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  orderLineItemStatesUpdate: (orderLineItemStateUpdateRequest: OrderLineItemStateUpdateRequest) => Promise<FetchResult<OrderLineItemStatesUpdate>>;
}

export const useOrderLineItemStatesUpdateMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation(ORDER_LINE_ITEM_STATES_UPDATE, {
    onError: () => void 0,
  });

  const orderLineItemStatesUpdate = useCallback(
    (orderLineItemStateUpdateRequest: OrderLineItemStateUpdateRequest) => {
      const variables = {
        orderLineItemStateUpdateRequest,
      };

      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { orderLineItemStatesUpdate, data, error, loading, called };
};

import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { SelectPatient, SelectPatientVariables } from "../../types";
import { PATIENT_FIELDS, PROFILE_NOTES } from "../fragments";

export const SELECT_PATIENT = gql`
  ${PATIENT_FIELDS}
  ${PROFILE_NOTES}
  query SelectPatient($posSelectPatientId: String!, $store: String!, $countryCode: String!) {
    posSelectPatient(id: $posSelectPatientId, store: $store, countryCode: $countryCode) {
      ...PatientFields
      ...ProfileNotes
    }
  }
`;

export const SELECT_PATIENT_EXTERNAL_ID = gql`
  query SelectPatientExternalId($posSelectPatientId: String!, $store: String!, $countryCode: String!) {
    posSelectPatient(id: $posSelectPatientId, store: $store, countryCode: $countryCode) {
      externalId
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<SelectPatient>, "data" | "loading" | "error" | "called"> {
  selectPatient: LazyQueryExecFunction<SelectPatient, SelectPatientVariables>;
}

export const useLazySelectPatientQuery = (): ExtendedQueryResult => {
  const [selectPatient, { data, error, loading, called }] = useLazyQuery<SelectPatient, SelectPatientVariables>(SELECT_PATIENT, {
    // these are important, do not remove
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
  });

  return {
    selectPatient,
    data,
    error,
    loading,
    called,
  };
};

import { gql, LazyQueryExecFunction, OperationVariables, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { PosRetrieveHealthFundList } from "../../types";
import { useCallback } from "react";

export const PRIVATE_HEALTH_FUNDS = gql`
  query PosRetrieveHealthFundList($countryCode: String!) {
    posRetrieveHealthFundList(countryCode: $countryCode) {
      id
      code
      name
      healthCareProviderKey
      order
    }
  }
`;

export const usePosRetrieveHealthFundListQuery = (countryCode: string): QueryResult<PosRetrieveHealthFundList> =>
  useQuery<PosRetrieveHealthFundList>(PRIVATE_HEALTH_FUNDS, {
    variables: {
      countryCode,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

export type UsePostRetrieveHealthFundListQuery = Pick<QueryResult<PosRetrieveHealthFundList>, "data" | "called" | "refetch" | "error" | "loading"> & {
  retrieveHealthFundList: LazyQueryExecFunction<PosRetrieveHealthFundList, OperationVariables>;
};

export const usePosRetrieveHealthFundListQueryLazy = (countryCode: string): UsePostRetrieveHealthFundListQuery => {
  const [query, { data, error, loading, called, refetch }] = useLazyQuery<PosRetrieveHealthFundList, OperationVariables>(PRIVATE_HEALTH_FUNDS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const retrieveHealthFundList = useCallback(() => {
    return query({
      variables: {
        countryCode: countryCode,
      },
    });
  }, [countryCode, query]);

  return {
    retrieveHealthFundList,
    data,
    error,
    loading,
    called,
    refetch,
  };
};

import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { RetrieveBulkCustomerOrder, RetrieveBulkCustomerOrderVariables } from "../../types";

export const BULK_CUSTOMER_ORDERS = gql`
  query RetrieveBulkCustomerOrder($distributionChannelKey: String!) {
    posRetrieveBulkCustomerOrders(distributionChannelKey: $distributionChannelKey) {
      distributionChannelKey
      batchNumber
      createdAt
      status
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<RetrieveBulkCustomerOrder>, "data" | "loading" | "error" | "variables" | "called"> {
  getBulkCustomerOrders: LazyQueryExecFunction<RetrieveBulkCustomerOrder, RetrieveBulkCustomerOrderVariables>;
}

export const useRetrieveBulkCustomerOrderLazyQuery = (): ExtendedQueryResult => {
  const [getBulkCustomerOrders, { data, error, loading, called, variables }] = useLazyQuery<
    RetrieveBulkCustomerOrder,
    RetrieveBulkCustomerOrderVariables
  >(BULK_CUSTOMER_ORDERS, {
    onError: () => void 0,
    fetchPolicy: "network-only",
  });

  return {
    getBulkCustomerOrders,
    data,
    error,
    loading,
    called,
    variables,
  };
};

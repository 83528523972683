import { gql, QueryResult, useLazyQuery, useQuery } from "@apollo/client";

import { PosRetrieveDiscountCodes } from "../../types";
import { useCallback } from "react";
import { getCountryCode } from "../../utils";

export const DISCOUNT_CODES = gql`
  query PosRetrieveDiscountCodes($store: String) {
    posRetrieveDiscountCodes(store: $store) {
      code
      id
    }
  }
`;

export const useDiscountCodesQuery = (): QueryResult<PosRetrieveDiscountCodes> =>
  useQuery<PosRetrieveDiscountCodes>(DISCOUNT_CODES, {
    variables: {
      store: `store-${getCountryCode()}`,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

interface ExtendedQueryResult extends Pick<QueryResult<PosRetrieveDiscountCodes>, "data" | "loading" | "error" | "refetch"> {
  getDiscountCodes: () => Promise<QueryResult<PosRetrieveDiscountCodes>>;
  called: boolean;
}

export const useDiscountCodesQueryLazy = (): ExtendedQueryResult => {
  const [query, { loading, data, error, refetch, called }] = useLazyQuery<PosRetrieveDiscountCodes>(DISCOUNT_CODES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const getDiscountCodes = useCallback(() => {
    return query({
      variables: {
        store: `store-${getCountryCode().toLowerCase()}`,
      },
    });
  }, [query]);

  return {
    loading,
    data,
    error,
    refetch,
    getDiscountCodes,
    called,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { selectActiveDistributionChannelKey } from "../../state/authentication";
import { useAppSelector } from "../../state/hooks";
import { selectAddToCartDraftItems } from "../../state/lensBuilder";
import { addToCartDraft, OrderDemoLens, OrderDemoLensVariables } from "../../types";
import { nullishStringify } from "../../utils";
import { useCallback, useMemo } from "react";

export const ORDER_DEMO_LENS = gql`
  mutation OrderDemoLens($addBundleToCartRequest: addBundleToCartDraft!, $distributionChannelKey: String!) {
    orderDemoLens(addBundleToCartRequest: $addBundleToCartRequest, distributionChannelKey: $distributionChannelKey) {
      orderNumber
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  orderDemoLens: () => Promise<FetchResult<OrderDemoLens>>;
}

export const useOrderDemoLensMutation = (handleOnComplete?: (data: OrderDemoLens) => void): ExtendedMutation => {
  const itemsToAdd = useAppSelector(selectAddToCartDraftItems);
  const distributionChannelKey = useAppSelector(selectActiveDistributionChannelKey) as string;

  const withDC = itemsToAdd.map(nullishStringify).map((item: addToCartDraft) => ({
    ...item,
    distributionChannelKey,
  }));

  const variables: OrderDemoLensVariables = useMemo(
    () => ({
      addBundleToCartRequest: {
        itemsToAdd: withDC,
      },
      distributionChannelKey,
    }),
    [distributionChannelKey, withDC],
  );

  const [mutate, { data, error, loading, called }] = useMutation<OrderDemoLens, OrderDemoLensVariables>(ORDER_DEMO_LENS, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
  });

  const orderDemoLens = useCallback(() => {
    return mutate({
      variables,
    });
  }, [mutate, variables]);

  return { orderDemoLens, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { WriteOffInventory, WriteOffInventoryDraft, WriteOffInventoryVariables } from "../../types";
import { useCallback } from "react";

export const WRITE_OFF_INVENTORY = gql`
  mutation WriteOffInventory($writeOffInventoryRequest: WriteOffInventoryDraft!) {
    writeOffInventory(writeOffInventoryRequest: $writeOffInventoryRequest) {
      sku
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  writeOffInventory: (writeOffInventoryRequest: WriteOffInventoryDraft) => Promise<FetchResult<WriteOffInventory>>;
}

export const useWriteOffInventory = (handleOnComplete?: (data: WriteOffInventory) => void): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<WriteOffInventory, WriteOffInventoryVariables>(WRITE_OFF_INVENTORY, {
    onError: () => void 0,
    ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    notifyOnNetworkStatusChange: true,
  });

  const writeOffInventory = useCallback(
    (writeOffInventoryRequest: WriteOffInventoryDraft) => {
      const variables = {
        writeOffInventoryRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { writeOffInventory, data, error, loading, called };
};

import { useIdle } from "@uidotdev/usehooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { FC, useCallback, useEffect, useState } from "react";

import { ConfigCommon, ConfigType } from "../../types";
import { isAfter } from "../../utils";
import useConfig from "../Setting/useConfig";
import PinModal from "./PinModal";
import StaffContext from "./StaffContext";
import useStaffToken, { StaffToken } from "./useStaffToken";

export type StaffProviderProps = {
  children: React.ReactNode;
};

const defaultLoginTimeout = 120; // seconds ~ 2 minutes

const StaffProvider: FC<StaffProviderProps> = ({ children }) => {
  const flags = useFlags();
  const userLoginPinVerify = flags.staffPINAuth;
  const { staffToken, setToken } = useStaffToken();
  const [openPinModal, setOpenPinModal] = useState(staffToken?.accessToken ? false : true);
  const { config } = useConfig<ConfigCommon>(ConfigType.IdleTime);
  const idle = useIdle((config?.value ? Number(config?.value) : defaultLoginTimeout) * 1000); // in milliseconds

  useEffect(() => {
    /**
    Open / close PIN modal depends on staff access token
    */
    if (staffToken?.accessToken) {
      setOpenPinModal(false);
    } else {
      setOpenPinModal(true);
    }
  }, [staffToken?.accessToken]);

  const onVerified = useCallback(
    (staffToken: StaffToken | null) => {
      // should be real data here
      setToken(staffToken);
      if (staffToken?.accessToken) {
        setOpenPinModal(false);
      }
    },
    [setToken],
  );

  const logout = useCallback(() => {
    // Clear token;
    setToken(null);
  }, [setToken]);

  useEffect(() => {
    /**
    every minute check for staff expire date
    */
    const timeout = setTimeout(
      () => {
        if (staffToken?.expiresAt) {
          if (isAfter(new Date(), new Date(staffToken?.expiresAt))) {
            logout();
          }
        }
      },
      1 * 60 * 1000,
    );

    return () => clearTimeout(timeout);
  }, [logout, staffToken?.expiresAt]);

  useEffect(() => {
    if (userLoginPinVerify && idle && staffToken?.accessToken) {
      logout();
    }
  }, [userLoginPinVerify, idle, staffToken?.accessToken, logout]);

  return (
    <StaffContext.Provider
      value={{
        logout,
        staffToken,
      }}
    >
      {userLoginPinVerify ? <PinModal open={openPinModal} onVerified={onVerified} /> : null}
      {children}
    </StaffContext.Provider>
  );
};

export default StaffProvider;

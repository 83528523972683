import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";
import { omit } from "lodash";

import { Appointment, AppointmentUpdateRequest, PosUpdateAppointmentMutation, PosUpdateAppointmentMutationVariables } from "../../types";
import { toAppointmentUpdateOperationVariables } from "../../utils";
import { APPOINTMENT_TYPE_FIELDS, CORE_OPTOMETRIST_FIELDS, PATIENT_FIELDS } from "../fragments";
import { useCallback } from "react";

export const UPDATE_APPOINTMENT = gql`
  ${APPOINTMENT_TYPE_FIELDS}
  ${CORE_OPTOMETRIST_FIELDS}
  ${PATIENT_FIELDS}
  mutation PosUpdateAppointmentMutation($posUpdateAppointmentAppointmentUpdateRequest: AppointmentUpdateRequest) {
    posUpdateAppointment(appointmentUpdateRequest: $posUpdateAppointmentAppointmentUpdateRequest) {
      id
      countryCode
      startAt
      endAt
      source
      notes
      status
      distributionChannel {
        key
      }
      patient {
        ...PatientFields
      }
      optometrist {
        ...CoreOptometristFields
        appointmentTypes {
          ...AppointmentTypeFields
        }
      }
      appointmentType {
        ...AppointmentTypeFields
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updateAppointment: (appointment: Appointment) => Promise<FetchResult<PosUpdateAppointmentMutation>>;
  updateAppointmentStatus: (appointment: Appointment) => Promise<FetchResult<PosUpdateAppointmentMutation>>;
}

export const useUpdateAppointmentMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosUpdateAppointmentMutation, PosUpdateAppointmentMutationVariables>(
    UPDATE_APPOINTMENT,
    {
      onError: () => void 0,
    },
  );

  const updateAppointment = useCallback(
    (appointment: Appointment) => {
      const variables: PosUpdateAppointmentMutationVariables = toAppointmentUpdateOperationVariables(appointment);
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  const updateAppointmentStatus = useCallback(
    (appointment: Appointment) => {
      const variables: PosUpdateAppointmentMutationVariables = toAppointmentUpdateOperationVariables(appointment);

      variables.posUpdateAppointmentAppointmentUpdateRequest = omit(variables.posUpdateAppointmentAppointmentUpdateRequest, [
        "startAt",
        "endAt",
        "note",
      ]) as never as AppointmentUpdateRequest;

      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateAppointment, updateAppointmentStatus, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { ContactMethodType, SetContactMethodOnOrder, SetContactMethodOnOrderVariables } from "../../types";
import { useCallback } from "react";

export const SET_ORDER_CONTACT_METHOD = gql`
  mutation SetContactMethodOnOrder($contactMethod: ContactMethodType!, $orderNumber: String!) {
    setContactMethodOnOrder(contactMethod: $contactMethod, orderNumber: $orderNumber) {
      customFields {
        contactMethod
      }
      orderNumber
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setOrderContactMethod: (contactMethod: ContactMethodType, orderNumber: string) => Promise<FetchResult<SetContactMethodOnOrder>>;
}

export const useSetOrderContactMethodMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SetContactMethodOnOrder, SetContactMethodOnOrderVariables>(
    SET_ORDER_CONTACT_METHOD,
    {
      onError: () => void 0,
      fetchPolicy: "network-only",
    },
  );

  const setOrderContactMethod = useCallback(
    (contactMethod: ContactMethodType, orderNumber: string) => {
      const variables: SetContactMethodOnOrderVariables = {
        contactMethod,
        orderNumber,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { setOrderContactMethod, data, error, loading, called };
};

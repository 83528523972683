import { FetchResult, MutationResult, gql, useMutation } from "@apollo/client";
import { CreateAttachment, CreateAttachmentInput, CreateAttachmentVariables } from "../../types";
import { useCallback } from "react";

export const ATTACHMENT_CREATE = gql`
  mutation CreateAttachment($input: CreateAttachmentInput!) {
    createAttachment(input: $input) {
      id
      name
      filename
      contentType
      downloadUrl
      uploadUrl
      createdAt
      updatedAt
      deletedAt
      uploadedAt
    }
  }
`;

type ExtendedMutation = Omit<MutationResult, "client" | "reset"> & {
  createAttachment: (input: CreateAttachmentInput) => Promise<FetchResult<CreateAttachment>>;
};

export const useAttachmentCreate = (): ExtendedMutation => {
  const [mutate, { data, loading, error, called }] = useMutation<CreateAttachment, CreateAttachmentVariables>(ATTACHMENT_CREATE);

  const createAttachment = useCallback(
    (input: CreateAttachmentInput) => {
      return mutate({
        variables: {
          input,
        },
      });
    },
    [mutate],
  );

  return {
    createAttachment,
    data,
    loading,
    error,
    called,
  };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { EditablePatientData, Gender, PosCreatePatientMutation, PosCreatePatientMutationVariables } from "../../types";
import { getCountryCode, toE164 } from "../../utils";
import { PATIENT_FIELDS } from "../fragments";
import { useCallback } from "react";

export const CREATE_PATIENT = gql`
  ${PATIENT_FIELDS}
  mutation PosCreatePatientMutation(
    $firstName: String!
    $store: String!
    $countryCode: String!
    $dateOfBirth: String
    $lastName: String
    $preferredName: String
    $email: String
    $mobilePhone: String
    $landline: String
    $workNumber: String
    $marketingEmailsOptin: Boolean
    $medicare: String
    $medicareReference: String
    $medicareExpiry: String
    $isMedicareValidated: Boolean
    $notes: String
    $privateHealthFundCode: String
    $privateHealthFundMemberNumber: String
    $privateHealthFundMemberReference: String
    $gender: Gender
    $provincialHealthProvider: String
    $provincialHealthNumber: String
    $provincialHealthVersion: String
    $provincialHealthExpiry: String
  ) {
    posCreatePatient(
      firstName: $firstName
      store: $store
      countryCode: $countryCode
      dateOfBirth: $dateOfBirth
      lastName: $lastName
      preferredName: $preferredName
      email: $email
      mobilePhone: $mobilePhone
      marketingEmailsOptin: $marketingEmailsOptin
      landline: $landline
      workNumber: $workNumber
      medicare: $medicare
      medicareReference: $medicareReference
      medicareExpiry: $medicareExpiry
      isMedicareValidated: $isMedicareValidated
      notes: $notes
      privateHealthFundCode: $privateHealthFundCode
      privateHealthFundMemberNumber: $privateHealthFundMemberNumber
      privateHealthFundMemberReference: $privateHealthFundMemberReference
      provincialHealthProvider: $provincialHealthProvider
      provincialHealthNumber: $provincialHealthNumber
      provincialHealthVersion: $provincialHealthVersion
      provincialHealthExpiry: $provincialHealthExpiry
      gender: $gender
    ) {
      ...PatientFields
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  createPatient: (payload: EditablePatientData) => Promise<FetchResult<PosCreatePatientMutation>>;
}

export const useCreatePatientMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosCreatePatientMutation, PosCreatePatientMutationVariables>(CREATE_PATIENT, {
    onError: () => void 0,
  });

  const store = process.env.REACT_APP_STORE_COUNTRY_CODE ?? "";
  const countryCode = getCountryCode().toUpperCase();

  const createPatient = useCallback(
    (patient: EditablePatientData) => {
      const firstName = patient?.firstName ?? "";
      const lastName = patient?.lastName ?? "";
      const preferredName = patient?.preferredName || patient?.firstName || "";

      return mutate({
        variables: {
          store,
          countryCode,
          firstName,
          lastName,
          preferredName,
          mobilePhone: toE164(patient.mobilePhone, countryCode), //pass empty string if there is no phone number
          landline: toE164(patient.landline, countryCode),
          workNumber: toE164(patient.workNumber, countryCode),
          medicare: patient?.medicare,
          medicareExpiry: patient?.medicareExpiry,
          isMedicareValidated: patient?.isMedicareValidated,
          medicareReference: patient?.medicareReference,
          marketingEmailsOptin: true,
          gender: patient?.gender as Gender,
          ...(patient.notes
            ? {
                notes: patient.notes,
              }
            : {}),
          ...(patient?.email
            ? {
                email: patient?.email,
              }
            : {}),
          ...(patient?.dateOfBirth
            ? {
                dateOfBirth: patient?.dateOfBirth,
              }
            : {}),
          provincialHealthProvider: patient?.provincialHealthProvider ?? "",
          provincialHealthNumber: patient?.provincialHealthNumber ?? "",
          provincialHealthVersion: patient?.provincialHealthVersion ?? "",
          provincialHealthExpiry: patient?.provincialHealthExpiry ?? "",
        },
      });
    },
    [countryCode, mutate, store],
  );
  return { createPatient, data, error, loading, called };
};

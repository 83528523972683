import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { OptometristUpdateRequest, PosUpdateOptometristMutation, PosUpdateOptometristMutationVariables } from "../../types";
import { useCallback } from "react";

export const UPDATE_OPTOMETRIST = gql`
  mutation PosUpdateOptometristMutation($posUpdateOptometristOptometristRequest: OptometristUpdateRequest) {
    posUpdateOptometrist(optometristUpdateRequest: $posUpdateOptometristOptometristRequest) {
      id
      firstName
      lastName
      email
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  updateOptometrist: (optometristUpdateRequest: OptometristUpdateRequest) => Promise<FetchResult<PosUpdateOptometristMutation>>;
}

export const useUpdateOptometristMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosUpdateOptometristMutation, PosUpdateOptometristMutationVariables>(
    UPDATE_OPTOMETRIST,
    {
      onError: () => void 0,
    },
  );

  const updateOptometrist = useCallback(
    (optometristRequest: OptometristUpdateRequest) => {
      const variables: PosUpdateOptometristMutationVariables = {
        posUpdateOptometristOptometristRequest: optometristRequest,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { updateOptometrist, data, error, loading, called };
};

import { ApolloError } from "@apollo/client";
import { useCallback, useEffect } from "react";

import { useUpsertConfig } from "../../data/mutations";
import { useReadConfig } from "../../data/queries";
import { ConfigType } from "../../types";

export type UseConfigResult<T = unknown> = {
  config: T | null;
  fetching: boolean;
  saving: boolean;
  updateConfig: (data: unknown) => Promise<void>;
  fetchError?: ApolloError;
  saveError?: ApolloError;
};

const useConfig = <T = unknown | null>(configType: ConfigType): UseConfigResult<T> => {
  const { readConfig, data: configData, loading: fetching, refetch, error: fetchError } = useReadConfig();
  const { upsertConfig, loading: saving, error: saveError } = useUpsertConfig();

  useEffect(() => {
    readConfig({ variables: { type: configType } });
  }, [configType, readConfig]);

  const updateConfig = useCallback(
    async (data: unknown) => {
      try {
        await upsertConfig({
          type: configType,
          data: data,
        });
        refetch();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    [configType, upsertConfig, refetch],
  );

  return {
    config: (configData?.readConfig as T) || null,
    fetching,
    saving,
    updateConfig,
    fetchError,
    saveError,
  };
};

export default useConfig;

import { gql, LazyQueryExecFunction, QueryResult, useLazyQuery } from "@apollo/client";

import { InventoriesReceived, InventoriesReceivedVariables } from "../../types";

export const INVENTORIES_RECEIVED = gql`
  query InventoriesReceived($inventoryMovementsRequest: InventoryMovementsRequest!) {
    inventoriesReceived(inventoryMovementsRequest: $inventoryMovementsRequest) {
      id
      createdAt
      batchNumber
      destinationChannel
      sourceChannel
      transferDate
      transferStatus
      inventoryEntries {
        sku
        description
        quantity
        transferType
        comment
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<InventoriesReceived>, "data" | "loading" | "error" | "variables" | "called"> {
  getInventoriesReceived: LazyQueryExecFunction<InventoriesReceived, InventoriesReceivedVariables>;
}

export const useInventoriesReceivedQuery = (handleOnComplete?: (data: InventoriesReceived) => void): ExtendedQueryResult => {
  const [getInventoriesReceived, { data, error, loading, called, variables }] = useLazyQuery<InventoriesReceived, InventoriesReceivedVariables>(
    INVENTORIES_RECEIVED,
    {
      onError: () => void 0,
      fetchPolicy: "no-cache",
      ...(handleOnComplete ? { onCompleted: handleOnComplete } : {}),
    },
  );

  return {
    getInventoriesReceived,
    data,
    error,
    loading,
    called,
    variables,
  };
};

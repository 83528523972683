import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { SendCommunicationForOrder, SendCommunicationForOrderVariables } from "../../types";
import { useCallback } from "react";

export const SEND_COMMUNICATION_FOR_ORDER = gql`
  mutation SendCommunicationForOrder($orderNumber: String!) {
    posSendCommunicationForOrder(orderNumber: $orderNumber) {
      status
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  sendCommunication: (orderNumber: string) => Promise<FetchResult<SendCommunicationForOrder>>;
}

export const useSendCommunicationForOrder = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SendCommunicationForOrder, SendCommunicationForOrderVariables>(
    SEND_COMMUNICATION_FOR_ORDER,
    {
      onError: () => void 0,
      notifyOnNetworkStatusChange: true,
    },
  );

  const sendCommunication = useCallback(
    (orderNumber: string) => {
      const sendSmsSendSmsRequest: SendCommunicationForOrderVariables = {
        orderNumber,
      };

      return mutate({
        variables: sendSmsSendSmsRequest,
      });
    },
    [mutate],
  );

  return { sendCommunication, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { PosCalculateRefund, PosCalculateRefundVariables } from "../../types";
import { useCallback } from "react";

export const CALCULATE_REFUND = gql`
  mutation PosCalculateRefund($refundCalculationRequest: OrderRefundCalculationRequest!) {
    calculateRefundAmount(refundCalculationRequest: $refundCalculationRequest) {
      orderId
      orderNumber
      amountOrigional {
        type
        currencyCode
        centAmount
        fractionDigits
      }
      amountPreviouslyRefunded {
        type
        currencyCode
        centAmount
        fractionDigits
      }
      refundAmount {
        type
        currencyCode
        centAmount
        fractionDigits
      }
      paymentMethods {
        paymentMethod
        amountOrigional {
          type
          currencyCode
          centAmount
          fractionDigits
        }
        amountPreviouslyRefunded {
          type
          currencyCode
          fractionDigits
          centAmount
        }
        amountAvailableForRefund {
          type
          currencyCode
          centAmount
          fractionDigits
        }
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult<PosCalculateRefund>, "client" | "reset"> {
  calculateRefund: (variables: PosCalculateRefundVariables) => Promise<FetchResult<PosCalculateRefund>>;
}

export const useCalculateRefundMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<PosCalculateRefund, PosCalculateRefundVariables>(CALCULATE_REFUND, {
    onError: () => void 0,
  });

  const calculateRefund = useCallback(
    (variables: PosCalculateRefundVariables) => {
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { calculateRefund, data, error, loading, called };
};

import { FetchResult, gql, MutationResult, useMutation } from "@apollo/client";

import { SpecialInstructions, SpecialInstructionsVariables } from "../../types";
import { useCallback } from "react";

export const CART_NOTES = gql`
  mutation SpecialInstructions($instructions: String!) {
    specialInstructions(instructions: $instructions) {
      customFields {
        specialInstructions
      }
    }
  }
`;

interface ExtendedMutation extends Omit<MutationResult, "client" | "reset"> {
  setCartNotes: (instructions: string) => Promise<FetchResult<SpecialInstructions>>;
}

export const useSetCartNotesMutation = (): ExtendedMutation => {
  const [mutate, { data, error, loading, called }] = useMutation<SpecialInstructions, SpecialInstructionsVariables>(CART_NOTES, {
    onError: () => void 0,
  });

  const setCartNotes = useCallback(
    (instructions: string) => {
      const variables: SpecialInstructionsVariables = {
        instructions,
      };
      return mutate({
        variables,
      });
    },
    [mutate],
  );

  return { setCartNotes, data, error, loading, called };
};

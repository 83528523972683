import { gql, QueryResult, useLazyQuery } from "@apollo/client";
import { useCallback } from "react";

import { DownloadPrescription, DownloadPrescriptionVariables, PRESCRIPTION_TYPE } from "./../../types/generated";

export const DOWNLOAD_PRESCRIPTION = gql`
  query DownloadPrescription($downloadRequest: DownloadPrescriptionRequest!) {
    downloadPrescription(request: $downloadRequest)
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<DownloadPrescription>, "data" | "loading" | "error" | "refetch" | "called"> {
  downloadPrescription: (
    patientId: string,
    prescriptionId: string,
    type: PRESCRIPTION_TYPE,
  ) => Promise<QueryResult<DownloadPrescription, DownloadPrescriptionVariables>>;
}

export const useDownloadPrescription = (): ExtendedQueryResult => {
  const [query, { data, error, loading, called, refetch }] = useLazyQuery<DownloadPrescription, DownloadPrescriptionVariables>(
    DOWNLOAD_PRESCRIPTION,
    {
      onError: () => void 0,
      fetchPolicy: "network-only",
    },
  );

  const downloadPrescription = useCallback(
    (patientId: string, prescriptionId: string, type: PRESCRIPTION_TYPE) => {
      return query({
        variables: {
          downloadRequest: {
            patientId,
            prescriptionId,
            type,
          },
        },
      });
    },
    [query],
  );

  return {
    downloadPrescription,
    data,
    error,
    loading,
    called,
    refetch,
  };
};

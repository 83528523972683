import { gql, LazyQueryExecFunction, OperationVariables, QueryResult, useLazyQuery } from "@apollo/client";

import { Customer } from "../../types";
import { ADDRESS_FIELDS } from "../fragments";

export const CUSTOMER = gql`
  ${ADDRESS_FIELDS}
  query Customer {
    customer {
      id
      email
      customerGroup {
        id
        logo
        region
        offers
      }
      version
      addresses {
        ...AddressFields
      }
    }
  }
`;

interface ExtendedQueryResult extends Pick<QueryResult<Customer>, "data" | "loading" | "error" | "refetch" | "called"> {
  getCustomer: LazyQueryExecFunction<Customer, OperationVariables>;
}

export const useCustomerQuery = (): ExtendedQueryResult => {
  const [getCustomer, { data, error, loading, called, refetch }] = useLazyQuery<Customer>(CUSTOMER, {
    onError: () => void 0,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  return {
    getCustomer,
    data,
    error,
    loading,
    called,
    refetch,
  };
};

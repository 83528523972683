import { Patient } from ".";
import {
  Addresses_customer_addresses,
  Cart_cart,
  Cart_cart_lineItems,
  Cart_cart_lineItems_discounts_includedDiscounts,
  Orders_orders,
  ShippingMethodsForCart_shippingMethodsForCart_shippingMethods,
} from "./generated";

export interface LineItem extends Cart_cart_lineItems {
  sharedClaimCode?: boolean;
  hfCodes?: string;
}

export interface GroupedLineItems {
  healthfundCode: string;
  items: LineItem[];
}

export type LineItemDiscount = Cart_cart_lineItems_discounts_includedDiscounts;

export type PatientCart = {
  [patientId: string]: Cart_cart;
};

export type PatientOrders = {
  [patientId: string]: Orders_orders[];
};

export type ShippingMethod = ShippingMethodsForCart_shippingMethodsForCart_shippingMethods;

export type CustomerAddress = Addresses_customer_addresses;

export type EditablePatientData = Pick<
  Patient,
  | "id"
  | "firstName"
  | "lastName"
  | "preferredName"
  | "email"
  | "mobilePhone"
  | "dateOfBirth"
  | "landline"
  | "workNumber"
  | "medicare"
  | "medicareExpiry"
  | "medicareReference"
  | "isMedicareValidated"
  | "notes"
  | "privateHealthFundCode"
  | "privateHealthFundMemberNumber"
  | "privateHealthFundMemberReference"
  | "gender"
  | "provincialHealthProvider"
  | "provincialHealthNumber"
  | "provincialHealthVersion"
  | "provincialHealthExpiry"
>;

export enum Genders {
  M = "Male",
  F = "Female",
  B = "Non-Binary",
  S = "Not Stated",
}
